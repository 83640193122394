import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";

class mebaboutUs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { translate } = this.props;
    var svgFreeShipping =
      '<svg width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M9.17083 16.4792C12.4708 22.375 17.7875 27.1875 24.2729 30.2083L29.3146 25.625C29.9333 25.0625 30.85 24.875 31.6521 25.125C34.2188 25.8958 36.9917 26.3125 39.8333 26.3125C41.0938 26.3125 42.125 27.25 42.125 28.3958V35.6667C42.125 36.8125 41.0938 37.75 39.8333 37.75C18.3146 37.75 0.875 21.8958 0.875 2.33333C0.875 1.1875 1.90625 0.25 3.16667 0.25H11.1875C12.4479 0.25 13.4792 1.1875 13.4792 2.33333C13.4792 4.9375 13.9375 7.4375 14.7854 9.77083C15.0375 10.5 14.8542 11.3125 14.2125 11.8958L9.17083 16.4792Z" fill="#00A075"/>' +
      "</svg>";
    var svgservice =
      '<svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M14.9998 0.166748C6.93734 0.166748 0.416504 6.68758 0.416504 14.7501C0.416504 25.6876 14.9998 41.8334 14.9998 41.8334C14.9998 41.8334 29.5832 25.6876 29.5832 14.7501C29.5832 6.68758 23.0623 0.166748 14.9998 0.166748ZM14.9998 19.9584C12.1248 19.9584 9.7915 17.6251 9.7915 14.7501C9.7915 11.8751 12.1248 9.54175 14.9998 9.54175C17.8748 9.54175 20.2082 11.8751 20.2082 14.7501C20.2082 17.6251 17.8748 19.9584 14.9998 19.9584Z" fill="#00A075"/>' +
      "</svg>";

    var svgoffer =
      '<svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M37.6665 0.333252H4.33317C2.0415 0.333252 0.187337 2.20825 0.187337 4.49992L0.166504 29.4999C0.166504 31.7916 2.0415 33.6666 4.33317 33.6666H37.6665C39.9582 33.6666 41.8332 31.7916 41.8332 29.4999V4.49992C41.8332 2.20825 39.9582 0.333252 37.6665 0.333252ZM37.6665 8.66658L20.9998 19.0832L4.33317 8.66658V4.49992L20.9998 14.9166L37.6665 4.49992V8.66658Z" fill="#00A075"/>' +
      "</svg>";

    return (
      <div>
        {/*Team Section*/}
        {/* <section id="team" className="team section-b-space">
                    <div className="title1 team-class-meb">
                        <h2 className="title-inner1">Наша команда</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">

                                <Slider {...Team4} className="team-4">
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/team/team-meb.png`} className="img-fluid" alt="" />
                                        <h4>Hileri Keol</h4>
                                        <h6>CEO & Founder At Company</h6>
                                    </div>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/team/team-meb.png`} className="img-fluid" alt="" />
                                        <h4>Hileri Keol</h4>
                                        <h6>CEO & Founder At Company</h6>
                                    </div>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/team/team-meb.png`} className="img-fluid" alt="" />
                                        <h4>Hileri Keol</h4>
                                        <h6>CEO & Founder At Company</h6>
                                    </div>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/team/team-meb.png`} className="img-fluid" alt="" />
                                        <h4>Hileri Keol</h4>
                                        <h6>CEO & Founder At Company</h6>
                                    </div>
                                    <div>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/team/team-meb.png`} className="img-fluid" alt="" />
                                        <h4>Hileri Keol</h4>
                                        <h6>CEO & Founder At Company</h6>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section> */}

        {/*service layout*/}
        <div className="container about-cls section-b-space mt-5">
          <section
            className="service border-section small-section "
            id="contact"
          >
            <div className="row">
              <div className="col-md-4 service-block">
                <div className="media">
                  <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                  <div className="media-body">
                    <h4>{translate("Contacts")}</h4>
                    <p>
                      <a href="tel:+998977000663">+998 97 700 06 63</a>
                    </p>
                    <p>
                      <a href="tel:+998 99 890 06 63">+998 99 890 06 63</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 service-block">
                <div className="media">
                  <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                  <div className="media-body">
                    <h4>{translate("Address")}</h4>
                    <a
                      href="https://www.google.ru/maps/place/%D0%A2%D1%83%D0%BA%D0%B8%D0%BC%D0%B0%D1%87%D0%B8+%D1%83%D0%BB%D0%B8%D1%86%D0%B0,+%D0%A2%D0%B0%D1%88%D0%BA%D0%B5%D0%BD%D1%82,+%D0%A3%D0%B7%D0%B1%D0%B5%D0%BA%D0%B8%D1%81%D1%82%D0%B0%D0%BD/@41.310679,69.1579927,17z/data=!4m5!3m4!1s0x38ae893badb3aa93:0x9c08c4ace0ae20b2!8m2!3d41.310679!4d69.1601814"
                      target="_blank"
                    >
                      Toshkent shaxar, Uchtepa tumani, 3- to'qimachi, 26-uy
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 service-block">
                <div className="media">
                  <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                  <div className="media-body">
                    <h4>{translate("Email")}</h4>
                    <a href="mailto:mymebzone@gmail.com">
                      {" "}
                      mymebzone@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*service layout end*/}
      </div>
    );
  }
}

export default withTranslate(mebaboutUs);
