import shop from "../api/shop";
import * as types from "../constants/ActionTypes";
import store from "../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import _ from "lodash";
import {
  fetchMainCategories,
  fetchFilteredProduct,
  getProduct,
  getDollarCurrency,
  getDefaultCategories,
  getProductComments,
  getRelatedProducts,
} from "../fetch/filterApi";
import { getUserData, getGenderData } from "../components/userProfile/Users";
import { getRegionData } from "../components/user/Auth";
export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

// region list
export const regionList = () => (dispatch) => {
  getRegionData().then((regions) => {
    dispatch({ type: types.REGION_LIST, regions });
  });
};

// export const getMainCategories = () => (dispatch) => {
//   fetchMainCategories().then(async (main_categories) => {
//     let mainCategoryIds = main_categories.map((el) => {
//       return {
//         mainCategory_uz: el.name_uz,
//         mainCategory_ru: el.name_ru,
//         id: el.id,
//         page: 1,
//         visibleBtn: true,
//       };
//     });
//     let loadmore = [];
//     for (let el of mainCategoryIds) {
//       await getDefaultCategories(el.id, 1).then((res) => {
//         if (res.length === 0) {
//           loadmore.push({ ...el, categories: res, visibleBtn: false });
//         } else {
//           loadmore.push({ ...el, categories: res });
//         }
//       });
//     }
//     dispatch({ type: types.MAIN_CATEGORIES, main_categories });
//     dispatch({ type: types.LOADMORE, list: loadmore });
//   });
// };

export const getMainCategories = () => (dispatch) => {
  fetchMainCategories().then(async (mainCategories) => {
    let main_categories = mainCategories.map((mainCategory) => {
      // console.log(mainCategory);
      return {
        ...mainCategory,
        categoryLength: mainCategory.categories.length,
        nextLength: 3,
      };
    });
    dispatch({ type: types.MAIN_CATEGORIES, main_categories });
  });
};

export const setMoreCategory = (id, res, page, visibleBtn) => ({
  type: types.LOADMORE_CERTAIN_CATEGORY,
  id: id,
  moreItem: res,
  page: page,
  visibleBtn: visibleBtn,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const getAllProducts = () => (dispatch) => {
  dispatch(fetchProductsBegin());
  shop.getProducts((products) => {
    dispatch(receiveProducts(products));
    return products;
  });
};
export const fetchSingleProduct = (productId) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  productId,
});

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
};
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
  dispatch(removeFromWishlist(product));
};
export const addToCartUnsafe = (product, qty) => ({
  type: types.ADD_TO_CART,
  product,
  qty,
});
export const removeFromCart = (product_id) => (dispatch) => {
  toast.error("Item Removed from Cart");
  dispatch({
    type: types.REMOVE_FROM_CART,
    product_id,
  });
};
export const incrementQty = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
};
export const decrementQty = (productId) => (dispatch) => {
  toast.warn("Item Decrement Qty to Cart");

  dispatch({
    type: types.DECREMENT_QTY,
    productId,
  });
};

export const addToWishlistUnsafe = (product) => ({
  type: types.ADD_TO_WISHLIST,
  product,
});

//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product, locale) => (dispatch) => {
  if (locale === "en") {
    toast.success("Mahsulot istaklar ro'yxatiga qo'shildi");
  } else {
    toast.success("Товар добавлен в список желаний");
  }
  // dispatch(addToWishlistUnsafe(product));
  dispatch({
    type: types.ADD_TO_WISHLIST,
    product,
  });
};

export const removeFromWishlist = (product_id, locale) => (dispatch) => {
  if (locale === "en") {
    toast.error("Mahsulot istaklar ro'yxatidan o'chirildi");
  } else {
    toast.error("Товар удален из списка желаний");
  }
  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    product_id,
  });
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
  toast.success("Item Added to Compare");
  dispatch(addToCompareUnsafe(product));
};
export const addToCompareUnsafe = (product) => ({
  type: types.ADD_TO_COMPARE,
  product,
});
export const removeFromCompare = (product_id) => ({
  type: types.REMOVE_FROM_COMPARE,
  product_id,
});

// Filters

export const filterPrice = (price) => ({
  type: types.FILTER_PRICE,
  price,
});

export const filterMainCategory = (mainCategoryId) => ({
  type: types.FILTER_MAINCATEGORY,
  mainCategoryId,
});

export const filterCategory = (categoryId) => ({
  type: types.FILTER_CATEGORY,
  categoryId,
});

export const filterAvailable = (isAvailable) => ({
  type: types.FILTER_AVAILABLE,
  isAvailable,
});

export const filterDeliverable = (isDeliverable) => ({
  type: types.FILTER_DELIVERABLE,
  isDeliverable,
});

export const filterRegion = (regionId) => ({
  type: types.FILTER_REGION,
  regionId,
});

export const filterStore = (storeId) => ({
  type: types.FILTER_STORE,
  storeId,
});

export const filterSearch = (search) => ({
  type: types.FILTER_SEARCH,
  search,
});

export const filterAttributes = (attributes) => ({
  type: types.FILTER_ATTRIBUTES,
  attributes,
});

export const helpMainCategory = (data) => ({
  type: types.HELP_MAINCATEGORY,
  data,
});

export const helpOnlyMainCategory = (data) => ({
  type: types.HELP_ONLY_MAINCATEGORY,
  data,
});

///////////////////////////////////////
export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by,
});

// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol,
});

//////////
export function getVisibleproducts(filters) {
  const finalFilters = _.pickBy(filters, function(value, key) {
    return !(value === null);
  });

  return function(dispatch) {
    return fetchFilteredProduct(finalFilters).then((products) => {
      dispatch({ type: types.RECEIVE_PRODUCTS, products });
    });
  };
}

// comment
export const commentAboutStore = (data) => ({
  type: types.STORE_ID,
  data,
});

// export const commentAboutProductId = (productId) => ({
//   type: types.PRODUCT_ID,
//   productId,
// });

// select product

export function chosenProductId(productId) {
  return function(dispatch) {
    return getProduct(productId).then((res) => {
      dispatch({ type: types.PRODUCT_DETAIL, product: res });
    });
  };
}

export function gettingProductComments(productId, page) {
  return function(dispatch) {
    return getProductComments(productId, page).then(async (res) => {
      const totalLength = res.headers.get("totalLength");
      const pages = Math.ceil(totalLength / 3);
      const commentList = await res
        .clone()
        .json()
        .then((clist) => {
          dispatch({
            type: types.PRODUCT_COMMENTS,
            product_comments: clist,
            pages: pages,
            productId: productId,
          });
        });
    });
  };
}

export const newProductCommentAdded = (new_comment) => ({
  type: types.NEW_PRODUCT_COMMENT_ADDED,
  new_comment,
});

export function gettingRelatedProduct(productId, page, size) {
  return function(dispatch) {
    return getRelatedProducts(productId, page, size).then((res) => {
      dispatch({ type: types.RELATED_PRODUCTS, related_products: res });
    });
  };
}

export const setUser = (userData) => ({
  type: types.USER_DATA,
  userData,
});

export function getUser(token) {
  return function(dispatch) {
    return getUserData(token).then((res) => {
      dispatch({ type: types.USER_DATA, userData: res });
    });
  };
}

export function getGender() {
  return function(dispatch) {
    return getGenderData().then((res) => {
      dispatch({ type: types.GENDER, gender: res });
    });
  };
}

export function getCurrency() {
  return function(dispatch) {
    return getDollarCurrency().then((res) => {
      dispatch({ type: types.RATE, rate: res });
    });
  };
}

export const setPriceRange = (data) => ({
  type: types.SELECTED_PRICE_RANGES,
  data,
});

export const setNextLength = (nextLength, id) => ({
  type: types.NEXT_LENGTH,
  nextLength,
  id,
});
