import React, { Component } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import SimpleReactValidator from "simple-react-validator";
import { withTranslate } from "react-redux-multilingual";

//custom files
import BackgroundContainer from "./components/BackgroundContainer";
import "./style/auth.scss";
import { forgotPassword } from "./api/auth";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailOrPhone: "",
      disableBtn: false,
      tryAgain: false,
      serverNotFoundErr: false,
      serverWrongInputErr: false,
      serverErr: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });
  }

  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  navigateBtn = (path) => {
    this.props.history.push(`${process.env.PUBLIC_URL}/${path}`);
  };
  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  tryAgainBtn = (e) => {
    e.preventDefault();
    this.setState({ tryAgain: false });
  };

  forgotPasswordBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const { emailOrPhone } = this.state;
      this.setState({ disableBtn: true });
      forgotPassword({ emailOrPhone }).then((res) => {
        if (res.status === 200) {
          console.log("sended");
          this.setState({ disableBtn: false, tryAgain: true });
        } else if (res.status === 404) {
          console.log("not found");
          this.setState({ disableBtn: false, serverNotFoundErr: true });
          setTimeout(() => {
            this.setState({ serverNotFoundErr: false });
          }, 4000);
        } else if (res.status === 400) {
          console.log("wrong input");
          this.setState({ disableBtn: false, serverWrongInputErr: true });
          setTimeout(() => {
            this.setState({ serverWrongInputErr: false });
          }, 4000);
        } else {
          console.log("server error");
          this.setState({ disableBtn: false, serverErr: true });
          setTimeout(() => {
            this.setState({ serverErr: false });
          }, 4000);
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <BackgroundContainer>
        {this.state.tryAgain ? (
          <form className="forgot-password-try-again-container">
            <div className="forgot-password-try-again-content">
              <p>{translate("new_password_send")}</p>
              <button onClick={() => this.navigateBtn("login")}>
                {translate("Login")}
              </button>
            </div>
            <p
              onClick={(e) => {
                this.tryAgainBtn(e);
              }}
            >
              {translate("not_get_new_password")}{" "}
              <span>{translate("Send")}</span>
            </p>
          </form>
        ) : (
          <form className="forgot-password-container">
            <div>
              <h3 className="form-title">
                {translate("Forgot_your_password")}
              </h3>
              <label htmlFor="phone">
                {translate("Enter_phone_or_email")}:
              </label>
              <input
                type="text"
                placeholder={translate("Phone_or_email")}
                name="emailOrPhone"
                value={this.state.emailOrPhone}
                onChange={this.handleInputChange}
                onBlur={() => this.validator.showMessageFor("emailOrPhone")}
              />
              {this.validator.message(
                "emailOrPhone",
                this.state.emailOrPhone,
                "required"
              )}
            </div>
            <div>
              <div className="server-errors">
                {this.state.serverNotFoundErr && (
                  <p>{translate("Not_found_such_user")}</p>
                )}
                {this.state.serverWrongInputErr && (
                  <p>{translate("Please_check_your_phone_email")}</p>
                )}
                {this.state.serverErr && <p>{translate("Server_error")}</p>}
              </div>
              <button
                className="forgot-password-btn"
                onClick={(e) => this.forgotPasswordBtn(e)}
                disabled={this.state.disableBtn}
                style={{
                  cursor: this.state.disableBtn ? "not-allowed" : "",
                }}
              >
                {this.state.disableBtn ? (
                  <ClipLoader color={"#fff"} loading={true} size={20} />
                ) : (
                  translate("Send")
                )}
              </button>
              <button onClick={() => this.navigateBtn("login")}>
                {translate("Back")}
              </button>
            </div>
          </form>
        )}
      </BackgroundContainer>
    );
  }
}

export default withTranslate(ForgotPassword);
