// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const SORT_BY = "SORT_BY";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

// Mavluda action types

export const MAIN_CATEGORIES = "MAIN_CATEGORIES";
export const FILTER_MAINCATEGORY = "FILTER_MAINCATEGORY";
export const FILTER_CATEGORY = "FILTER_CATEGORY";
export const FILTER_PRICE = "FILTER_PRICE";
export const FILTER_AVAILABLE = "FILTER_AVAILABLE";
export const FILTER_DELIVERABLE = "FILTER_DELIVERABLE";
export const FILTER_REGION = "FILTER_REGION";
export const FILTER_STORE = "FILTER_STORE";
export const FILTER_SEARCH = "FILTER_SEARCH ";
export const FILTER_ATTRIBUTES = "FILTER_ATTRIBUTES";

export const HELP_MAINCATEGORY = "HELP_MAINCATEGORY";
export const HELP_ONLY_MAINCATEGORY = "HELP_ONLY_MAINCATEGORY";

export const STORE_ID = "STORE_ID";
export const PRODUCT_ID = "PRODUCT_ID";

export const REGION_LIST = "REGION_LIST";
export const USER_DATA = "USER_DATA";
export const GENDER = "GENDER";

export const PRODUCT_DETAIL = "PRODUCT_DETAIL";
export const RATE = "RATE";
export const SELECTED_PRICE_RANGES = "SELECTED_PRICE_RANGES";

export const LOADMORE = "LOADMORE";
export const LOADMORE_CERTAIN_CATEGORY = "LOADMORE_CERTAIN_CATEGORY";
export const PRODUCT_COMMENTS = "PRODUCT_COMMENTS";
export const NEW_PRODUCT_COMMENT_ADDED = "NEW_PRODUCT_COMMENT_ADDED";
export const RELATED_PRODUCTS = "RELATED_PRODUCTS";

export const NEXT_LENGTH = "NEXT_LENGTH";
