import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import HeaderOne from "./common/headers/header-href";
import FooterOne from "./common/footers/footer-five";
import ThemeSettings from "./common/theme-settings";

class App extends Component {
  render() {
    return (
      <div>
        <HeaderOne />
        {this.props.children}
        <FooterOne />
        <ThemeSettings />
      </div>
    );
  }
}

export default withTranslate(App);
