import * as types from "../constants/ActionTypes";

const userReducerDefaultState = {
  regions: [],
  userData: "",
  gender: [],
};

const userReducer = (state = userReducerDefaultState, action) => {
  // console.log("Action Result");
  // console.log(action);
  switch (action.type) {
    case types.REGION_LIST:
      return {
        ...state,
        regions: action.regions,
      };

    case types.USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    case types.GENDER:
      return {
        ...state,
        gender: action.gender,
      };
    default:
      return state;
  }
};

export default userReducer;
