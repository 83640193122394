import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { Trash2 } from "react-feather";
import Breadcrumb from "../common/breadcrumb";
import NumberFormat from "react-number-format";
import { addToCartAndRemoveWishlist, removeFromWishlist } from "../../actions";

class wishList extends Component {
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  render() {
    const { Items, translate, locale, symbol } = this.props;
    const { USD_UZS } = this.props.rate;
    return (
      <div>
        <Breadcrumb title={translate("Wishlist")} />
        {Items.length > 0 ? (
          <section className="wishlist-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table cart-table table-responsive-xs">
                    <thead>
                      <tr className="table-head">
                        <th scope="col">{translate("Photo")}</th>
                        <th scope="col">{translate("Name_product")} </th>
                        <th scope="col">{translate("Price")}</th>
                        <th scope="col">{translate("isAvailable")}</th>
                        <th scope="col">{translate("Delete")}</th>
                      </tr>
                    </thead>
                    {Items.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <Link
                                to={`${
                                  process.env.PUBLIC_URL
                                }/right-sidebar/product/${item.id}`}
                              >
                                <img src={item.images[0].imageUrl} alt="" />
                              </Link>
                            </td>
                            <td>{item.name_ru}</td>
                            <td>
                              <h2>
                                <NumberFormat
                                  value={
                                    symbol === "$"
                                      ? item.price / USD_UZS
                                      : item.price
                                  }
                                  thousandSeparator=" "
                                  decimalScale={2}
                                  prefix={symbol === "$" ? "$" : null}
                                  suffix={symbol === "$" ? null : " UZS"}
                                  displayType="text"
                                />
                              </h2>
                            </td>
                            <td>
                              <p>{item.isAvailable ? "да" : "нет"}</p>
                            </td>
                            <td>
                              <a
                                href="javascript:void(0)"
                                className="icon"
                                onClick={() =>
                                  this.props.removeFromWishlist(item, locale)
                                }
                              >
                                <Trash2 />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
              <div className="row wishlist-buttons">
                <div className="col-12">
                  <Link
                    to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}
                    className="btn btn-solid"
                    style={{ fontSize: "16px" }}
                  >
                    {translate("Continue_shopping")}
                  </Link>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/empty_wish.png`}
                        className="img-fluid mb-4"
                        alt=""
                      />
                      <h3>
                        <strong>{translate("Empty_wishlist")}</strong>
                      </h3>
                      {/* <h4>Explore more shortlist some items.</h4> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Items: state.wishlist.list,
  symbol: state.data.symbol,
  locale: state.Intl.locale,
  rate: state.data.rate,
});

export default connect(
  mapStateToProps,
  { addToCartAndRemoveWishlist, removeFromWishlist }
)(withTranslate(wishList));
