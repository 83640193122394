import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "../common/index.scss";
import Service from "./service";
import NewProduct from "../common/new-product";
import DetailsWithPrice from "./details-price";
import WriteProductComment from "./writeProductComment";
import { getProduct } from "../../fetch/filterApi";

import {
  chosenProductId,
  gettingProductComments,
  gettingRelatedProduct,
} from "../../actions/index";

class RightSideBar extends Component {
  constructor() {
    super();
    this.state = {
      nav1: null,
      nav2: null,
      lightBoxOpen: false,
      lightBoxImages: [],
      photoIndex: 0,
      role: null,
      product: "",
    };
  }

  // componentWillMount() {
  //   const { productId } = this.props.match.params;
  //   this.props.chosenProductId(productId);
  //   this.props.gettingProductComments(productId, 1);
  //   this.props.gettingRelatedProduct(productId, 1, 4);
  // }

  componentDidMount() {
    // const { productId } = this.props.match.params;
    // this.props.chosenProductId(productId);
    // this.props.gettingProductComments(productId, 1);
    // this.props.gettingRelatedProduct(productId, 1, 4);
    // if (document.referrer === "") {
    //   console.log("User came directly");
    // } else {
    //   console.log("User did not came directly");
    // }

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });

    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    this.setState({
      role: userObj ? userObj.roles[0].id : "",
    });

    const { item } = this.props;
    if (item) {
      this.setState({ product: item });
      const { images } = item;
      images.map((el) => this.state.lightBoxImages.push(el.imageUrl));
      this.props.gettingProductComments(item.id, 1);
      this.props.gettingRelatedProduct(item.id, 1, 3);
    } else {
      const { productId } = this.props.match.params;
      getProduct(productId).then((res) => {
        console.log(res);
        const { images } = res;
        images.map((el) => this.state.lightBoxImages.push(el.imageUrl));
        this.setState({ product: res });
        this.props.gettingProductComments(productId, 1);
        this.props.gettingRelatedProduct(productId, 1, 3);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.productId !== this.props.match.params.productId
    ) {
      console.log("change");
      const { item } = this.props;
      if (item) {
        this.setState({ product: item });
        const { images } = item;
        images.map((el) => this.state.lightBoxImages.push(el.imageUrl));
        this.props.gettingProductComments(item.id, 1);
        this.props.gettingRelatedProduct(item.id, 1, 3);
      } else {
        const { productId } = this.props.match.params;
        getProduct(productId).then((res) => {
          console.log(res);
          const { images } = res;
          images.map((el) => this.state.lightBoxImages.push(el.imageUrl));
          this.setState({ product: res });
          this.props.gettingProductComments(productId, 1);
          this.props.gettingRelatedProduct(productId, 1, 3);
        });
      }
    }
  }

  render() {
    const { lightBoxOpen, lightBoxImages, photoIndex, product } = this.state;
    return (
      <div>
        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 col-sm-12 col-xs-12">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6 product-thumbnail">
                        <Slider
                          asNavFor={this.state.nav2}
                          ref={(slider) => (this.slider1 = slider)}
                          className="product-slick"
                        >
                          {product.images ? (
                            product.images.map((image, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  this.setState({ lightBoxOpen: true })
                                }
                              >
                                <img
                                  src={`${image.imageUrl}?width=383&height=287`}
                                  className="img-fluid image_zoom_cls-0"
                                  style={{ objectFit: "cover" }}
                                  alt="product images"
                                />
                              </div>
                            ))
                          ) : (
                            <div>here picture</div>
                          )}
                        </Slider>

                        <Slider
                          className="slider-nav"
                          asNavFor={this.state.nav1}
                          ref={(slider) => (this.slider2 = slider)}
                          slidesToShow={3}
                          swipeToSlide={true}
                          focusOnSelect={true}
                        >
                          {product.images ? (
                            product.images.map((image, index) => (
                              <div key={index}>
                                <img
                                  src={`${image.imageUrl}?width=128&height=97`}
                                  key={index}
                                  alt=""
                                  className="img-fluid"
                                  style={{
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            ))
                          ) : (
                            <div>here picture</div>
                          )}
                        </Slider>
                      </div>
                      <DetailsWithPrice product={product} />
                    </div>
                  </div>
                  <WriteProductComment />
                </div>
                <div className="col-sm-3 collection-filter">
                  <Service product={product} />
                  <NewProduct />
                </div>
              </div>
            </div>
          </div>
        </section>

        {lightBoxOpen && (
          <Lightbox
            mainSrc={lightBoxImages[photoIndex]}
            nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
            prevSrc={
              lightBoxImages[
                (photoIndex + lightBoxImages.length - 1) % lightBoxImages.length
              ]
            }
            onCloseRequest={() => this.setState({ lightBoxOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + lightBoxImages.length - 1) %
                  lightBoxImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % lightBoxImages.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.productId;
  console.log(productId, "from map");
  return {
    // productData: state.product_detail.product,
    item: state.data.products.find((el) => el.id == productId),
  };
};

export default connect(
  mapStateToProps,
  { chosenProductId, gettingProductComments, gettingRelatedProduct }
)(RightSideBar);
