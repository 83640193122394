import React, { Component } from "react";
import MainProfile from "./mainProfile";
import EditProfile from "./editProfile";
import MyComments from "./myComments";
import ChangePassword from "./changePassword";
import ChangePhone from "./changePhone";
import ChangeEmail from "./changeEmail";
import MyShops from "./myShops";
import CreateStore from "./createStore";

export class switchProfile extends Component {
  render() {
    const {
      page,
      comment,
      changeComment,
      changePage,
      mainCategories,
      regions,
      userData,
      gender,
      getUser,
      locale,
    } = this.props;
    switch (page) {
      case 1:
        return <MainProfile userData={userData} locale={locale} />;
      case 2:
        return (
          <EditProfile
            userData={userData}
            gender={gender}
            changePage={changePage}
            getUser={getUser}
            locale={locale}
          />
        );
      case 3:
        return (
          <div>
            <div className="comment-btns">
              <button
                className="help-class-2 comment-active"
                onClick={(event) => {
                  changeComment(1, event);
                }}
              >
                О компании
              </button>
              <button
                className="help-class-2"
                onClick={(event) => {
                  changeComment(2, event);
                }}
              >
                О товаре
              </button>
            </div>
            <MyComments comment={comment} />
          </div>
        );

      case 4:
        return <MyShops locale={locale} />;
      case 5:
        return <ChangePassword />;
      case 6:
        return (
          <CreateStore
            changePage={changePage}
            mainCategories={mainCategories}
            regions={regions}
            locale={locale}
          />
        );
      case 7:
        return <ChangePhone changePage={changePage} />;
      case 8:
        return <ChangeEmail changePage={changePage} />;
      default:
        return <div>Something wrong with profile pages</div>;
    }
  }
}

export default switchProfile;
