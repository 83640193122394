const postRegisterData = async (data = {}) => {
  // console.log(data);
  const renamedData = {
    name: data.newName,
    surname: data.newSurname,
    email: data.newEmail,
    password: data.newPassword,
    roles: data.roles,
  };
  // console.log(renamedData);
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/users/register`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
      },
      body: JSON.stringify(renamedData),
    }
  );
  try {
    const newData = await req;
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const postConfirmCodeCustomer = async (data = {}) => {
  // console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/users/code`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
    },
    body: JSON.stringify(data),
  });
  try {
    const newData = await req;
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const getRegionData = async () => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/regions`);
  try {
    const newData = await req.json();
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const getMainCategoryData = async () => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/maincategories`
  );
  try {
    const newData = await req.json();
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const postLogoImage = async (data = {}) => {
  var formData = new FormData();
  formData.append("file", data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/files`, {
    method: "POST",
    credentials: "same-origin",
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
    body: formData,
  });
  try {
    const newData = await req;
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const postRegisterSeller = async (data = {}) => {
  // console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/stores`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
    },
    body: JSON.stringify(data),
  });
  try {
    const newData = await req;
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const postLogin = async (data = {}) => {
  // console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/auth/login`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
    },
    body: JSON.stringify(data),
  });
  try {
    const newData = await req;
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const postForgetEmailPassword = async (data = {}) => {
  //  console.log(data);
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/users/forgotPassword`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const newData = await req;
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const postResendCode = async (data = {}) => {
  // console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/users/resend`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
    },
    body: JSON.stringify(data),
  });
  try {
    const newData = await req;
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

export {
  postLogin,
  postForgetEmailPassword,
  postRegisterSeller,
  postRegisterData,
  postConfirmCodeCustomer,
  postLogoImage,
  getRegionData,
  getMainCategoryData,
  postResendCode,
};
