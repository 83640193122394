const fetchMainCategories = async () => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/maincategories/public`
  );
  try {
    const mainCategories = await req.json();
    return mainCategories;
  } catch (error) {
    console.log("error", error);
  }
};

const fetchSellers = async () => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/stores/public`);
  try {
    const stores = await req.json();
    return stores;
  } catch (error) {
    console.log("error", error);
  }
};

const fetchReportTypes = async () => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/reporttypes`);
  try {
    const result = await req.json();
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const postReportTypes = async (data = {}) => {
  console.log(data);
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/productReports`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const filteredProduct = await req.json();
    return filteredProduct;
  } catch (error) {
    console.log("error", error);
  }
};

const reportProductComment = async (data = {}) => {
  console.log(data);
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/productCommentReports`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const filteredProduct = await req.json();
    return filteredProduct;
  } catch (error) {
    console.log("error", error);
  }
};

const reportStore = async (data = {}) => {
  console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/storeReports`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  try {
    const filteredProduct = await req.json();
    return filteredProduct;
  } catch (error) {
    console.log("error", error);
  }
};

const fetchRegions = async () => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/regions`);
  try {
    const regions = await req.json();
    return regions;
  } catch (error) {
    console.log("error", error);
  }
};

const fetchMinMaxPrice = async (id) => {
  //console.log("id", id);
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/products/price?categoryId=${id}`
  );
  try {
    const value = await req.json();
    return value;
  } catch (error) {
    console.log("error", error);
  }
};

const fetchFilters = async (id) => {
  // console.log("id", id);
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/categories/${id}/filter/public`
  );
  try {
    const filters = await req.json();
    return filters;
  } catch (error) {
    console.log("error", error);
  }
};

const fetchFilteredProduct = async (data = {}, size = 10) => {
  // console.log(data);
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/products/public`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const filteredProduct = await req.json();
    return filteredProduct;
  } catch (error) {
    console.log("error", error);
  }
};
const fetchFilteredProductBy = async (data, page = 1, size = 10) => {
  console.log(data);
  const req = await fetch(
    `${
      process.env.REACT_APP_MEBZONE_API
    }/products/public?page=${page}&size=${size}`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const filteredProduct = await req.json();
    return filteredProduct;
  } catch (error) {
    console.log("error", error);
  }
};

const getProduct = async (productId) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/products/${productId}/public`
  );
  try {
    const product = await req.json();
    return product;
  } catch (error) {
    console.log("error", error);
  }
};

const getRelatedProducts = async (productId, page, size) => {
  const req = await fetch(
    `${
      process.env.REACT_APP_MEBZONE_API
    }/products/${productId}/similar?page=${page}&size=${size}`
  );
  try {
    const products = await req.json();
    return products;
  } catch (err) {
    console.log("err", err);
  }
};

const getStore = async (storeId) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/stores/${storeId}/public`
  );
  try {
    const store = await req.json();
    return store;
  } catch (err) {
    console.log("err", err);
  }
};

const postStoreComment = async (data = {}, token) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/storecomments`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const getStoreComments = async (storeId, page) => {
  const req = await fetch(
    `${
      process.env.REACT_APP_MEBZONE_API
    }/storecomments?storeId=${storeId}&page=${page}&size=3`
  );
  try {
    const comment = await req;
    return comment;
  } catch (err) {
    console.log("err", err);
  }
};

const postProductComment = async (data = {}, token) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/productcomments`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const result = await req;
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

const getProductComments = async (productId, page) => {
  const req = await fetch(
    `${
      process.env.REACT_APP_MEBZONE_API
    }/productcomments?productId=${productId}&page=${page}&size=3`
  );
  try {
    const comment = await req;
    return comment;
  } catch (err) {
    console.log("err", err);
  }
};

const getDollarCurrency = async () => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/currencies?Ccy=USD`
  );
  try {
    const rate = await req.json();
    return { USD_UZS: rate[0].Rate };
  } catch (err) {
    console.log("err", err);
  }
};

const getDefaultCategories = async (mainCategoryId, page) => {
  const req = await fetch(
    `${
      process.env.REACT_APP_MEBZONE_API
    }/categories?mainCategoryId=${mainCategoryId}&page=${page}&size=3`
  );
  try {
    const defaultCategories = await req.json();
    return defaultCategories;
  } catch (err) {
    console.log("err", err);
  }
};

const checkToken = async (token) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/auth/tokenExpire`,
    {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const result = await req.json();
    return result;
  } catch (err) {
    console.log("err", err);
  }
};

export {
  fetchFilteredProductBy,
  fetchMainCategories,
  fetchMinMaxPrice,
  fetchSellers,
  fetchRegions,
  fetchFilters,
  fetchFilteredProduct,
  getProduct,
  getRelatedProducts,
  getStore,
  postStoreComment,
  getStoreComments,
  postProductComment,
  getProductComments,
  getDollarCurrency,
  getDefaultCategories,
  checkToken,
  fetchReportTypes,
  postReportTypes,
  reportProductComment,
  reportStore,
};
