import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import SimpleReactValidator from "simple-react-validator";
import { postChangePassword } from "./Users";

export class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      token: "",
    };
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });
  }

  componentDidMount() {
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    this.setState({ token: userObj.token });
  }

  handleChangePassword(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  changePassword = (e) => {
    e.preventDefault();
    document.querySelector("#resetpwdErrMsg").innerHTML = "";
    if (this.validator.allValid()) {
      if (this.state.newPassword === this.state.repeatNewPassword) {
        postChangePassword(this.state).then(async (res) => {
          if (res.status == 200) {
            localStorage.removeItem("mebzone-user");
            this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            window.location.reload();
          } else {
            const errMsg = await res
              .clone()
              .json()
              .then((errJson) => {
                console.log(errJson.errors[0].msg);
                document.querySelector("#resetpwdErrMsg").innerHTML =
                  errJson.errors[0].msg;
              });
          }
        });
      } else {
        console.log("wrong inputs");
        document.querySelector("#resetpwdErrMsg").innerHTML = "dont match";
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="edit-profile-container">
        <h3 className="profile-sidebar-title">
          {translate("Change_password")}
        </h3>
        <form className="changePassword-container">
          <label htmlFor="">{translate("Enter_old_password")}</label>
          <input
            type="password"
            name="oldPassword"
            placeholder={translate("Enter_old_password")}
            value={this.state.oldPassword}
            onChange={this.handleChangePassword}
            onBlur={() => this.validator.showMessageFor("oldPassword")}
          />
          {this.validator.message(
            "oldPassword",
            this.state.oldPassword,
            "required"
          )}
          <label htmlFor="">{translate("Enter_new_password")}</label>
          <input
            type="password"
            name="newPassword"
            placeholder={translate("Enter_new_password")}
            value={this.state.newPassword}
            onChange={this.handleChangePassword}
            onBlur={() => this.validator.showMessageFor("newPassword")}
          />
          {this.validator.message(
            "newPassword",
            this.state.newPassword,
            "required|min:8"
          )}
          <label htmlFor="">{translate("Repeat_new_password")}</label>
          <input
            type="password"
            name="repeatNewPassword"
            placeholder={translate("Repeat_new_password")}
            value={this.state.repeatNewPassword}
            onChange={this.handleChangePassword}
            onBlur={() => this.validator.showMessageFor("repeatNewPassword")}
          />
          {this.validator.message(
            "repeatNewPassword",
            this.state.repeatNewPassword,
            "required|min:8"
          )}

          <p id="resetpwdErrMsg" style={{ color: "red", marginTop: "10px" }} />
          <button onClick={this.changePassword}>{translate("Save")}</button>
        </form>
      </div>
    );
  }
}

export default withRouter(withTranslate(ChangePassword));
