import React, { Component } from "react";
import ShopComment from "./shopComment";
import ItemCommment from "./itemComment";

export class myComments extends Component {
  render() {
    const { comment } = this.props;
    switch (comment) {
      case 1:
        return <ShopComment />;
      case 2:
        return <ItemCommment />;
    }
  }
}

export default myComments;
