import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslate } from "react-redux-multilingual";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import StarRatingComponent from "react-star-rating-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";
import {
  fetchReportTypes,
  postProductComment,
  postReportTypes,
  reportProductComment,
} from "../../fetch/filterApi";
import ClipLoader from "react-spinners/ClipLoader";
import ReactPaginate from "react-paginate";
import moment from "moment";

import {
  gettingProductComments,
  newProductCommentAdded,
} from "../../actions/index";
class WriteProductComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: null,
      comment: "",
      role: null,
      token: "",
      disableBtn: false,
      registerModal: false,
      open: false,
      reportTypes: [],
      optionSelected: null,
      disabled: false,
      errorMessage: false,
      reportComment: "",
      productCommentId: null,
    };
    this.handleInput = this.handleInput.bind(this);
  }

  componentWillMount() {
    fetchReportTypes().then((res) => {
      this.setState({ reportTypes: res });
    });
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    this.setState({
      role: userObj ? userObj.roles[0].id : "",
      token: userObj ? userObj.token : "",
    });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    this.props.gettingProductComments(this.props.productId, selected + 1);
  };

  handleInput(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onOpenModal = (id) => {
    this.setState({ open: true, productCommentId: id });
  };

  onCloseModal = () =>
    this.setState({
      registerModal: false,
      open: false,
      optionSelected: null,
      reportComment: "",
      errorMessage: false,
      disabled: false,
      productCommentId: null,
    });

  handleOption = async (id) => {
    this.setState({ optionSelected: id });
  };

  handleComment = (event) => {
    this.setState({ reportComment: event.target.value, errorMessage: true });
    if (event.target.value.length >= 10) {
      this.setState({ disabled: true, errorMessage: false });
    } else {
      this.setState({ disabled: false, errorMessage: true });
    }
  };

  sendReport = () => {
    const data = {
      productCommentId: this.state.productCommentId,
      reportTypeId: this.state.optionSelected,
      description: this.state.reportComment,
    };
    reportProductComment(data).then((res) => {
      console.log(res);
      toast.success(`${this.props.translate("reportNotif")}`);
      this.onCloseModal();
    });
  };

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rate: nextValue });
  }

  addProductComment = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const { rate, comment, token, role } = this.state;
      const { productId } = this.props;
      if (token && role == 3) {
        this.setState({ disableBtn: true });
        postProductComment({ rate, comment, productId }, token).then(
          async (res) => {
            if (res.status === 200) {
              console.log("comment added about product");
              const newComment = await res
                .clone()
                .json()
                .then((result) => {
                  setTimeout(() => {
                    this.props.newProductCommentAdded(result);
                    this.setState({
                      disableBtn: false,
                    });
                    toast.success(this.props.translate("Comment_added"));
                  }, 2000);
                });
            } else {
              setTimeout(() => {
                this.setState({
                  disableBtn: false,
                });
              }, 2000);
              console.log("smth error with post product comment");
            }
          }
        );
      } else {
        this.setState({ registerModal: true });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { translate } = this.props;
    const { product_comments, pages } = this.props;
    const { open } = this.state;

    return (
      <section className="tab-product m-0">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <Tabs className="tab-content nav-material">
              <TabList className="nav nav-tabs nav-material">
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-contacts" />
                    {translate("Write_comment")}
                  </span>
                  <div className="material-border" />
                </Tab>
              </TabList>
              <TabPanel>
                {this.state.role !== 1 && this.state.role !== 2 && (
                  <form className="theme-form mt-4 mb-4">
                    <div className="form-row">
                      <div className="col-md-12 mb-2 ">
                        <div>
                          <p className="mb-0">
                            {translate("Total_product_rating")}:
                          </p>
                          <div style={{ fontSize: "24px" }}>
                            <StarRatingComponent
                              name="store_rate"
                              starCount={5}
                              value={this.state.rate}
                              onStarClick={this.onStarClick.bind(this)}
                              onBlur={() =>
                                this.validator.showMessageFor("rate")
                              }
                            />
                          </div>
                          {this.validator.message(
                            "rate",
                            this.state.rate,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mb-2">
                        <p className="mb-2">
                          {translate("Your_comment_about_product")}:
                        </p>
                        <textarea
                          name="comment"
                          className="form-control"
                          placeholder={translate("Why_you_liked")}
                          onChange={this.handleInput}
                          onBlur={() =>
                            this.validator.showMessageFor("comment")
                          }
                          rows="6"
                        />
                        {this.validator.message(
                          "comment",
                          this.state.comment,
                          "required"
                        )}
                      </div>
                      <div className="col-md-12">
                        <button
                          disabled={this.state.disableBtn}
                          style={{
                            cursor: this.state.disableBtn ? "not-allowed" : "",
                            backgroundColor: "#a8cf45",
                            color: "#fff",
                            border: "none",
                            fontSize: "16px",
                            borderRadius: "5px",
                          }}
                          className="px-4 py-2"
                          type="button"
                          onClick={(e) => {
                            this.addProductComment(e);
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ClipLoader
                              color={"#fff"}
                              loading={this.state.disableBtn}
                              size={20}
                            />{" "}
                            <span style={{ marginLeft: "8px" }}>
                              {translate("Put_comment")}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                {product_comments && product_comments.length > 0 && (
                  <div className="mt-4">
                    <div className="mb-4">
                      {product_comments.map((el, index) => {
                        return (
                          <div key={index} className="store-comment-fluid">
                            <div className="comment-author">
                              <div className="author-info">
                                <p>
                                  <i className="fa fa-user" />{" "}
                                  {el.user ? el.user.name : "hi"}{" "}
                                  {el.user ? el.user.surname : "hello"}
                                </p>
                                <p>
                                  <i className="fa fa-calendar" />{" "}
                                  {moment(el.createdAt).format("DD/MM/YYYY")}
                                </p>
                              </div>
                              <div>
                                <StarRatingComponent
                                  className="store-star-rating"
                                  name="store_rate"
                                  starCount={5}
                                  value={el.rate}
                                />
                              </div>
                            </div>
                            <div className="row justify-content-between px-3">
                              <div>
                                <p>{el.comment}</p>
                              </div>

                              <span
                                onClick={() => this.onOpenModal(el.id)}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <svg
                                  style={{
                                    fill: "#009F73",
                                    width: "20px",
                                    marginRight: "5px",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
                                </svg>
                                <b>{translate("complain")}</b>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <ReactPaginate
                      previousLabel={<i className="fa fa-angle-double-left" />}
                      previousClassName={"btn-pagination"}
                      nextLabel={<i className="fa fa-angle-double-right" />}
                      nextClassName={"btn-pagination"}
                      pageClassName={"page-pagination"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active-pagination"}
                    />
                  </div>
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <Modal open={open} onClose={this.onCloseModal} center>
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col py-3">
                    <div style={{ textAlign: "center" }}>
                      <svg
                        style={{
                          fill: "#009F73",
                          width: "80px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
                      </svg>
                    </div>
                    <h4 style={{ margin: "0 30px" }}>
                      {translate("warrningSpam")}
                    </h4>
                    {this.state.reportTypes.length
                      ? this.state.reportTypes.map((item, index) => (
                          <div
                            style={{ cursor: "pointer" }}
                            className="custom-control custom-checkbox collection-filter-checkbox"
                          >
                            <input
                              type="checkbox"
                              checked={this.state.optionSelected == item.id}
                              onChange={() => this.handleOption(item.id)}
                              value={item.id}
                              id={item.id}
                              className="custom-control-input reportCheck"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              className="custom-control-label"
                              htmlFor={item.id}
                            >
                              {this.props.locale === "en"
                                ? item.name_uz
                                : item.name_ru}
                            </label>
                          </div>
                        ))
                      : ""}

                    {this.state.optionSelected !== null ? (
                      <div className="form-group">
                        <label htmlFor="description">
                          {translate("description")}
                        </label>
                        <textarea
                          cols="30"
                          rows="5"
                          value={this.state.reportComment}
                          onChange={this.handleComment}
                          className="form-control"
                          id="description"
                        />
                        {/* <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required=""
                      /> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.errorMessage ? (
                      <p> {translate("requiredMess")}</p>
                    ) : (
                      ""
                    )}
                    <button
                      className="btnWarning"
                      disabled={this.state.disabled ? false : true}
                      onClick={this.sendReport}
                    >
                      {translate("sendReport")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.registerModal}
          onClose={this.onCloseModal}
          center
        >
          <section className="login-page section-b-space">
            <div className="container">
              <div className="row">
                <div className="col right-login">
                  <h3>{translate("Please")}!</h3>
                  <div className="theme-card authentication-right">
                    <p>{translate("Please_login")}</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/login`}
                      className="btn btn-solid"
                    >
                      {translate("Login")}
                    </Link>
                    <p className="mt-2">
                      {translate("Not_yet_account")}{" "}
                      <Link to={`${process.env.PUBLIC_URL}/login`}>
                        {translate("Please_sign_up")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>
        <ToastContainer />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  product_comments: state.product_detail.product_comments,
  productId: state.product_detail.productId,
  pages: state.product_detail.pages,
});

export default connect(
  mapStateToProps,
  { gettingProductComments, newProductCommentAdded }
)(withTranslate(WriteProductComment));
