import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import Slider from "react-slick-ad-height-fixed";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClipLoader from "react-spinners/ClipLoader";
import {
  filterCategory,
  getVisibleproducts,
  helpMainCategory,
  setMoreCategory,
} from "../../../actions";

import { getDefaultCategories } from "../../../fetch/filterApi";

class SpecialProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      currentIndex: 0,
      disableBtn: false,
    };

    this.handleCategoryId = this.handleCategoryId.bind(this);
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  handleCategoryId = async (e, categoryId, mainCategoryId) => {
    e.preventDefault();
    // console.log(categoryId, mainCategoryId);
    await this.props.filterCategory(categoryId);
    await this.props.helpMainCategory({ mainCategoryId, categoryId });
    await this.props.getVisibleproducts(this.props.filters);
    this.props.history.push(
      `${process.env.PUBLIC_URL}/left-sidebar/collection`
    );
  };

  loadMoreBtn = (e, id, page) => {
    e.preventDefault();

    this.setState({ disableBtn: true });
    getDefaultCategories(id, page + 1).then(async (res) => {
      if (res.length < 3) {
        console.log("less 3", page);
        this.setState({ disableBtn: false });
        await this.props.setMoreCategory(id, res, page + 1, false);
      } else {
        console.log("more 3", page);
        getDefaultCategories(id, page + 2).then(async (checkLength) => {
          console.log(checkLength.length, "for check");
          if (checkLength.length === 0) {
            this.setState({ disableBtn: false });
            await this.props.setMoreCategory(id, res, page + 1, false);
          } else {
            this.setState({ disableBtn: false });
            await this.props.setMoreCategory(id, res, page + 1, true);
          }
        });
      }
    });
  };
  render() {
    const { loadmore, translate, locale } = this.props;
    return (
      <div id="category">
        <div className="title1 section-t-space">
          <h2 className="title-inner1">{translate("Categories")}</h2>
        </div>
        <section className="section-b-space p-t-0">
          <div className="container">
            <Slider
              className="remove-slick-before"
              asNavFor={this.state.nav1}
              ref={(slider) => (this.slider2 = slider)}
              slidesToShow={3}
              swipeToSlide={true}
              focusOnSelect={true}
              afterChange={(newIndex) => {
                this.setState({ currentIndex: newIndex });
              }}
            >
              {loadmore.map((el, index) => {
                return (
                  <div key={index}>
                    <p
                      className="titles-of-maincategories"
                      style={{
                        color:
                          this.state.currentIndex === index ? "#a8cf45" : "",
                        backgroundColor:
                          this.state.currentIndex === index
                            ? "rgba(168, 207, 69, 0.05)"
                            : "",
                      }}
                    >
                      {locale == "en" ? el.mainCategory_uz : el.mainCategory_ru}
                    </p>
                  </div>
                );
              })}
            </Slider>
            <Slider
              adaptiveHeight={true}
              className="remove-slick-before"
              asNavFor={this.state.nav2}
              ref={(slider) => (this.slider1 = slider)}
              slidesToShow={1}
            >
              {loadmore.map((mainCategory, index) => {
                return (
                  <div key={index}>
                    <div className="landing-category-list">
                      {mainCategory.categories.map((category, index) => {
                        return (
                          <div
                            className="category"
                            key={index}
                            onClick={(e) => {
                              this.handleCategoryId(
                                e,
                                category.id,
                                mainCategory.id
                              );
                            }}
                          >
                            <div className="img-deco" />
                            <p className="category-title">
                              {locale == "en"
                                ? category.name_uz
                                : category.name_ru}
                            </p>
                            <img
                              src={
                                category.image
                                  ? category.image.imageUrl
                                  : `${
                                      process.env.PUBLIC_URL
                                    }/assets/images/furniture/product/f-1.jpg`
                              }
                              className="img-fluid"
                              alt="category-pics"
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {mainCategory.visibleBtn ? (
                        <button
                          className="btn btn-solid mt-3 mb-3"
                          disabled={this.state.disableBtn}
                          style={{
                            cursor: this.state.disableBtn ? "not-allowed" : "",
                            backgroundColor: this.state.disableBtn
                              ? "#cccccc"
                              : "",
                            border: this.state.disableBtn ? "#cccccc" : "",
                          }}
                          onClick={(e) =>
                            this.loadMoreBtn(
                              e,
                              mainCategory.id,
                              mainCategory.page
                            )
                          }
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ClipLoader
                              color={"#fff"}
                              loading={this.state.disableBtn}
                              size={20}
                            />{" "}
                            <span style={{ marginLeft: "8px" }}>
                              {translate("Loadmore")}
                            </span>
                          </span>
                        </button>
                      ) : (
                        <p>{translate("No_item_left")}</p>
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
  loadmore: state.loadmore.loadmore,
  locale: state.Intl.locale,
});

export default connect(
  mapStateToProps,
  { filterCategory, getVisibleproducts, helpMainCategory, setMoreCategory }
)(withRouter(withTranslate(SpecialProducts)));
