import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { withTranslate } from "react-redux-multilingual";
import { addToCart, addToWishlist, addToCompare } from "../../../actions";
import { getVisibleproducts } from "../../../actions/index";
import ProductListItem from "./product-list-item";
import { fetchFilteredProductBy } from "../../../fetch/filterApi";

class ProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
      page: 1,
      hasMoreItems: true,
      myProducts: [],
      loader: false,
      mainCategoryId: props.mainCategoryId,
    };
  }

  componentWillMount() {
    console.log(this.state.myProducts);
    this.fetchMoreItems();
  }

  fetchMoreItems = () => {
    const { filters } = this.props;
    console.log(
      "🚀 ~ file: product-listing.jsx:31 ~ ProductListing ~ filters:",
      filters
    );
    // console.log("FETCH");
    // console.log("CategoryId: ", filters.categoryId);
    const data = {
      categoryId: filters.categoryId,
    };
    fetchFilteredProductBy(filters, this.state.page, 10).then((res) => {
      // console.log(res);
      if (res.length > 0) {
        // this.setState({
        //   page: this.state.page + 1,
        //   myProducts: this.state.myProducts + res,
        // });
        this.setState((prevState) => ({
          page: this.state.page + 1,
          myProducts: [...prevState.myProducts, ...res],
        }));
        // console.log(this.state.loader);
        console.log(this.state.myProducts);
      } else {
        this.setState({
          loader: true,
        });
        console.log(this.state.loader);
      }
    });

    if (this.state.limit >= this.props.products.length) {
      this.setState({ hasMoreItems: false });
      return;
    }
    // a fake async api call
    setTimeout(() => {
      this.setState({
        limit: this.state.limit + 5,
      });
      // console.log(this.state.limit);
    });
  };

  render() {
    const {
      products,
      addToCart,
      symbol,
      addToWishlist,
      addToCompare,
      translate,
      locale,
    } = this.props;
    // console.log(this.props);
    return (
      <div>
        <div className="product-wrapper-grid">
          <div className="container-fluid">
            <h6 style={{ textAlign: "right" }}>
              {translate("ProductLength")}: {products.length}
            </h6>
            {/* {console.log(this.state.myProducts.length)} */}
            {products.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.limit} //This is important field to render the next data
                next={this.fetchMoreItems}
                hasMore={this.state.hasMoreItems}
                loader={<div className="loading-cls" />}
                endMessage={
                  <p className="seen-cls seen-it-cls">
                    <b>{translate("Change_filter_to_get_more")}</b>
                  </p>
                }
              >
                <div className="row">
                  {products.slice(0, this.state.limit).map((product, index) => (
                    <div
                      className={`${
                        this.props.colSize === 3
                          ? "col-xl-3 col-md-6 col-sm-6 col-6 col-grid-box"
                          : "col-lg-" + this.props.colSize
                      }`}
                      key={index}
                    >
                      <ProductListItem
                        product={product}
                        symbol={symbol}
                        onAddToCompareClicked={() => addToCompare(product)}
                        onAddToWishlistClicked={() =>
                          addToWishlist(product, locale)
                        }
                        onAddToCartClicked={addToCart}
                        key={index}
                      />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div className="row">
                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/empty-search.jpg`}
                    className="img-fluid mb-4"
                  />
                  <h3>{translate("Sorry_not_found_product")}</h3>
                  <p>{translate("Change_filters_please")}</p>
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="btn btn-solid"
                  >
                    {translate("Continue")}
                  </Link>
                </div>
              </div>
            )}

            {/* {this.state.myProducts.length > 0 ? (
              !this.state.loader ? (
                <h1>Product is available</h1>
              ) : (
                <h1>No product</h1>
              )
            ) : (
              <h1>false</h1>
            )} */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  locale: state.Intl.locale,
  products: state.data.products,
  symbol: state.data.symbol,
  filters: state.filters,
});

export default connect(
  mapStateToProps,
  { addToCart, addToWishlist, addToCompare, getVisibleproducts }
)(withTranslate(ProductListing));
