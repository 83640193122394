import React, { Component } from "react";
import { getOwnProductComments } from "./Users";

export class ItemCommment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemComments: [],
    };
  }

  componentDidMount() {
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    getOwnProductComments(userObj.token).then((resComments) => {
      if (resComments.length === 0) {
        console.log("you have not commented yet");
      } else {
        console.log(resComments);
        this.setState({
          itemComments: [...resComments],
        });
      }
    });
  }

  render() {
    if (this.state.itemComments.length === 0) {
      return (
        <h4
          style={{
            textAlign: "center",
          }}
        >
          У вас еще нет комментариев
        </h4>
      );
    } else {
      return (
        <div>
          {this.state.itemComments
            .slice(0)
            .reverse()
            .map((comment, index) => {
              return (
                <div className="comment-container" key={index}>
                  <div className="comment-info">
                    <div>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.54019 5.41097C5.54019 4.15512 6.0594 2.95071 6.98362 2.06269C7.90783 1.17467 9.16134 0.675781 10.4684 0.675781C11.7754 0.675781 13.0289 1.17467 13.9531 2.06269C14.8773 2.95071 15.3966 4.15512 15.3966 5.41097V7.30505C15.3966 8.5609 14.8773 9.76531 13.9531 10.6533C13.0289 11.5413 11.7754 12.0402 10.4684 12.0402C9.16134 12.0402 7.90783 11.5413 6.98362 10.6533C6.0594 9.76531 5.54019 8.5609 5.54019 7.30505V5.41097ZM0.612 16.4724C3.60687 14.8056 7.00741 13.93 10.4684 13.9343C14.0561 13.9343 17.427 14.8529 20.3247 16.4724V19.6165H0.612V16.4724Z"
                          fill="#444444"
                        />
                      </svg>
                      <p>Mavluda Mavludakhon</p>
                      <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.48114 4.46393C0.48114 3.42219 1.36821 2.56986 2.45241 2.56986H16.2513C16.7742 2.56986 17.2756 2.76941 17.6452 3.12462C18.0149 3.47983 18.2226 3.96159 18.2226 4.46393V17.7225C18.2226 18.2248 18.0149 18.7066 17.6452 19.0618C17.2756 19.417 16.7742 19.6165 16.2513 19.6165H2.45241C1.9296 19.6165 1.4282 19.417 1.05851 19.0618C0.688827 18.7066 0.48114 18.2248 0.48114 17.7225V4.46393ZM2.45241 6.35801V17.7225H16.2513V6.35801H2.45241ZM4.42369 0.675781H6.39496V2.56986H4.42369V0.675781ZM12.3088 0.675781H14.2801V2.56986H12.3088V0.675781ZM4.42369 9.19912H6.39496V11.0932H4.42369V9.19912ZM4.42369 12.9873H6.39496V14.8813H4.42369V12.9873ZM8.36624 9.19912H10.3375V11.0932H8.36624V9.19912ZM8.36624 12.9873H10.3375V14.8813H8.36624V12.9873ZM12.3088 9.19912H14.2801V11.0932H12.3088V9.19912ZM12.3088 12.9873H14.2801V14.8813H12.3088V12.9873Z"
                          fill="#999999"
                        />
                      </svg>
                      <p>{comment.updatedAt.slice(0, 10)}</p>
                    </div>
                    <div>
                      <p>Отлично</p>
                      <svg
                        width="92"
                        height="16"
                        viewBox="0 0 92 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.56483 0.571289L10.3028 5.71062H15.9268L11.3769 8.8869L13.1148 14.0262L8.56483 10.85L4.01487 14.0262L5.7528 8.8869L1.20284 5.71062H6.8269L8.56483 0.571289Z"
                          fill="#FF6C00"
                        />
                        <path
                          d="M27.3643 0.570312L29.1022 5.70964H34.7263L30.1763 8.88593L31.9142 14.0253L27.3643 10.849L22.8143 14.0253L24.5522 8.88593L20.0023 5.70964H25.6263L27.3643 0.570312Z"
                          fill="#FF6C00"
                        />
                        <path
                          d="M46.1635 0.570312L47.9014 5.70964H53.5255L48.9755 8.88593L50.7134 14.0253L46.1635 10.849L41.6135 14.0253L43.3514 8.88593L38.8015 5.70964H44.4255L46.1635 0.570312Z"
                          fill="#FF6C00"
                        />
                        <path
                          d="M64.9627 0.570312L66.7006 5.70964H72.3247L67.7747 8.88593L69.5126 14.0253L64.9627 10.849L60.4127 14.0253L62.1506 8.88593L57.6007 5.70964H63.2247L64.9627 0.570312Z"
                          fill="#FF6C00"
                        />
                        <path
                          d="M83.7623 0.570312L85.5003 5.70964H91.1243L86.5744 8.88593L88.3123 14.0253L83.7623 10.849L79.2124 14.0253L80.9503 8.88593L76.4003 5.70964H82.0244L83.7623 0.570312Z"
                          fill="#FF6C00"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="comment-text">
                    <p>{comment.comment}</p>
                    <div className="comment-for">
                      <p>Alfa Mebel</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      );
    }
  }
}

export default ItemCommment;
