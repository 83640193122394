import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { Mail, MapPin } from "react-feather";
import SimpleReactValidator from "simple-react-validator";
import ClipLoader from "react-spinners/ClipLoader";
import _ from "lodash";
import { putUpdateUser } from "./Users";
import { postLogoImage } from "../user/Auth";

export class editProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      name: "",
      surname: "",
      email: "",
      phone: "",
      genderId: "",
      birthday: "",
      address: "",
      genderOptions: [],
      file: "",
      fileTypes: ["jpg", "jpeg", "png"],
      avatar: "",
      imageId: null,
      imageChanged: false,
      disableBtn: false,
    };
    this.handleEditProfile = this.handleEditProfile.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });

    const { userData, gender } = this.props;
    this.setState({
      name: userData.name || "",
      surname: userData.surname || "",
      email: userData.email || "",
      phone: userData.phone || "",
      genderId: userData.genderId === null ? "0" : userData.genderId.toString(),
      birthday:
        userData.birthday === null ? "" : userData.birthday.slice(0, 10),
      address: userData.address || "",
      imageId: userData.imageId || null,
      avatar: userData.image
        ? userData.image.imageUrl ||
          `${process.env.PUBLIC_URL}/assets/images/awa.png`
        : `${process.env.PUBLIC_URL}/assets/images/awa.png`,
    });

    const genders = [];
    gender.map((g) => {
      genders.push({
        id: g.id,
        name: this.props.locale === "en" ? g.name_uz : g.name_ru,
      });
    });
    this.setState({ genderOptions: genders });
  }

  componentDidMount() {
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    this.setState({ token: userObj.token });
  }

  handleEditProfile(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleUserAvatar(e) {
    e.preventDefault();
    this.setState({ file: "", avatar: "" });
    document.querySelector(".avatar-error-img").innerHTML = "";
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      var isSuccess = this.state.fileTypes.indexOf(extension) > -1;
    }
    if (isSuccess) {
      reader.onloadend = () => {
        let finalImg = reader.result;
        this.setState({
          imageChanged: true,
          file: file,
          avatar: finalImg,
        });
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      console.log("error img");
      document.querySelector(
        ".avatar-error-img"
      ).innerHTML = this.props.translate("Wrong_format");
    }
  }

  editButton = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ disableBtn: true });
      const {
        name,
        surname,
        genderId,
        birthday,
        address,
        imageId,
      } = this.state;

      const finalArray = {
        name,
        surname,
        genderId: parseInt(genderId, 10) !== 0 ? parseInt(genderId, 10) : null,
        birthday: new Date(birthday).getTime() || null,
        address,
        imageId,
      };

      if (this.state.imageChanged) {
        postLogoImage(this.state.file).then(async (res) => {
          const imgData = await res
            .clone()
            .json()
            .then((imgJson) => {
              putUpdateUser(this.state.token, {
                ...finalArray,
                imageId: imgJson.id,
              }).then(async (res) => {
                if (res.status == 200) {
                  await this.props.getUser(this.state.token);
                  setTimeout(() => {
                    this.setState({ disableBtn: false });
                    this.props.changePage(1);
                  }, 2000);
                }
              });
            });
        });
      } else {
        putUpdateUser(this.state.token, finalArray).then(async (res) => {
          if (res.status == 200) {
            await this.props.getUser(this.state.token);
            setTimeout(() => {
              this.setState({ disableBtn: false });
              this.props.changePage(1);
            }, 2000);
          }
        });
      }
    }
    {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="edit-profile-container">
        <h3 className="profile-sidebar-title">
          {translate("Edit_personal_info")}
        </h3>
        <div className="edit-user-awatar">
          <div className="user-edit-img">
            <label htmlFor="edit-img-u" className="edit-avatar-upload-label">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                  stroke="white"
                  strokeOpacity="0.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                  stroke="white"
                  strokeOpacity="0.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </label>
            <input
              id="edit-img-u"
              type="file"
              onChange={(e) => this.handleUserAvatar(e)}
              className="edit-avatar-upload"
            />
            <img
              className="avatar-preview"
              src={this.state.avatar}
              style={{ objectFit: "cover" }}
              alt="avatar"
            />
          </div>

          <div>
            <p className="edit-user-name">
              {this.state.name} {this.state.surname}
            </p>
            {this.state.email && (
              <p>
                <Mail size={12} className="edit-feather-icons" />
                {this.state.email}
              </p>
            )}
            {this.state.address && (
              <p>
                <MapPin size={12} className="edit-feather-icons" />
                {this.state.address}
              </p>
            )}
          </div>
        </div>
        <p className="avatar-error-img" />
        <div>
          <form>
            <div className="edit-profile-form">
              <div>
                <label htmlFor="">{translate("Name")}</label>
                <input
                  type="text"
                  placeholder={
                    this.state.name ? this.state.name : translate("Not_given")
                  }
                  name="name"
                  value={this.state.name}
                  onChange={this.handleEditProfile}
                  onBlur={() => this.validator.showMessageFor("name")}
                />
                {this.validator.message("name", this.state.name, "required")}
                {/* <label htmlFor="">{translate("Email")}</label>
                <input
                  type="text"
                  placeholder={this.state.email}
                  name="email"
                  value={this.state.email}
                  onChange={this.handleEditProfile}
                  onBlur={() => this.validator.showMessageFor("email")}
                />
                {this.validator.message(
                  "email",
                  this.state.email,
                  "required|email"
                )} */}
                <label htmlFor="">{translate("Gender")}</label>
                <select
                  name="genderId"
                  value={this.state.genderId}
                  onChange={this.handleEditProfile}
                >
                  <option value="0" style={{ display: "none" }}>
                    Не выбран
                  </option>
                  {this.state.genderOptions.map((el, index) => (
                    <option key={index} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="">{translate("Address")}</label>
                <input
                  type="text"
                  placeholder={
                    this.state.address
                      ? this.state.address
                      : translate("Not_given")
                  }
                  name="address"
                  value={this.state.address}
                  onChange={this.handleEditProfile}
                />
              </div>
              <div>
                <label htmlFor="">{translate("Surname")}</label>
                <input
                  type="text"
                  placeholder={
                    this.state.surname
                      ? this.state.surname
                      : translate("Not_given")
                  }
                  name="surname"
                  value={this.state.surname}
                  onChange={this.handleEditProfile}
                  onBlur={() => this.validator.showMessageFor("surname")}
                />
                {this.validator.message(
                  "surname",
                  this.state.surname,
                  "required"
                )}
                {/* <label htmlFor="">{translate("Phone_number")}</label>
                <input
                  type="text"
                  placeholder={
                    this.state.phone ? this.state.phone : translate("Not_given")
                  }
                  name="phone"
                  value={this.state.phone}
                  onChange={this.handleEditProfile}
                /> */}
                <label htmlFor="">{translate("Birthday")}</label>
                <input
                  type="date"
                  name="birthday"
                  value={this.state.birthday}
                  onChange={this.handleEditProfile}
                />
              </div>
            </div>

            <button
              disabled={this.state.disableBtn}
              style={{
                cursor: this.state.disableBtn ? "not-allowed" : "",
                backgroundColor: this.state.disableBtn ? "#cccccc" : "",
                border: this.state.disableBtn ? "#cccccc" : "",
              }}
              onClick={this.editButton}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  color={"#fff"}
                  loading={this.state.disableBtn}
                  size={20}
                />{" "}
                <span style={{ marginLeft: "8px" }}> {translate("Save")} </span>
              </span>
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslate(editProfile);
