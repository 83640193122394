import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/logo/mebzone-logo.svg";

function LogoImage(props) {
  if (props.shopImage) {
    return (
      <img
        src={props.shopImage}
        alt="Seller Logo"
        className="img-fluid"
        style={{ height: "90px" }}
      />
    );
  } else {
    return (
      <Link to={`${process.env.PUBLIC_URL}/`}>
        <img src={Logo} alt="Mebzone logo" className="img-fluid" />
      </Link>
    );
  }
}

export default LogoImage;
