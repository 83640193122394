import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";

import { connect } from "react-redux";
import {
  filterCategory,
  getVisibleproducts,
  helpMainCategory,
} from "../../../../actions";

class MebSideBar extends Component {
  constructor(props) {
    super(props);
    this.handleCategoryId = this.handleCategoryId.bind(this);
  }

  closeNav() {
    var closemyslide = document.getElementById("mySidenav");
    if (closemyslide) closemyslide.classList.remove("open-side");
  }

  handleSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub1"))
      event.target.nextElementSibling.classList.remove("opensub1");
    else {
      document.querySelectorAll(".opensub1").forEach(function(value) {
        value.classList.remove("opensub1");
      });
      event.target.nextElementSibling.classList.add("opensub1");
    }
  };
  handleSubTwoMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub2"))
      event.target.nextElementSibling.classList.remove("opensub2");
    else {
      document.querySelectorAll(".opensub2").forEach(function(value) {
        value.classList.remove("opensub2");
      });
      event.target.nextElementSibling.classList.add("opensub2");
    }
  };
  handleSubThreeMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub3"))
      event.target.nextElementSibling.classList.remove("opensub3");
    else {
      document.querySelectorAll(".opensub3").forEach(function(value) {
        value.classList.remove("opensub3");
      });
      event.target.nextElementSibling.classList.add("opensub3");
    }
  };
  handleSubFourMenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensub4"))
      event.target.nextElementSibling.classList.remove("opensub4");
    else {
      document.querySelectorAll(".opensub4").forEach(function(value) {
        value.classList.remove("opensub4");
      });
      event.target.nextElementSibling.classList.add("opensub4");
    }
  };

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensidesubmenu"))
      event.target.nextElementSibling.classList.remove("opensidesubmenu");
    else {
      event.target.nextElementSibling.classList.add("opensidesubmenu");
    }
  };

  handleCategoryId = async (e, categoryId, mainCategoryId) => {
    e.preventDefault();
    console.log(categoryId, mainCategoryId);
    await this.props.filterCategory(categoryId);
    await this.props.helpMainCategory({ mainCategoryId, categoryId });
    this.props.getVisibleproducts(this.props.filters);
    await localStorage.removeItem("hidefilters");
    this.props.history.push(
      `${process.env.PUBLIC_URL}/left-sidebar/collection`
    );
  };

  render() {
    const { mainCategories, translate, locale } = this.props;

    return (
      <div id="mySidenav" className="sidenav">
        <a
          href="javascript:void(0)"
          className="sidebar-overlay"
          onClick={this.closeNav}
        />
        {/* <nav>
          <a onClick={this.closeNav}>
            <div className="sidebar-back text-left">
              <i className="fa fa-angle-left pr-2" aria-hidden="true" />
              {translate("Back")}
            </div>
          </a>
          <ul id="sub-menu" className="sidebar-menu">
            {mainCategories.map((mainCategory, index) => {
              return (
                <li key={index}>
                  <Link to="#" onClick={(e) => this.handleSubmenu(e)}>
                    {locale == "en"
                      ? mainCategory.name_uz
                      : mainCategory.name_ru}
                    <span className="sub-arrow" />
                  </Link>

                  <ul className="mega-menu">
                    {mainCategory.categories.map((category, index) => {
                      return (
                        <li
                          key={index}
                          onClick={(e) =>
                            this.handleCategoryId(
                              e,
                              category.id,
                              mainCategory.id
                            )
                          }
                        >
                          <Link to="#">
                            {locale == "en"
                              ? category.name_uz
                              : category.name_ru}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </nav> */}
        <nav>
          <a onClick={this.closeNav}>
            <div className="sidebar-back text-left">
              <i className="fa fa-angle-left pr-2" aria-hidden="true" />
              {translate("Back")}
            </div>
          </a>
          <ul id="sub-menu" className="sidebar-menu">
            {mainCategories.map((el) => {
              return el.categories.map((category, index) => {
                return (
                  <li key={index}>
                    <Link
                      to="#"
                      onClick={(e) =>
                        this.handleCategoryId(
                          e,
                          category.id,
                          category.mainCategoryId
                        )
                      }
                    >
                      {locale == "en" ? category.name_uz : category.name_ru}
                    </Link>
                  </li>
                );
              });
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainCategories: state.mainCategories.mainCategories,
  filters: state.filters,
  locale: state.Intl.locale,
});

export default connect(
  mapStateToProps,
  { filterCategory, getVisibleproducts, helpMainCategory }
)(withRouter(withTranslate(MebSideBar)));
