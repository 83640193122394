import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { SlideToggle } from "react-slide-toggle";
import { withTranslate } from "react-redux-multilingual";

import {
  filterMainCategory,
  filterCategory,
  filterPrice,
  filterAvailable,
  filterDeliverable,
  filterRegion,
  filterStore,
  filterAttributes,
  getVisibleproducts,
  helpMainCategory,
  helpOnlyMainCategory,
  setPriceRange,
} from "../../../actions";
import {
  fetchSellers,
  fetchMinMaxPrice,
  fetchRegions,
  fetchFilters,
} from "../../../fetch/filterApi";
import ProductListing from "./product-listing";

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [],
      regions: [],
      typeOne: [],
      typeTwo: [],
      typeThree: [],
      optionSelected: null,
      toggleSelected: null,
      minmax: null,
      optionAvailable: null,
      optionDelivery: null,
      attributes: [],
      stringOptions: new Map(),
      stringAttributes: new Map(),
      booleanOptions: new Map(),
      booleanAttributes: new Map(),
      intRanges: new Map(),
      intAttributes: new Map(),
    };
  }

  componentWillMount() {
    this.props.getVisibleproducts(this.props.filters);
    const categoryId = this.props.help.categoryId;
    const mainCategoryId = this.props.help.mainCategoryId;
    if (mainCategoryId) {
      this.setState({ toggleSelected: mainCategoryId });
    }
    if (categoryId) {
      this.setState({
        optionSelected: categoryId,
      });
      const id = Number(categoryId);
      fetchMinMaxPrice(id).then(async (res) => {
        if (res.min && res.max) {
          const { USD_UZS } = this.props.rate;
          if (this.props.symbol === "$") {
            const range = {
              min: (res.min / USD_UZS).toFixed(2),
              max: (res.max / USD_UZS).toFixed(2),
            };
            await this.props.setPriceRange(range);
          } else {
            await this.props.setPriceRange(res);
          }
          this.setState({ minmax: res });
        } else {
          this.setState({ minmax: null });
        }
      });
      fetchFilters(id).then((flist) => {
        const typeOne = flist.filters.filter((f) => f.dataTypeId === 1);
        const typeTwo = flist.filters.filter((f) => f.dataTypeId === 2);
        const typeThree = flist.filters.filter((f) => f.dataTypeId === 3);
        this.setState({ typeOne, typeTwo, typeThree });
        const { intRanges } = this.state;
        this.state.typeOne.map((el) => {
          intRanges.set(el.id, { min: el.min, max: el.max });
        });
        this.setState({ intRanges });
      });
    }

    fetchSellers().then((slist) => {
      let newArray = [];
      slist.map((s) => {
        newArray.push({ value: s.id, label: s.brandname });
      });
      this.setState({ stores: newArray });
    });
    fetchRegions().then((rlist) => {
      let newArray = [];
      rlist.map((r) => {
        newArray.push({
          value: r.id,
          label: this.props.locale == "en" ? r.name_uz : r.name_ru,
        });
      });
      this.setState({ regions: newArray });
    });
  }
  // componentWillReceiveProps = () => {
  //   // console.log("useEffect", this.state.optionSelected);
  //   return <ProductListing categoryId={this.state.optionSelected} />;
  // };

  componentWillUnmount() {
    this.setState({
      optionSelected: null,
      toggleSelected: null,
    });
  }

  closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  };

  handleOption = async (id, mainCategoryId) => {
    if (this.state.optionSelected == id) {
      // console.log("here");
      this.setState({ optionSelected: null, minmax: null });
      await this.props.filterMainCategory(mainCategoryId);
      await this.props.helpOnlyMainCategory({ mainCategoryId });
      this.props.getVisibleproducts(this.props.filters);
    } else {
      // console.log("there");
      this.setState({ optionSelected: id });
      await this.props.filterCategory(id);
      await this.props.helpMainCategory({ mainCategoryId, categoryId: id });
      this.props.getVisibleproducts(this.props.filters);
      fetchMinMaxPrice(id).then(async (res) => {
        if (res.min && res.max) {
          const { USD_UZS } = this.props.rate;
          if (this.props.symbol === "$") {
            const range = {
              min: (res.min / USD_UZS).toFixed(2),
              max: (res.max / USD_UZS).toFixed(2),
            };
            await this.props.setPriceRange(range);
          } else {
            await this.props.setPriceRange(res);
          }
          this.setState({ minmax: res });
        } else {
          this.setState({ minmax: null });
        }
      });
      fetchFilters(id).then((flist) => {
        const typeOne = flist.filters.filter((f) => f.dataTypeId === 1);
        const typeTwo = flist.filters.filter((f) => f.dataTypeId === 2);
        const typeThree = flist.filters.filter((f) => f.dataTypeId === 3);
        this.setState({ typeOne, typeTwo, typeThree });
        const { intRanges } = this.state;
        this.state.typeOne.map((el) => {
          intRanges.set(el.id, { min: el.min, max: el.max });
        });
        this.setState({ intRanges });
      });
    }
  };

  handleRegions = async (selectedRegion) => {
    // console.log(selectedRegion);
    if (selectedRegion) {
      await this.props.filterRegion({ regionId: selectedRegion.value });
      this.props.getVisibleproducts(this.props.filters);
    } else {
      await this.props.filterRegion({ regionId: null });
      this.props.getVisibleproducts(this.props.filters);
    }
  };

  handleStore = async (selectedStore) => {
    // console.log(selectedStore);
    if (selectedStore) {
      await this.props.filterStore({ storeId: selectedStore.value });
      this.props.getVisibleproducts(this.props.filters);
    } else {
      await this.props.filterStore({ storeId: null });
      this.props.getVisibleproducts(this.props.filters);
    }
  };

  handlePrice = async (value) => {
    // console.log("hii=>", value);
    const { USD_UZS } = this.props.rate;
    await this.props.setPriceRange(value);
    if (this.props.symbol === "$") {
      await this.props.filterPrice([
        (value.min * USD_UZS).toFixed(2),
        (value.max * USD_UZS).toFixed(2),
      ]);
    } else {
      await this.props.filterPrice([value.min, value.max]);
    }
  };

  handleAvailable = async (b) => {
    if (this.state.optionAvailable === b) {
      this.setState({ optionAvailable: null });
      await this.props.filterAvailable({ isAvailable: null });
      this.props.getVisibleproducts(this.props.filters);
    } else {
      this.setState({ optionAvailable: b });
      await this.props.filterAvailable({ isAvailable: b });
      this.props.getVisibleproducts(this.props.filters);
    }
  };

  handleDelivery = async (b) => {
    if (this.state.optionDelivery === b) {
      this.setState({ optionDelivery: null });
      await this.props.filterDeliverable({ isDeliverable: null });
      this.props.getVisibleproducts(this.props.filters);
    } else {
      this.setState({ optionDelivery: b });
      await this.props.filterDeliverable({ isDeliverable: b });
      this.props.getVisibleproducts(this.props.filters);
    }
  };

  handleStringFilter = async (optionId, filterId, index, stringValue_ru) => {
    const { stringOptions, stringAttributes } = this.state;
    if (stringOptions.get(index) == optionId) {
      stringOptions.delete(index);
      stringAttributes.delete(index);
      await this.setState({
        stringOptions,
        stringAttributes,
        attributes: [
          ...this.state.booleanAttributes.values(),
          ...this.state.intAttributes.values(),
          ...stringAttributes.values(),
        ],
      });
      await this.props.filterAttributes(this.state.attributes);
      this.props.getVisibleproducts(this.props.filters);
    } else {
      stringOptions.set(index, optionId);
      stringAttributes.set(index, { filterId, stringValue_ru });
      await this.setState({
        stringOptions,
        stringAttributes,
        attributes: [
          ...this.state.booleanAttributes.values(),
          ...this.state.intAttributes.values(),
          ...stringAttributes.values(),
        ],
      });
      await this.props.filterAttributes(this.state.attributes);
      this.props.getVisibleproducts(this.props.filters);
    }
  };

  handleBooleanFilter = async (index, id, b) => {
    const { booleanOptions, booleanAttributes } = this.state;
    if (booleanOptions.get(index) == b) {
      booleanOptions.delete(index);
      booleanAttributes.delete(index);
      await this.setState({
        booleanOptions,
        booleanAttributes,
        attributes: [
          ...this.state.stringAttributes.values(),
          ...this.state.intAttributes.values(),
          ...booleanAttributes.values(),
        ],
      });
      await this.props.filterAttributes(this.state.attributes);
      this.props.getVisibleproducts(this.props.filters);
    } else {
      booleanOptions.set(index, b);
      booleanAttributes.set(index, { filterId: id, booleanValue: b });
      await this.setState({
        booleanOptions,
        booleanAttributes,
        attributes: [
          ...this.state.stringAttributes.values(),
          ...this.state.intAttributes.values(),
          ...booleanAttributes.values(),
        ],
      });
      await this.props.filterAttributes(this.state.attributes);
      this.props.getVisibleproducts(this.props.filters);
    }
  };

  handleIntFilter = async (value, id) => {
    const { intRanges, intAttributes } = this.state;
    intRanges.set(id, value);
    await this.setState({
      intRanges,
    });
    await intAttributes.set(id, {
      filterId: id,
      intValue: [value.min, value.max],
    });
    await this.setState({
      intAttributes,
      attributes: [
        ...this.state.stringAttributes.values(),
        ...this.state.booleanAttributes.values(),
        ...intAttributes.values(),
      ],
    });
    await this.props.filterAttributes(this.state.attributes);
  };

  rangeComplete = () => {
    this.props.getVisibleproducts(this.props.filters);
  };

  render() {
    const { symbol, translate, locale } = this.props;
    const { USD_UZS } = this.props.rate;
    return (
      <div className="collection-filter-block">
        <div className="collection-mobile-back">
          <span className="filter-back" onClick={(e) => this.closeFilter(e)}>
            <i className="fa fa-angle-left" aria-hidden="true" />{" "}
            {translate("Back")}
          </span>
        </div>

        {/* category filters start here */}
        {this.props.mainCategories.length > 0
          ? this.props.mainCategories.map((mainCategory, index) => (
              <SlideToggle
                key={index}
                collapsed={!(this.state.toggleSelected == mainCategory.id)}
              >
                {({ onToggle, setCollapsibleElement }) => (
                  <div className="collection-collapse-block">
                    <h3 className="collapse-block-title" onClick={onToggle}>
                      {locale == "en"
                        ? mainCategory.name_uz
                        : mainCategory.name_ru}
                    </h3>
                    <div
                      className="collection-collapse-block-content"
                      ref={setCollapsibleElement}
                    >
                      <div className="collection-brand-filter">
                        {mainCategory.categories.length > 0
                          ? mainCategory.categories.map((category, index) => {
                              return (
                                <div
                                  className="custom-control custom-checkbox collection-filter-checkbox"
                                  key={index}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      this.state.optionSelected == category.id
                                    }
                                    onChange={() =>
                                      this.handleOption(
                                        category.id,
                                        mainCategory.id
                                      )
                                    }
                                    value={category.id}
                                    className="custom-control-input"
                                    id={category.id}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={category.id}
                                  >
                                    {locale == "en"
                                      ? category.name_uz
                                      : category.name_ru}
                                  </label>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
              </SlideToggle>
            ))
          : ""}

        {/*price filter start here */}
        {this.state.minmax ? (
          <SlideToggle>
            {({ onToggle, setCollapsibleElement }) => (
              <div className="collection-collapse-block open">
                <h3 className="collapse-block-title" onClick={onToggle}>
                  {translate("Price")}
                </h3>
                <div
                  className="collection-collapse-block-content block-price-content"
                  ref={setCollapsibleElement}
                >
                  <div className="collection-brand-filter">
                    <div
                      className="custom-control custom-checkbox collection-filter-checkbox px-5"
                      style={{ paddingTop: "20px" }}
                    >
                      <InputRange
                        maxValue={
                          symbol === "$"
                            ? Number(
                                (this.state.minmax.max / USD_UZS).toFixed(2)
                              )
                            : Number(this.state.minmax.max)
                        }
                        minValue={
                          symbol === "$"
                            ? Number(
                                (this.state.minmax.min / USD_UZS).toFixed(2)
                              )
                            : Number(this.state.minmax.min)
                        }
                        formatLabel={(value) =>
                          symbol === "$" ? `$${value}` : `${value} UZS`
                        }
                        value={this.props.selectedPriceRanges}
                        onChange={(value) => this.handlePrice(value)}
                        onChangeComplete={() => this.rangeComplete()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        ) : (
          ""
        )}

        {/* Seller filter start here */}
        <div>
          <h3 className="seller-region-title">{translate("Brands")}</h3>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={this.state.stores}
            placeholder={translate("Find_brand")}
            onChange={this.handleStore}
            isClearable="false"
            isSearchable="true"
          />
        </div>

        {/* Region filter start here */}
        <div>
          <h3 className="seller-region-title">{translate("Regions")}</h3>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={this.state.regions}
            placeholder={translate("Select_region")}
            onChange={this.handleRegions}
            isClearable="false"
            isSearchable="true"
          />
        </div>

        {/* Available filter start here */}
        <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block">
              <h3 className="collapse-block-title" onClick={onToggle}>
                {translate("Available")}
              </h3>
              <div
                className="collection-collapse-block-content"
                ref={setCollapsibleElement}
              >
                <div
                  className="collection-brand-filter"
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <div className="custom-control custom-checkbox collection-filter-checkbox mr-4">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.optionAvailable === true}
                      value={true}
                      id="isAvailableYes"
                      onChange={() => {
                        this.handleAvailable(true);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isAvailableYes"
                    >
                      {translate("Yes")}
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox collection-filter-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.optionAvailable === false}
                      value={false}
                      id="isAvailableNo"
                      onChange={() => {
                        this.handleAvailable(false);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isAvailableNo"
                    >
                      {translate("No")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SlideToggle>

        {/* Delivery filter start here */}
        <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block">
              <h3 className="collapse-block-title" onClick={onToggle}>
                {translate("Delivery")}
              </h3>
              <div
                className="collection-collapse-block-content"
                ref={setCollapsibleElement}
              >
                <div
                  className="collection-brand-filter"
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <div className="custom-control custom-checkbox collection-filter-checkbox mr-4">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.optionDelivery === true}
                      value={true}
                      id="isDeliverableYes"
                      onChange={() => {
                        this.handleDelivery(true);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isDeliverableYes"
                    >
                      {translate("Yes")}
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox collection-filter-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={this.state.optionDelivery === false}
                      value={false}
                      id="isDeliverableNo"
                      onChange={() => {
                        this.handleDelivery(false);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isDeliverableNo"
                    >
                      {translate("No")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SlideToggle>

        {/* String type filters start here */}
        {this.state.typeTwo.map((stringfilter, index) => (
          <SlideToggle key={index}>
            {({ onToggle, setCollapsibleElement }) => (
              <div className="collection-collapse-block">
                <h3 className="collapse-block-title" onClick={onToggle}>
                  {locale == "en" ? stringfilter.name_uz : stringfilter.name_ru}
                </h3>
                <div
                  className="collection-collapse-block-content"
                  ref={setCollapsibleElement}
                >
                  <div className="collection-brand-filter">
                    {stringfilter.filterOptions.length > 0
                      ? stringfilter.filterOptions.map((option, i) => {
                          return (
                            <div
                              key={i}
                              className="custom-control custom-checkbox collection-filter-checkbox"
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={
                                  this.state.stringOptions.get(index) ==
                                  option.id
                                }
                                value={option.id}
                                id={option.stringValue_ru}
                                onChange={() =>
                                  this.handleStringFilter(
                                    option.id,
                                    option.filterId,
                                    index,
                                    option.stringValue_ru
                                  )
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={option.stringValue_ru}
                              >
                                {locale == "en"
                                  ? option.stringValue_uz
                                  : option.stringValue_ru}
                              </label>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        ))}

        {/* boolean type filters start here */}
        {this.state.typeThree.map((boolfilter, index) => (
          <SlideToggle key={index}>
            {({ onToggle, setCollapsibleElement }) => (
              <div className="collection-collapse-block">
                <h3 className="collapse-block-title" onClick={onToggle}>
                  {locale == "en" ? boolfilter.name_uz : boolfilter.name_ru}
                </h3>
                <div
                  className="collection-collapse-block-content"
                  ref={setCollapsibleElement}
                >
                  <div
                    className="collection-brand-filter"
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <div className="custom-control custom-checkbox collection-filter-checkbox mr-4">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={this.state.booleanOptions.get(index) == true}
                        name={boolfilter.name_ru}
                        id={boolfilter.name_ru + "yes"}
                        value={true}
                        onChange={() =>
                          this.handleBooleanFilter(index, boolfilter.id, true)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={boolfilter.name_ru + "yes"}
                      >
                        {translate("Yes")}
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox collection-filter-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={this.state.booleanOptions.get(index) == false}
                        name={boolfilter.name_ru}
                        id={boolfilter.name_ru + "no"}
                        value={false}
                        onChange={() =>
                          this.handleBooleanFilter(index, boolfilter.id, false)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={boolfilter.name_ru + "no"}
                      >
                        {translate("No")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        ))}

        {/* int type filters start here */}
        {this.state.typeOne.map((intfilter, index) => (
          <SlideToggle key={index}>
            {({ onToggle, setCollapsibleElement }) => (
              <div className="collection-collapse-block open">
                <h3 className="collapse-block-title" onClick={onToggle}>
                  {locale == "en" ? intfilter.name_uz : intfilter.name_ru}
                </h3>
                <div
                  className="collection-collapse-block-content block-price-content"
                  ref={setCollapsibleElement}
                >
                  <div className="collection-brand-filter">
                    <div
                      className="custom-control custom-checkbox collection-filter-checkbox"
                      style={{ paddingTop: "20px" }}
                    >
                      <InputRange
                        maxValue={intfilter.max}
                        minValue={intfilter.min}
                        value={this.state.intRanges.get(intfilter.id)}
                        onChange={(value) => {
                          this.handleIntFilter(value, intfilter.id);
                        }}
                        onChangeComplete={() => this.rangeComplete()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainCategories: state.mainCategories.mainCategories,
  filters: state.filters,
  help: state.help.mainCategory,
  symbol: state.data.symbol,
  rate: state.data.rate,
  selectedPriceRanges: state.data.selected_price_ranges,
  locale: state.Intl.locale,
});

export default connect(
  mapStateToProps,
  {
    filterMainCategory,
    filterCategory,
    filterPrice,
    filterAvailable,
    filterDeliverable,
    filterStore,
    filterRegion,
    filterAttributes,
    getVisibleproducts,
    helpOnlyMainCategory,
    helpMainCategory,
    setPriceRange,
  }
)(withTranslate(Filter));
