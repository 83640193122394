const getUserData = async (token = {}) => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/users/me`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  try {
    const newData = await req.json();
    return newData;
  } catch (error) {
    console.log("error", error);
  }
};

const getGenderData = async () => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/genders`);
  try {
    const genderData = await req.json();
    return genderData;
  } catch (error) {
    console.log("error", error);
  }
};

const putUpdateUser = async (token, data) => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/users`, {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  try {
    const r = await req;
    return r;
  } catch (error) {
    console.log("error", error);
  }
};

const postChangePassword = async (data = {}) => {
  // console.log(data);
  const newData = {
    oldPassword: data.oldPassword,
    newPassword: data.newPassword,
    repeatNewPassword: data.repeatNewPassword,
  };

  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/users/password`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + data.token,
      },
      body: JSON.stringify(newData),
    }
  );
  try {
    const r = await req;
    return r;
  } catch (error) {
    console.log("error", error);
  }
};

const getSellerOwnShops = async (token = {}) => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/stores`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  try {
    const shops = await req.json();
    return shops;
  } catch (error) {
    console.log("error", error);
  }
};

const postCreateNewStore = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/stores/private`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const s = await req;
    return s;
  } catch (error) {
    console.log("error", error);
  }
};

const getOwnShopComments = async (token = {}) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/storecomments/private`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const shops = await req.json();
    return shops;
  } catch (error) {
    console.log("error", error);
  }
};

const getOwnProductComments = async (token = {}) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/productcomments/private`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const shops = await req.json();
    return shops;
  } catch (error) {
    console.log("error", error);
  }
};

const getTempToken = async (token = {}) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/auth/tempToken`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  try {
    const tempToken = await req.json();
    //  console.log("tempToken:  " + JSON.stringify(tempToken, null, 2));
    return tempToken;
  } catch (error) {
    console.log("error", error);
  }
};

const updateStore = async (token, data, storeId) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/stores/${storeId}`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const r = await req;
    return r;
  } catch (error) {
    console.log("error", error);
  }
};

const postChangeEmail = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/users/emailChange`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const r = await req;
    return r;
  } catch (error) {
    console.log("error", error);
  }
};

const postConfirmCode = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/users/emailChange/code`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const r = await req;
    return r;
  } catch (error) {
    console.log("error", error);
  }
};

const postChangePhone = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/users/phoneChange`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const r = await req;
    return r;
  } catch (error) {
    console.log("error", error);
  }
};

const postPhoneConfirmCode = async (token, data) => {
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/users/phoneChange/code`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const r = await req;
    return r;
  } catch (error) {
    console.log("error", error);
  }
};

const getWeekdays = async () => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/weekdays`);
  try {
    const weekdays = await req.json();
    return weekdays;
  } catch (error) {
    console.log("error", error);
  }
};


const checkBrandname = async (brandname) => {
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/stores/exists?brandname=${brandname}`);
  try {
    const res = await req.json();
    return res;
  } catch (error) {
    console.log("error", error);
  }
}

export {
  getUserData,
  getGenderData,
  putUpdateUser,
  postChangePassword,
  getSellerOwnShops,
  postCreateNewStore,
  getOwnShopComments,
  getOwnProductComments,
  getTempToken,
  updateStore,
  getWeekdays,
  postChangeEmail,
  postConfirmCode,
  postChangePhone,
  postPhoneConfirmCode,
  checkBrandname
};
