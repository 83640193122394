import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import StarRatingComponent from "react-star-rating-component";
import { commentAboutStore, addToWishlist } from "../../actions/index";
import Modal from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { fetchReportTypes, postReportTypes } from "../../fetch/filterApi";

class DetailsWithPrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      reportTypes: [],
      optionSelected: null,
      disabled: false,
      errorMessage: false,
      reportComment: "",
    };
  }

  componentWillMount() {
    fetchReportTypes().then((res) => {
      this.setState({ reportTypes: res });
    });
  }

  gotoStore = async (e, brandname, storeId) => {
    e.preventDefault();

    await this.props.commentAboutStore(storeId);
    this.props.history.push(`${process.env.PUBLIC_URL}/store/${storeId}`);
  };

  onOpenModal = () => {
    this.setState({ open: true });
    console.log(this.state.reportTypes);
    // console.log(this.state.optionSelected);
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      optionSelected: null,
      reportComment: "",
      errorMessage: false,
      disabled: false,
    });
  };

  handleOption = async (id) => {
    this.setState({ optionSelected: id });
    console.log(id);
  };

  handleComment = (event) => {
    this.setState({ reportComment: event.target.value, errorMessage: true });
    if (event.target.value.length >= 10) {
      this.setState({ disabled: true, errorMessage: false });
    } else {
      this.setState({ disabled: false, errorMessage: true });
    }
    // console.log(event.target.value);
  };

  sendReport = () => {
    const { product } = this.props;
    const data = {
      productId: product.id,
      reportTypeId: this.state.optionSelected,
      description: this.state.reportComment,
    };
    postReportTypes(data).then((res) => {
      console.log(res);
      toast.success(`${this.props.translate("reportNotif")}`);
      // this.props.locale == "uz" ? toast.success(`${translate("reportNotif")}`) : toast.success(`${translate("reportNotif")}`);
      this.onCloseModal();
    });
  };

  render() {
    const { product, symbol, translate, locale } = this.props;
    const { store, storeId } = product;
    const { USD_UZS } = this.props.rate;
    const { open } = this.state;

    return (
      <div className="col-lg-6 rtl-text">
        <ToastContainer />
        <div className="product-right">
          <h2> {locale === "en" ? product.name_uz : product.name_ru} </h2>
          <div style={{ fontSize: "24px" }}>
            <StarRatingComponent
              name="store_rate"
              starCount={5}
              value={Number(product.rate)}
            />
          </div>

          <h3>
            <NumberFormat
              value={symbol === "$" ? product.price / USD_UZS : product.price}
              thousandSeparator=" "
              decimalScale={2}
              prefix={symbol === "$" ? "$" : null}
              suffix={symbol === "$" ? null : " UZS"}
              displayType="text"
            />
          </h3>

          <div className="border-product">
            <h6 className="product-title">
              {translate("Feature_and_descriptions")} :
            </h6>
            <p>
              {locale === "en"
                ? product.description_uz
                : product.description_ru}
            </p>
          </div>

          {product.store ? (
            <div className="border-product">
              <p>
                <span className="product-title">{translate("Made_in")} : </span>{" "}
                <span
                  onClick={(e) => this.gotoStore(e, store.brandname, storeId)}
                  style={{ cursor: "pointer" }}
                >
                  {product.store.brandname}
                </span>
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="border-product">
            <h6 className="product-title">{translate("Share")} :</h6>
            <div className="product-icon">
              <ul className="product-social">
                <li>
                  <FacebookShareButton
                    url={`https://mebzone.uz/right-sidebar/product/${
                      product.id
                    }`}
                  >
                    <FacebookIcon size="35" round="true" />
                  </FacebookShareButton>
                </li>

                <li>
                  <TelegramShareButton
                    url={`https://mebzone.uz/right-sidebar/product/${
                      product.id
                    }`}
                  >
                    <TelegramIcon size="35" round="true" />
                  </TelegramShareButton>
                </li>
                {/* <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i className="fa fa-instagram" />
                  </a>
                </li> */}
              </ul>
              <button
                className="wishlist-btn"
                onClick={() => this.props.addToWishlist(product, locale)}
              >
                <i className="fa fa-heart" />
                <span className="title-font">
                  {translate("Adding_to_wishlist")}
                </span>
              </button>
            </div>
          </div>

          <div className="product-buttons">
            <a
              className="btn btn-solid mr-2"
              onClick={(e) => this.gotoStore(e, store.brandname, storeId)}
            >
              {translate("Seller")}
            </a>
            {store ? (
              store.phone ? (
                <a
                  className="btn btn-solid mr-2"
                  href={"tel:" + `${store.phone}`}
                >
                  <i className="fa fa-phone" aria-hidden="true" />
                </a>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <a
              className="btn btn-solid ml-0 mt-2 btnResponsive"
              onClick={this.onOpenModal}
            >
              {translate("complain")}
            </a>
            {/* <button
              className="btn"
              style={{ color: "red", fontSize: "18px" }}
              onClick={this.onOpenModal}
            >
              <i className="fa fa-flag" style={{ color: "red" }} />
              {translate("complain")}
            </button> */}
          </div>
        </div>
        <Modal open={open} onClose={this.onCloseModal} center>
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col py-3">
                    <div style={{ textAlign: "center" }}>
                      <svg
                        style={{
                          fill: "#009F73",
                          width: "80px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
                      </svg>
                    </div>
                    <h4 style={{ margin: "0 30px" }}>
                      {translate("warrningSpam")}
                    </h4>
                    {this.state.reportTypes.length
                      ? this.state.reportTypes.map((item, index) => (
                          <div
                            style={{ cursor: "pointer" }}
                            className="custom-control custom-checkbox collection-filter-checkbox"
                          >
                            <input
                              type="checkbox"
                              checked={this.state.optionSelected == item.id}
                              onChange={() => this.handleOption(item.id)}
                              value={item.id}
                              id={item.id}
                              className="custom-control-input reportCheck"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              className="custom-control-label"
                              htmlFor={item.id}
                            >
                              {this.props.locale === "en"
                                ? item.name_uz
                                : item.name_ru}
                            </label>
                          </div>
                        ))
                      : ""}

                    {this.state.optionSelected !== null ? (
                      <div className="form-group">
                        <label htmlFor="description">
                          {translate("description")}
                        </label>
                        <textarea
                          cols="30"
                          rows="5"
                          value={this.state.reportComment}
                          onChange={this.handleComment}
                          className="form-control"
                          id="description"
                        />
                        {/* <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required=""
                      /> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.errorMessage ? (
                      <p> {translate("requiredMess")}</p>
                    ) : (
                      ""
                    )}
                    <button
                      className="btnWarning"
                      disabled={this.state.disabled ? false : true}
                      onClick={this.sendReport}
                    >
                      {translate("sendReport")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rate: state.data.rate,
  symbol: state.data.symbol,
  locale: state.Intl.locale,
});

export default withRouter(
  connect(
    mapStateToProps,
    { commentAboutStore, addToWishlist }
  )(withTranslate(DetailsWithPrice))
);
