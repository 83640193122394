import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import dymmyLogo from "../../assets/images/Vector.svg";
import { postLogoImage } from "../user/Auth";
import { updateStore, getWeekdays, checkBrandname } from "./Users";
import { getStore } from "../../fetch/filterApi";
import NumberFormat from "react-number-format";

export class UpdateStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      storeId: null,
      regionOptions: [],
      selectedRegion: null,
      regionId: null,
      mainCategoryOptions: [],
      selectedMainCategoryOptions: null,
      mainCategories: [],
      address: "",
      phone: "",
      phone2: "",
      phone3: "",
      telegram: "",
      oldBrandname: "",
      brandname: "",
      aboutUs_uz: "",
      aboutUs_ru: "",
      startTime: "",
      finishTime: "",
      imageId: null,
      file: "",
      logoImg: "",
      fileTypes: ["jpg", "jpeg", "png"],
      imageChanged: false,
      errorImg: false,
      weekdayOptions: new Map(),
      selectedDayOptions: [],
      workingHours: new Map(),
      timeOptions: [
        { value: "07:00", label: "07:00" },
        { value: "08:00", label: "08:00" },
        { value: "09:00", label: "09:00" },
        { value: "10:00", label: "10:00" },
        { value: "11:00", label: "11:00" },
        { value: "12:00", label: "12:00" },
        { value: "13:00", label: "13:00" },
        { value: "14:00", label: "14:00" },
        { value: "15:00", label: "15:00" },
        { value: "16:00", label: "16:00" },
        { value: "17:00", label: "17:00" },
        { value: "18:00", label: "18:00" },
        { value: "19:00", label: "19:00" },
        { value: "20:00", label: "20:00" },
        { value: "21:00", label: "21:00" },
        { value: "22:00", label: "22:00" },
        { value: "23:00", label: "23:00" },
      ],
      disableBtn: false,
      brandnameExist: false,
    };
    this.handleInputs = this.handleInputs.bind(this);
  }

  componentWillMount() {
    const { storeId } = this.props.match.params;
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
      validators: {
        tg: {
          // name the rule
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /@$/i) &&
              params.indexOf(val) === -1
            );
          },
          // messageReplace: (message, params) =>
          //   message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    });

    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    const token = userObj.token;

    const { mainCategories, regions } = this.props;
    let mainCategoryOptions = [];
    let regionOptions = [];

    mainCategories.map((m) => {
      mainCategoryOptions.push({
        value: m.id,
        label: this.props.locale === "en" ? m.name_uz : m.name_ru,
      });
    });
    regions.map((r) => {
      regionOptions.push({
        value: r.id,
        label: this.props.locale === "en" ? r.name_uz : r.name_ru,
      });
    });
    getWeekdays().then((res) => {
      res.map((d) => {
        this.state.weekdayOptions.set(d.id, {
          value: d.id,
          label: this.props.locale === "en" ? d.name_uz : d.name_ru,
        });
      });
    });

    this.setState({ mainCategoryOptions, regionOptions, token, storeId });
    getStore(storeId).then((store) => {
      const { mainCategories } = store;
      if (mainCategories.length > 0) {
        const chosens = mainCategories.map((el) => ({
          label: this.props.locale === "en" ? el.name_uz : el.name_ru,
          value: el.id,
        }));
        chosens.map((el) => {
          this.state.mainCategories.push(el.value);
        });
        this.setState({
          selectedMainCategoryOptions: chosens,
        });
      }

      const { workingTimes } = store;

      if (workingTimes.length > 0) {
        workingTimes.map((el) => {
          this.state.weekdayOptions.set(el.weekdayId, {
            ...this.state.weekdayOptions.get(el.weekdayId),
            id: el.id,
          });
          this.state.selectedDayOptions.push({
            label:
              this.props.locale === "en"
                ? el.weekday.name_uz
                : el.weekday.name_ru,
            value: el.weekdayId,
            id: el.id,
          });
          this.state.workingHours.set(el.weekdayId, {
            weekdayId: el.weekdayId,
            startTime: el.startTime,
            finishTime: el.finishTime,
            storeId: el.storeId,
            id: el.id,
          });
        });
      }

      this.setState({
        selectedRegion: store.region
          ? {
              label:
                this.props.locale === "en"
                  ? store.region.name_uz
                  : store.region.name_ru,
              value: store.regionId,
            }
          : "",
        regionId: store.regionId,
        address: store.address || "",
        phone: store.phone || "",
        phone2: store.phone2 || "",
        phone3: store.phone3 || "",
        telegram: store.telegram || "",
        brandname: store.brandname || "",
        oldBrandname: store.brandname || "",
        aboutUs_uz: store.aboutUs_uz || "",
        aboutUs_ru: store.aboutUs_ru || "",
        imageId: store.imageId || null,
        logoImg: store.image ? store.image.imageUrl : dymmyLogo,
        file: store.image ? "there is an image" : "",
      });
    });
  }

  componentDidMount() {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  handleSingleSelect = (selectedRegion) => {
    this.setState({ selectedRegion });
    if (selectedRegion) {
      this.state.regionId = selectedRegion.value;
    }
  };

  handleMultiSelect = (selectedMainCategoryOptions) => {
    this.setState({ selectedMainCategoryOptions });
    this.state.mainCategories = [];
    selectedMainCategoryOptions.map((el) => {
      this.state.mainCategories.push(el.value);
    });
  };

  handleWeekdays = (selectedDayOptions, removed) => {
    this.validator.hideMessages();
    this.forceUpdate();
    if (removed.removedValue) {
      this.state.workingHours.delete(removed.removedValue.value);
    }
    if (removed.removedValues) {
      this.state.workingHours.clear();
    }
    if (selectedDayOptions) {
      this.setState({ selectedDayOptions });
    }
  };

  handleStartTime = (e, weekdayId, id) => {
    const { workingHours } = this.state;
    if (e) {
      if (id) {
        workingHours.set(weekdayId, {
          ...this.state.workingHours.get(weekdayId),
          weekdayId,
          startTime: e.label,
          storeId: this.state.storeId,
          id,
        });
      } else {
        workingHours.set(weekdayId, {
          ...this.state.workingHours.get(weekdayId),
          weekdayId,
          startTime: e.label,
          storeId: this.state.storeId,
        });
      }
      this.setState({ workingHours });
    } else {
      workingHours.set(weekdayId, {
        ...this.state.workingHours.get(weekdayId),
        startTime: "",
      });
      this.setState({ workingHours });
    }
  };

  handleEndTime = (e, weekdayId, id) => {
    const { workingHours } = this.state;
    if (e) {
      if (id) {
        workingHours.set(weekdayId, {
          ...this.state.workingHours.get(weekdayId),
          weekdayId,
          finishTime: e.label,
          storeId: this.state.storeId,
          id,
        });
      } else {
        workingHours.set(weekdayId, {
          ...this.state.workingHours.get(weekdayId),
          weekdayId,
          finishTime: e.label,
          storeId: this.state.storeId,
        });
      }
      this.setState({ workingHours });
    } else {
      workingHours.set(weekdayId, {
        ...this.state.workingHours.get(weekdayId),
        finishTime: "",
      });
      this.setState({ workingHours });
    }
  };

  handleInputs(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleBrandname = (e) => {
    let brandname = e.target.value;
    if (brandname === this.state.oldBrandname) {
      this.setState({ brandname });
    } else {
      checkBrandname(brandname).then((res) => {
        this.setState({ brandname });
        if (res.isExisting) {
          this.setState({ brandnameExist: true });
        } else {
          this.setState({ brandnameExist: false });
        }
      });
    }
  };

  handleLogo(e) {
    e.preventDefault();
    this.setState({
      file: "",
      imageId: null,
      errorImg: false,
    });
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      var extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      var isSuccess = this.state.fileTypes.indexOf(extension) > -1;
    }
    if (isSuccess) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          logoImg: reader.result,
          imageChanged: true,
        });
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      console.log("error img");
      this.setState({ errorImg: true });
    }
  }

  updateStoreBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.state.brandnameExist === false) {
      this.setState({ disableBtn: true });
      const {
        regionId,
        address,
        phone,
        phone2,
        phone3,
        brandname,
        mainCategories,
        aboutUs_uz,
        aboutUs_ru,
        telegram,
      } = this.state;
      const cloneData = {
        regionId,
        address,
        phone,
        phone2,
        phone3,
        brandname,
        mainCategories,
        aboutUs_uz,
        aboutUs_ru,
        telegram,
        workingHours: [...this.state.workingHours.values()],
      };
      // console.log(cloneData);
      // const data = _.pickBy(cloneData, function(value, key) {
      //   return !(value === "");
      // });
      // console.log(data);
      if (this.state.imageChanged) {
        postLogoImage(this.state.file).then(async (res) => {
          if (res.status == 201) {
            const result = await res
              .clone()
              .json()
              .then((json) => {
                const imageId = json.id;
                updateStore(
                  this.state.token,
                  { ...cloneData, imageId },
                  this.state.storeId
                ).then((res) => {
                  if (res.status === 200) {
                    console.log("updated Store");
                    setTimeout(
                      () => this.setState({ disableBtn: false }),
                      2000
                    );
                  } else {
                    console.log("smth wrong with update store with new pic id");
                  }
                });
              });
          }
        });
      } else {
        const { imageId } = this.state;
        updateStore(
          this.state.token,
          { ...cloneData, imageId },
          this.state.storeId
        ).then((res) => {
          if (res.status === 200) {
            console.log("updated Store");
            setTimeout(() => this.setState({ disableBtn: false }), 2000);
          } else {
            console.log("smth wrong with update store with old pic id");
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { translate } = this.props;
    const { workingHours } = this.state;
    this.validator.purgeFields();
    return (
      <div className="container createStore-container">
        <h3 className="profile-sidebar-title">{translate("Update_store")}</h3>
        <Link
          to={`${process.env.PUBLIC_URL}/user-profile`}
          style={{ color: "#00a075" }}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
          {translate("Back")}
        </Link>
        <form className="update-store-form">
          <div>
            <div>
              <label>{translate("Select_region")} :</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                options={this.state.regionOptions}
                placeholder={translate("Select_region")}
                onChange={this.handleSingleSelect}
                value={this.state.selectedRegion}
                isClearable="false"
                isSearchable="true"
                onBlur={() => this.validator.showMessageFor("selectedRegion")}
              />
              {this.validator.message(
                "selectedRegion",
                this.state.selectedRegion,
                "required"
              )}
            </div>
            <div>
              <label>{translate("Address")} :</label>
              <input
                className="common-inputs"
                type="text"
                placeholder={translate("Enter_address")}
                name="address"
                value={this.state.address}
                onChange={this.handleInputs}
                onBlur={() => this.validator.showMessageFor("address")}
              />
              {this.validator.message(
                "address",
                this.state.address,
                "required"
              )}
            </div>
            <div>
              <label>{translate("Phone_number")} 1 :</label>
              <NumberFormat
                className="common-inputs"
                value={this.state.phone}
                displayType={"input"}
                placeholder="+998"
                format="+998 (##) ###-##-##"
                mask="_"
                onValueChange={(values) => {
                  this.setState({
                    phone: values.value,
                  });
                }}
                onBlur={() => this.validator.showMessageFor("phone")}
                renderText={(value, props) => <div {...props}>{value}</div>}
              />
              {this.validator.message("phone", this.state.phone, "required")}
            </div>
            <div>
              <label>{translate("Phone_number")} 2 :</label>
              <NumberFormat
                className="common-inputs"
                value={this.state.phone2}
                displayType={"input"}
                placeholder="+998"
                format="+998 (##) ###-##-##"
                mask="_"
                onValueChange={(values) => {
                  this.setState({
                    phone2: values.value,
                  });
                }}
                // onBlur={() => this.validator.showMessageFor("phone2")}
                renderText={(value, props) => <div {...props}>{value}</div>}
              />
              {/* {this.validator.message("phone2", this.state.phone2, "required")} */}
            </div>
            <div>
              <label>{translate("Phone_number")} 3 :</label>
              <NumberFormat
                className="common-inputs"
                value={this.state.phone3}
                displayType={"input"}
                placeholder="+998"
                format="+998 (##) ###-##-##"
                mask="_"
                onValueChange={(values) => {
                  this.setState({
                    phone3: values.value,
                  });
                }}
                // onBlur={() => this.validator.showMessageFor("phone3")}
                renderText={(value, props) => <div {...props}>{value}</div>}
              />
              {/* {this.validator.message("phone3", this.state.phone3, "required")} */}
            </div>
            <div>
              <label>{translate("telegram")}:</label>
              <input
                className="common-inputs"
                type="text"
                placeholder=""
                name="telegram"
                value={this.state.telegram}
                onChange={this.handleInputs}
                onBlur={() => this.validator.showMessageFor("telegram")}
              />
              {this.validator.message(
                "telegram",
                this.state.telegram,
                "required"
              )}
            </div>

            <div>
              <label>{translate("Company_name")} :</label>
              <input
                className="common-inputs"
                type="text"
                placeholder={translate("Enter_company_name")}
                name="brandname"
                value={this.state.brandname}
                onChange={this.handleBrandname}
                onBlur={() => this.validator.showMessageFor("brandname")}
              />
              {this.validator.message(
                "brandname",
                this.state.brandname,
                "required"
              )}
              {this.state.brandnameExist && (
                <p style={{ marginTop: "10px", color: "red" }}>
                  {translate("This_brandname_is_already_exist")}
                </p>
              )}
            </div>
            <div>
              <label>{translate("Product_type_you_sell")} :</label>
              <Select
                isMulti
                options={this.state.mainCategoryOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={translate("Choose_product_type")}
                value={this.state.selectedMainCategoryOptions}
                onChange={this.handleMultiSelect}
                onBlur={() => this.validator.showMessageFor("mainCategories")}
              />
              {this.validator.message(
                "mainCategories",
                this.state.mainCategories,
                "required"
              )}
            </div>
            <div>
              <label>
                {translate("About_Company")} ({translate("In_uzbek")})
              </label>
              <textarea
                name="aboutUs_uz"
                value={this.state.aboutUs_uz}
                rows="8"
                cols="50"
                onChange={this.handleInputs}
                onBlur={() => this.validator.showMessageFor("aboutUs_uz")}
              />
              {this.validator.message(
                "aboutUs_uz",
                this.state.aboutUs_uz,
                "required"
              )}
            </div>
            <div>
              <label>
                {translate("About_Company")} ({translate("In_russian")})
              </label>
              <textarea
                name="aboutUs_ru"
                value={this.state.aboutUs_ru}
                rows="8"
                cols="50"
                onChange={this.handleInputs}
                onBlur={() => this.validator.showMessageFor("aboutUs_ruz")}
              />
              {this.validator.message(
                "aboutUs_ru",
                this.state.aboutUs_ru,
                "required"
              )}
            </div>
          </div>
          <div>
            <div>
              <label>{translate("Work_schedule")} :</label>
              <Select
                isMulti
                options={[...this.state.weekdayOptions.values()]}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={translate("Choose_days")}
                value={this.state.selectedDayOptions}
                onChange={this.handleWeekdays}
              />

              {this.state.selectedDayOptions.length > 0 && (
                <div>
                  {this.state.selectedDayOptions.map((day, index) => {
                    return (
                      <div className="start-end-loop" key={index}>
                        <p>{day.label}</p>
                        <div className="start-end-work-container">
                          <div>
                            <label>{translate("Start_time")}</label>
                            <Select
                              options={this.state.timeOptions}
                              placeholder="_ : _"
                              onChange={(e) =>
                                this.handleStartTime(e, day.value, day.id)
                              }
                              value={
                                workingHours.get(day.value)
                                  ? {
                                      label: workingHours.get(day.value)
                                        .startTime,
                                      value: workingHours.get(day.value)
                                        .startTime,
                                    }
                                  : ""
                              }
                              isClearable
                              onBlur={() =>
                                this.validator.showMessageFor(
                                  "startTime" + day.value
                                )
                              }
                            />
                            {this.validator.message(
                              "startTime" + day.value,
                              workingHours.get(day.value) &&
                                workingHours.get(day.value).startTime,
                              "required"
                            )}
                          </div>
                          <div>
                            <label>{translate("End_time")}</label>
                            <Select
                              options={this.state.timeOptions}
                              placeholder="_ : _"
                              onChange={(e) =>
                                this.handleEndTime(e, day.value, day.id)
                              }
                              value={
                                workingHours.get(day.value)
                                  ? {
                                      label: workingHours.get(day.value)
                                        .finishTime,
                                      value: workingHours.get(day.value)
                                        .finishTime,
                                    }
                                  : ""
                              }
                              isClearable
                              onBlur={() =>
                                this.validator.showMessageFor(
                                  "finishTime" + day.value
                                )
                              }
                            />
                            {this.validator.message(
                              "finishTime" + day.value,
                              workingHours.get(day.value) &&
                                workingHours.get(day.value).finishTime,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div style={{ marginTop: "10px" }}>
              <p>{translate("Upload_logo")} :</p>
              <label htmlFor="store-logo-img">
                <div className="img-uploader">
                  {this.state.logoImg ? (
                    <img
                      src={this.state.logoImg}
                      style={{
                        height: "143px",
                        width: "123px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img src={dymmyLogo} />
                  )}
                </div>
              </label>
              <input
                style={{ display: "none" }}
                id="store-logo-img"
                type="file"
                onChange={(e) => this.handleLogo(e)}
                onBlur={() => this.validator.showMessageFor("imageFile")}
              />
              {this.validator.message("imageFile", this.state.file, "required")}
              {this.state.errorImg && (
                <p style={{ color: "red" }}>Error image</p>
              )}
            </div>
            <button
              className="create-btn-store"
              disabled={this.state.disableBtn}
              style={{
                cursor: this.state.disableBtn ? "not-allowed" : "",
                backgroundColor: this.state.disableBtn ? "#cccccc" : "",
                border: this.state.disableBtn ? "#cccccc" : "",
                marginBottom: "15px",
              }}
              onClick={(e) => this.updateStoreBtn(e)}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  color={"#fff"}
                  loading={this.state.disableBtn}
                  size={20}
                />{" "}
                <span style={{ marginLeft: "8px" }}>
                  {" "}
                  {translate("Update")}
                </span>
              </span>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainCategories: state.mainCategories.mainCategories,
  regions: state.user.regions,
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(UpdateStore));
