const sendCode = async (data) => {
  //   console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/users/phone`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
    },
    body: JSON.stringify(data),
  });
  try {
    const res = await req;
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

const confirmCode = async (data) => {
  //   console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/users/code`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
    },
    body: JSON.stringify(data),
  });
  try {
    const res = await req;
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

const registerUser = async (data) => {
  //   console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/users`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
    },
    body: JSON.stringify(data),
  });
  try {
    const res = await req;
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

const forgotPassword = async (data) => {
  //   console.log(data);
  const req = await fetch(
    `${process.env.REACT_APP_MEBZONE_API}/users/forgotPassword`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json; charset= UTF-8",
      },
      body: JSON.stringify(data),
    }
  );
  try {
    const res = await req;
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

const login = async (data) => {
  console.log(data);
  const req = await fetch(`${process.env.REACT_APP_MEBZONE_API}/auth/login`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset= UTF-8",
    },
    body: JSON.stringify(data),
  });
  try {
    const res = await req;
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

export { sendCode, confirmCode, registerUser, forgotPassword, login };
