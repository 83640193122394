import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslate } from "react-redux-multilingual";
import { Link } from "react-router-dom";
import "../../common/index.scss";
import Slider from "react-slick";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  filterMainCategory,
  getVisibleproducts,
  helpOnlyMainCategory,
} from "../../../actions";

// Import custom components
import HeaderSix from "../../common/headers/header-six";
import SpecialProducts from "./mebzone-special-products";
import FooterFive from "../../common/footers/footer-five";
import BlogSection from "../common/m-blogSection";
import MebAboutUs from "../../pages/meb-about-us";
import Categories from "./categories";

class Mebzone extends Component {
  componentDidMount() {
    const fullyLogout = new URLSearchParams(this.props.location.search).get(
      "fullyLogout"
    );
    console.log("fullyLogout:", fullyLogout);
    if (fullyLogout) {
      localStorage.removeItem("mebzone-user");
    }
  }

  handleMainCategoryId = (e, mainCategoryId) => {
    e.preventDefault();
    console.log(mainCategoryId);
    this.props.filterMainCategory(mainCategoryId);
    this.props.helpOnlyMainCategory({ mainCategoryId });
    this.props.getVisibleproducts(this.props.filters);
    this.props.history.push(
      `${process.env.PUBLIC_URL}/left-sidebar/collection`
    );
  };

  render() {
    const { translate, locale } = this.props;
    const { mainCategories } = this.props;
    return (
      <div>
        <HeaderSix logoName={"logo/mebzone.svg"} />
        <Helmet>
          <title>Mebzone</title>
          <meta name="description" content="Mebzone project" />
        </Helmet>
        <section className="p-0 small-slider">
          <Slider className="slide-1 home-slider">
            {mainCategories.map((mainCategory, index) => {
              return (
                <div key={index}>
                  <div
                    className="home home12 text-left"
                    style={
                      mainCategory.image
                        ? {
                            backgroundImage: `url(${
                              mainCategory.image.imageUrl
                            })`,
                          }
                        : { backgroundImage: "" }
                    }
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <div className="slider-contain">
                            <div>
                              {/* <h4
                                dangerouslySetInnerHTML={{
                                  __html: mainCategory.description_ru.replace(
                                    mainCategory.name_ru.toLowerCase(),
                                    `<span> ${mainCategory.name_ru.toLowerCase()} </span><br/>`
                                  ),
                                }}
                              /> */}
                              <h4>
                                {locale == "en"
                                  ? mainCategory.description_uz
                                  : mainCategory.description_ru}
                              </h4>

                              <button
                                className="btn btn-solid"
                                onClick={(e) => {
                                  this.handleMainCategoryId(e, mainCategory.id);
                                }}
                              >
                                {locale == "en"
                                  ? mainCategory.button_uz
                                  : mainCategory.button_ru}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </section>
        {/*Special Products Start*/}
        {/* <SpecialProducts /> */}
        <Categories />
        {/*Special Products End*/}
        {/* Blog Section Section*/}
        <section className="blog blog-2 section-b-space ratio3_2">
          <div className="container ">
            <div className="row">
              <div className="col">
                <div className="title1">
                  <h2 className="title-inner1">{translate("Our_partners")}</h2>
                </div>
              </div>
            </div>
          </div>
          <BlogSection />
        </section>
        {/* Blog Section End*/}

        {/* <section id="sponsor">
          <div className="container">
            <div className="title1">
              <h2 className="title-inner1">Станьте нашим партнером</h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div>
                <p>
                  Мебельная фабрика - эксперт на рынке встроенной и корпусной
                  мебели. Вот уже более 12 лет мы производим для Вас
                  качественную мебель по индивидуальным проектам. За время
                  своего существования, мы зарекомендовали себя на рынке мебели
                  как творческий коллектив с множеством идей для Вашего
                  интерьера. Современный дизайн и новые технологии, которые мы
                  внедряем в наше производство, отличают нас от других мебельных
                  компаний.
                </p>
              </div>
              <div>
                <Link
                  to={`${process.env.PUBLIC_URL}/pages/partner`}
                  className="btn btn-solid"
                >
                  Cтать партнером
                </Link>
              </div>
            </div>
          </div>
        </section> */}
        <MebAboutUs />
        <FooterFive logoName={"logo/mebzone.svg"} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  mainCategories: state.mainCategories.mainCategories,
  filters: state.filters,
  symbol: state.data.symbol,
  locale: state.Intl.locale,
});

export default connect(
  mapStateToProps,
  { filterMainCategory, getVisibleproducts, helpOnlyMainCategory }
)(withRouter(withTranslate(Mebzone)));
