import React, { Component } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { withTranslate } from "react-redux-multilingual";
import SimpleReactValidator from "simple-react-validator";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

//custom files
import BackgroundContainer from "./components/BackgroundContainer";
import "./style/auth.scss";
import { confirmCode, sendCode } from "./api/auth";

export class ConfirmCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "+998" + localStorage.getItem("new-user-phone"),
      code: "",
      disableBtn: false,
      time: 60,
      key: 0,
      stopped: false,
      serverCodeErr: false,
      serverErr: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });

    if (!localStorage.getItem("new-user-phone")) {
      this.navigateBtn("login");
    }
  }

  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  navigateBtn = (path) => {
    this.props.history.push(`${process.env.PUBLIC_URL}/${path}`);
  };
  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  confirmCodeBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ disableBtn: true });
      const { phone, code } = this.state;
      confirmCode({ phone, code: Number(code) }).then((res) => {
        if (res.status === 202) {
          this.setState({ disableBtn: false });
          this.navigateBtn("register");
        }
        if (res.status === 401) {
          console.log("wrong code input");
          this.setState({ disableBtn: false, serverCodeErr: true });
          setTimeout(() => {
            this.setState({ serverCodeErr: false });
          }, 2000);
        } else {
          console.log(res);
          this.setState({ disableBtn: false, serverErr: true });
          setTimeout(() => {
            this.setState({ serverErr: false });
          }, 2000);
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  restartTimeBtn = (e) => {
    e.preventDefault();
    this.setState({ disableBtn: true });
    const { phone } = this.state;
    sendCode({ phone }).then((res) => {
      console.log(res.status);
      if (res.status) {
        this.setState({
          key: this.state.key + 1,
          stopped: false,
          disableBtn: false,
        });
      } else {
        console.log("smth problem with resend code");
        this.setState({ disableBtn: false });
      }
    });
  };

  render() {
    const { translate } = this.props;
    return (
      <BackgroundContainer>
        <form className="confirm-code-container">
          <div>
            <h3 className="form-title">{translate("Title_comfirm_code")}</h3>
            <label htmlFor="phone">{translate("Code_confirmation")}:</label>
            <input
              type="text"
              placeholder={translate("Confirm_code")}
              name="code"
              value={this.state.code}
              onChange={this.handleInputChange}
              onBlur={() => this.validator.showMessageFor("code")}
            />
            {this.validator.message("code", this.state.code, "required")}
            <div className="counter">
              <CountdownCircleTimer
                key={this.state.key}
                isPlaying
                size={120}
                duration={this.state.time}
                colors={[
                  ["#a8cf45", 0.33],
                  ["#F7B801", 0.33],
                  ["#A30000", 0.33],
                ]}
                onComplete={() => {
                  this.setState({ stopped: true });
                }}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </div>
          </div>
          <div className="confirm-code-btns">
            <div className="server-errors">
              {this.state.serverCodeErr && <p>{translate("wrong_code")}</p>}
              {this.state.serverErr && <p>{translate("Server_error")}</p>}
            </div>
            <button
              className="confirm-code-btn"
              onClick={(e) =>
                this.state.stopped
                  ? this.restartTimeBtn(e)
                  : this.confirmCodeBtn(e)
              }
              disabled={this.state.disableBtn}
              style={{
                cursor: this.state.disableBtn ? "not-allowed" : "",
              }}
            >
              {this.state.disableBtn ? (
                <ClipLoader color={"#fff"} loading={true} size={20} />
              ) : this.state.stopped ? (
                translate("Send_again")
              ) : (
                translate("Send")
              )}
            </button>
            <button onClick={() => this.navigateBtn("sendCode")}>
              {translate("Back")}
            </button>
          </div>
        </form>
      </BackgroundContainer>
    );
  }
}

export default withTranslate(ConfirmCode);
