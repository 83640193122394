import * as types from "../constants/ActionTypes";

const commentReducerDefaultState = {
  storeId: null,
};

const commentReducer = (state = commentReducerDefaultState, action) => {
  // console.log("Action Result");
  // console.log(action);
  switch (action.type) {
    case types.STORE_ID:
      return {
        storeId: action.data,
      };

    default:
      return state;
  }
};

export default commentReducer;
