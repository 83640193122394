import * as types from "../constants/ActionTypes";

const filtersReducerDefaultState = {
  // value: { min: 20, max: 30 },
  price: null,
  mainCategoryId: null,
  categoryId: null,
  storeId: null,
  regionId: null,
  isAvailable: null,
  isDeliverable: null,
  search: null,
  attributes: null,
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
  // console.log("Action Result");
  // console.log(action);
  switch (action.type) {
    case types.FILTER_PRICE:
      return {
        ...state,
        price: action.price,
        search: null,
      };
    case types.FILTER_MAINCATEGORY:
      return {
        mainCategoryId: action.mainCategoryId,
      };
    case types.FILTER_CATEGORY:
      return {
        categoryId: action.categoryId,
      };
    case types.FILTER_AVAILABLE:
      return {
        ...state,
        isAvailable: action.isAvailable.isAvailable,
        search: null,
      };
    case types.FILTER_DELIVERABLE:
      return {
        ...state,
        isDeliverable: action.isDeliverable.isDeliverable,
        search: null,
      };
    case types.FILTER_STORE:
      return {
        storeId: action.storeId.storeId,
        search: null,
      };
    case types.FILTER_REGION:
      return {
        ...state,
        regionId: action.regionId.regionId,
        search: null,
      };
    case types.FILTER_SEARCH:
      return {
        search: action.search,
      };

    case types.FILTER_ATTRIBUTES:
      return {
        ...state,
        search: null,
        attributes: action.attributes,
      };
    default:
      return state;
  }
};

export default filtersReducer;
