var module = {
  fn: {
    locale: "fn",
    messages: {
      reportNotif: "Ваше обращение направлено администрации Mebzone.uz",
      requiredMess: "Не может быть короче 10 символов",
      description: "Объясните, что не так",
      sendReport: "ОТПРАВИТЬ",
      complain: "Пожаловаться",
      warrningSpam: "На что хотите пожаловаться?",
      Categories: "Категории товаров",
      Our_partners: "Наши партнёры",
      Loadmore: "Eщё",
      Welcome_to_mebzone: "Добро пожаловать в Mebzone",
      Login: "Войти",
      Language: "Язык",
      Uzbek: "Узбекский",
      Russian: "Русский",
      Currency: "Валюта",
      Contacts: "Контакты",
      Be_partner: "Стать партнером",
      Address: "Адрес",
      Email: "Электронная почта",
      Back: "Назад",
      Wishlist: "Список желаний",
      Empty_wishlist: "Ваш список желаний пуст",
      Photo: "Изображение",
      Name_product: "Название товара",
      Price: "Ценa",
      isAvailable: "Наличие",
      Delete: "Удалить",
      Continue_shopping: "Продолжить покупки",
      Mebzone_footer_about:
        "Широкий ассортимент товаров высокого качества для Вашей зоны комфорта.",
      To_seller: "Продавцам",
      To_customers: "Покупателям",
      About_us: "О Нас",
      How_to_use_mebzone: "Как пользоваться Mebzone",
      How_to_leave_comment: "Как оставить полезный отзыв",
      How_to_create_website: "Как создать сайт на Mebzone",
      Security: "Политика конфиденциальности",
      Rules: "Пользовательское соглашение",
      About_mebzone: "О Mebzone",
      FAQ: "Справка и FAQ",
      Phones: "Телефоны",
      Work_schedule: "График работы",
      About_Company: "О компании",
      Comments_about_company: "Отзывы о компании",
      Put_comment: "Добавить отзыв",
      Total_seller_rating: "Общая оценка продавца",
      Comment_about_seller: "Отзыв о продавце",
      No_description_about_company:
        "Владелец магазина пока не добавил информацию в данный раздел...",
      Please: "Пожалуйста !",
      Please_login:
        "Чтобы оставить комментарий, войдите или зарегистрируйтесь как покупатель.",
      Not_yet_account: "У вас ещё нет аккаунта?",
      Please_sign_up: "Зарегистрироваться",
      Comment_added: "Ваш комментарий успешно добавлен",
      Comments: "Отзывы",
      Sorry_not_found_product:
        "Простите! Не удалось найти продукт, который вы искали !!!",
      Change_filters_please:
        "Пожалуйста, проверьте, не написали ли вы что-то с ошибками, или попробуйте выполнить поиск с другими словами.",
      Continue: "Продолжить",
      ProductLength: "Товары",
      Change_filter_to_get_more:
        "Попробуйте изменить фильтры, чтобы найти больше товаров",
      Regions: "Регионы",
      Sellers: "Продавцы",
      Delivery: "Доставка",
      Available: "Hаличие",
      Yes: "Да",
      No: "Нет",
      Search_seller: "Искать продавца",
      Select_region: "Выберите регион",
      Add_to_wishlist: "Добавить в список желаний",
      Quick_view: "Быстрый просмотр",
      In_detail: "Подробно",
      Feature_and_descriptions: "Характеристики и oписание",
      Made_in: "Производитель",
      Share: "Поделиться",
      Adding_to_wishlist: "Добавить в желаемые",
      Seller: "Продавец",
      Write_comment: "Написать отзыв",
      Total_product_rating: "Общая оценка продукта",
      Your_comment_about_product: "Отзыв о продукте",
      Why_you_liked:
        "Расскажите почему Вам понравился или не понравился данный продукт",
      Delivery_through_uzb: "Доставка по Узбекистану",
      In_a_sale: "Есть в наличие",
      Same_products: "Похожие товары",
      Filters: "Фильтры",
      Login_to_your_profile: "Вход в личный кабинет",
      Enter_email: "Введите электронную почту:",
      Enter_password: "Введите пароль:",
      Password: "Пароль",
      Forgot_password: "Забыли пароль ?",
      Forgot_your_password: "Забыли свой пароль?",
      Send: "Отправить",
      Cancel: "Отмена",
      Registration: "Регистрация",
      Name: "Имя",
      Enter_name: "Введите имя",
      Surname: "Фамилия",
      Enter_surname: "Введите фамилию",
      Choose_type: "Выберите тип",
      Customer: "Покупатель",
      Company: "Компания",
      Next_step: "Следующий шаг",
      Confirmation_by_email: "Подтверждение электронной почты",
      Code_confirmation: "Код потверждение",
      Confirm_code: "Подтвердите код",
      Enter_address: "Введите адрес",
      Phone_number: "Телефон номер",
      Company_name: "Название компании",
      Enter_company_name: "Введите названию компании",
      Product_type_you_sell: "Тип товара вы продаете",
      Choose_product_type: "Выберите тип товарa",
      Upload_logo: "Загрузите логотип",
      To_complete: "Завершить",
      Please_enter_a_valid_email_address:
        "Введите правильный адрес электронной почты",
      Fill_in_the_field: "Заполните поле",
      must_be_8_digit:
        "Новый пароль должен состоять из не менее чем 8 символов",
      My_profile: "Мой профиль",
      Log_out: "Выйти",
      My_personal_info: "Мои личные данные",
      Edit_personal_info: "Изменить личные данные",
      My_stores: "Мои магазины",
      Change_password: "Изменить пароль",
      Create_internet_store: "Создать интернет магазин",
      Control_stores: "Управление магазинами",
      My_comments: "Мои отзывы",
      Gender: "Пол",
      Birthday: "Дата рождение",
      Not_given: "Не указано",
      Save: "Сохранить",
      Enter_old_password: "Введите старый пароль",
      Enter_new_password: "Введите новый пароль",
      Repeat_new_password: "Повторите новый пароль",
      Wrong_format: "Неправильный формат",
      Create: "Создать",
      Region: "Регион",
      you_are_not_activated: "Вы не активированы",
      Update_store: "Обновить магазин",
      Update: "Обновить",
      In_uzbek: "На узбекском",
      In_russian: "На русском",
      No_item_left: "Товаров в данной категории больше нет",
      Your_default_store_is_blocked: "Ваш основной магазин заблокирован",
      Start_time: "Начало работы",
      End_time: "Конец работы",
      you_are_registered_as_customer: "Вы успешно зарегистрировались.",
      you_are_registered_as_seller:
        "Вы успешно зарегистрировались. Мы предоставим вам логин и пароль для вашей панели после анализа ваших данных.",
      wrong_code: "Hеверный код",
      Code_sended: "Код отправлен",
      first_step: "Первый шаг",
      second_step: "Второй шаг",
      third_step: "Третий шаг",
      new_password_send:
        "Пожалуйста, проверьте e-mail или свой телефон, недавно мы отправили новый пароль.",
      not_get_new_password: "Не получили email?",
      Close: "Закрыть",
      Profile: "Профиль",
      Search: "Поиск",
      Settings: "Hастройки",
      Wishlist_ico: "Избранное",
      Choose_days: "Выберите дни",
      Send_again: "Отправить код еще раз",
      Brands: "Бренды",
      Find_brand: "Найти бренд",
      Wrong_login_or_password: "Пароль или логин неверен",
      To_many_request: "Пожалуйста, повторите запрос чуть позже",
      Server_error: "Произошла непредвиденная ошибка",
      Title_comfirm_code: "Введите код",
      Enter_phone_or_email: "Введите свой телефон или e-mail",
      Phone_or_email: "Телефон или e-mail",
      Not_found_such_user: "Такого пользователя не существует",
      Please_check_your_phone_email:
        "Пожалуйста, проверьте правильность ввода номера телефона или e-mail",
      Phone_already_taken: "Номер телефона уже используется",
      Update_email: "Изменить e-mail",
      Update_phone: "Изменить телефон",
      Email_already_taken: "E-mail уже используется",
      Products: "Товары",
      This_brandname_is_already_exist: "Этот бренд уже используется.",
      telegram: "Телеграм",
      January: "Январь",
      February: "Февраль",
      March: "Март",
      April: "Апрель",
      May: "Май",
      June: "Июнь",
      July: "Июль",
      August: "Август",
      September: "Сентябрь",
      October: "Октябрь",
      November: "Ноябрь",
      December: "Декабрь",
      Find_product: "Найти продукт",
    },
  },
  en: {
    locale: "en_US",
    messages: {
      reportNotif:
        "Sizning murojaatingiz Mebzone.uz administrasiyasiga yetqazildi",
      requiredMess: "10 belgidan qisqa boʻlishi mumkin emas",
      description: "Tavsif",
      sendReport: "Xabarni jo'natish",
      complain: "Shikoyat qilish",
      warrningSpam: "Nima xato ekanligini izohlang?",
      Categories: "Toifalar",
      Our_partners: "Bizning hamkorlarimiz",
      Loadmore: "Ko'proq",
      Welcome_to_mebzone: "Mebzonega xush kelibsiz",
      Login: "Kirish",
      Language: "Til",
      Uzbek: "O'zbekcha",
      Russian: "Ruscha",
      Currency: "Pul birligi",
      Contacts: "Biz bilan aloqa",
      Be_partner: "Hamkor bo'ling",
      Address: "Manzil",
      Email: "E-mail",
      Back: "Ortga",
      Wishlist: "Istaklar ro'yhati",
      Empty_wishlist: "Sizning istaklar ro'yhatingiz bo'sh",
      Photo: "Rasm",
      Name_product: "Mahsulot nomi",
      Price: "Narx",
      isAvailable: "Bor-yo'qligi",
      Delete: "O'chirish",
      Continue_shopping: "Xaridni davom ettirish",
      Mebzone_footer_about:
        "Yuqori sifatli mahsulotlarning keng assortimenti Siz uchun.",
      To_seller: "Sotuvchilarga",
      To_customers: "Xaridorlarga",
      About_us: "Biz haqimizda",
      How_to_use_mebzone: "Mebzonedan foydalanish qoidalari",
      How_to_leave_comment: "Qanday qilib foydali sharh qoldirish",
      How_to_create_website: "Mebzoneda veb-sayt yaratish",
      Security: "Maxfiylik siyosati",
      Rules: "Foydalanish shartlari",
      About_mebzone: "Mebzone haqida",
      FAQ: "Yordam va tez-tez beriladigan savollar",
      Phones: "Telefonlar",
      Work_schedule: "Ish grafigi",
      About_Company: "Kompaniya haqida",
      Comments_about_company: "Kompaniya haqida sharhlar",
      Put_comment: "Sharh qoldirish",
      Total_seller_rating: "Sotuvchilarning umumiy reytingi",
      Comment_about_seller: "Sotuvchi to'g'risida sharhingiz",
      No_description_about_company:
        "Do'kon egasi hali bu bo'limga ma'lumot qo'shmagan...",
      Please: "Iltimos !",
      Please_login:
        "Fikr qoldirish uchun - profilingizga kiring yoki xaridor sifatida ro'yxatdan o'ting.",
      Not_yet_account: "Hali profilingiz yo'qmi?",
      Please_sign_up: "Ro'yxatdan o'ting",
      Comment_added: "Sizning sharhingiz muvaffaqiyatli qo'shildi",
      Comments: "Sharhlar",
      Sorry_not_found_product:
        "Kechirasiz! Siz qidirayotgan mahsulot topilmadi !!!",
      Change_filters_please:
        "Iltimos, xato yozilmaganligini tekshiring yoki boshqa so'zlar bilan qidirishga harakat qiling.",
      Continue: "Davom ettirish",
      ProductLength: "Mahsulotlar",
      Change_filter_to_get_more:
        "Boshqa mahsulotlarni topish uchun filtrlarni o'zgartiring",
      Regions: "Viloyatlar",
      Sellers: "Sotuvchilar",
      Delivery: "Yetkazib berish",
      Available: "Bor-yo'qligi",
      Yes: "Ha",
      No: "Yo'q",
      Search_seller: "Sotuvchini topish",
      Select_region: "Viloyat tanlang",
      Add_to_wishlist: "Istaklar ro'yxatiga qo'shish",
      Quick_view: "Tezkor ko'rish",
      In_detail: "Batafsil",
      Feature_and_descriptions: "Xususiyatlari va tavsifi",
      Made_in: "Ishlab chiqaruvchi",
      Share: "Ulashish",
      Adding_to_wishlist: "Istaklar ro'yhatiga qo'shish",
      Seller: "Sotuvchi",
      Write_comment: "Sharh qoldirish",
      Total_product_rating: "Mahsulotning umumiy reytingi",
      Your_comment_about_product: "Mahsulot haqidagi fikr-mulohazangiz",
      Why_you_liked:
        "Ayting-chi, nima uchun sizga ushbu mahsulot yoqdi yoki yoqmadi",
      Delivery_through_uzb: "O'zbekiston bo'ylab yetkazib berish",
      In_a_sale: "Sotuvda mavjud",
      Same_products: "O'xshash mahsulotlar",
      Filters: "Filterlar",
      Login_to_your_profile: "Shaxsiy profilingizga kiring",
      Enter_email: "Elektron manzil kiriting:",
      Enter_password: "Parolni kiriting",
      Password: "Parol",
      Forgot_password: "Parolni unutdingizmi ?",
      Forgot_your_password: "Parolingizni unutdingizmi?",
      Send: "Yuborish",
      Cancel: "Bekor qilish",
      Registration: "Ro'yxatdan o'tish",
      Name: "Ism",
      Enter_name: "Ismingizni kiriting",
      Surname: "Familiya",
      Enter_surname: "Familiyangizni kiriting",
      Choose_type: "Turini tanlang",
      Customer: "Xaridor",
      Company: "Kompaniya",
      Next_step: "Keyingi qadam",
      Confirmation_by_email: "Elektron pochta orqali tasdiqlash",
      Code_confirmation: "Tasdiqlash kodi",
      Confirm_code: "Kodni tasdiqlang",
      Enter_address: "Manzilni kiriting",
      Phone_number: "Telefon raqami",
      Company_name: "Kompaniya nomi",
      Enter_company_name: "Kompaniya nomini kiriting",
      Product_type_you_sell: "Siz sotayotgan mahsulot turi",
      Choose_product_type: "Mahsulot turini tanlang",
      Upload_logo: "Logotipni yuklang",
      To_complete: "Tugatish uchun",
      Please_enter_a_valid_email_address:
        "Iltimos elektron pochtani to'g'ri kiriting",
      Fill_in_the_field: "Formani to'ldiring",
      must_be_8_digit: "Yangi parol kamida 8 ta belgidan iborat bo'lishi kerak",
      My_profile: "Mening profilim",
      Log_out: "Chiqish",
      My_personal_info: "Mening shaxsiy ma'lumotlarim",
      Edit_personal_info: "Shaxsiy ma'lumotlarni o'zgartirish",
      My_stores: "Мening do'konlarim",
      Change_password: "Parolni o'zgartirish",
      Create_internet_store: "Onlayn do'kon yarating",
      Control_stores: "Do'kon boshqaruvi",
      My_comments: "Mening sharhlarim",
      Gender: "Jinsi",
      Birthday: "Tug'ilgan sana",
      Not_given: "Ko'rsatilmagan",
      Save: "Saqlash",
      Enter_old_password: "Eski parolingizni kiriting",
      Enter_new_password: "Yangi parolni kiriting",
      Repeat_new_password: "Yangi parolni takrorlang",
      Wrong_format: "Noto'g'ri format",
      Create: "Yaratish",
      Region: "Viloyat",
      you_are_not_activated: "Faollashtirilmagan",
      Update_store: "Do'konni tahrirlash",
      Update: "Tahrirlash",
      In_uzbek: "O'zbek tilida",
      In_russian: "Rus tilida",
      No_item_left: "Ushbu toifadagi mahsulotlar qolmadi",
      Your_default_store_is_blocked:
        "Sizning asosiy magaziningiz block qilingan",
      Start_time: "Ish boshlanish vaqti",
      End_time: "Ish tugash vaqti",
      you_are_registered_as_customer:
        "Siz muvaffaqiyatli ro'yxatdan o'tdingiz.",
      you_are_registered_as_seller:
        "Siz muvaffaqiyatli ro'yxatdan o'tdingiz. Ma'lumotlaringizni tahlil qilgandan so'ng, biz sizga magazin uchun login va parolni taqdim etamiz.",
      wrong_code: "Kod noto'g'ri",
      Code_sended: "Kod yuborildi",
      first_step: "Birinchi qadam",
      second_step: "Ikkinchi qadam",
      third_step: "Uchinchi qadam",
      new_password_send:
        "Iltimos, telefoningiz yoki elektron pochtangizni tekshiring, hozirgina yangi parol yuborildi.",
      not_get_new_password: "Xabar kelmadimi?",
      Close: "Yopish",
      Profile: "Profil",
      Search: "Izlash",
      Settings: "Sozlamalar",
      Wishlist_ico: "Istaklar",
      Choose_days: "Kunlarni tanlang",
      Send_again: "Kodni qayta yuborish",
      Brands: "Brandlar",
      Find_brand: "Brandni topish ",
      Wrong_login_or_password: "Parol yoki login noto'g'ri",
      To_many_request: "Iltimos, biroz vaqtdan keyin qayta urinib ko'ring",
      Server_error: "Serverda hatolik yuz berdi",
      Title_comfirm_code: "Kodni tasdiqlash",
      Enter_phone_or_email: "Telefon raqam yoki email kiriting",
      Phone_or_email: "Telefon raqam yoki email",
      Not_found_such_user: "Bunday foydalanuvchi topilmadi",
      Please_check_your_phone_email:
        "Iltimos, telefon raqamingiz yoki emailingiz to'g'ri kiritilganligini tekshiring",
      Phone_already_taken: "Telefon nomer allaqachon ishlatilgan",
      Update_email: "E-mailni o'zgartirish",
      Update_phone: "Telefon no'merni o'zgartirish",
      Email_already_taken: "E-mail allaqachon ishlatilgan",
      Products: "Mahsulotlar",
      This_brandname_is_already_exist: "Bu brend ishlatilgan.",
      telegram: "Тelegram",
      January: "Yanvar",
      February: "Fevral",
      March: "Mart",
      April: "Aprel",
      May: "May",
      June: "Iyun",
      July: "Iyul",
      August: "Avgust",
      September: "Sentabr",
      October: "Oktabr",
      November: "Noyabr",
      December: "Dekabr",
      Find_product: "Tovarni topish",
    },
  },
};

export default module;
