import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IntlActions } from "react-redux-multilingual";
import store from "../../../store";
import { withTranslate } from "react-redux-multilingual";

import "../style/auth.scss";

export class BackgroundContainer extends Component {
  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  render() {
    const { translate } = this.props;
    return (
      <div className="background-container">
        <div className="top-dark-container">
          <div className="link-to-home-container">
            <Link to={`${process.env.PUBLIC_URL}/`}>
              {translate("Welcome_to_mebzone")}
            </Link>
            <a href="tel:+998977000663" >+998 97 700 06 63</a>
          </div>
          <div className="change-lang-container">
            <p>
              <span
                className={this.props.locale == "en" ? "makeGreen" : ""}
                onClick={() => this.changeLanguage("en")}
              >
                {translate("Uzbek")}
              </span>{" "}
              /{" "}
              <span
                className={this.props.locale == "fn" ? "makeGreen" : ""}
                onClick={() => this.changeLanguage("fn")}
              >
                {translate("Russian")}
              </span>
            </p>
          </div>
        </div>
        <div className="white-container">{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(BackgroundContainer));
