import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import ClipLoader from "react-spinners/ClipLoader";
import SimpleReactValidator from "simple-react-validator";
import NumberFormat from "react-number-format";

//custom files
import BackgroundContainer from "./components/BackgroundContainer";
import "./style/auth.scss";
import { sendCode } from "./api/auth";

export class SendCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      disableBtn: false,
      serverErr: false,
      serverPhoneTakenErr: false,
      serverTooManyErr: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });
  }

  componentDidMount() {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  navigateBtn = (path) => {
    this.props.history.push(`${process.env.PUBLIC_URL}/${path}`);
  };

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  sendCodeBtn = (e) => {
    e.preventDefault();
    this.setState({ serverErr: false, serverPhoneTakenErr: false });
    if (this.validator.allValid()) {
      this.setState({ disableBtn: true });
      const { phone } = this.state;
      sendCode({ phone: "+998" + phone }).then((res) => {
        if (res.status === 200) {
          localStorage.setItem("new-user-phone", phone);
          this.setState({ disableBtn: false });
          this.navigateBtn("confirmCode");
        } else if (res.status === 409) {
          this.setState({ disableBtn: false, serverPhoneTakenErr: true });
        } else if (res.status === 429) {
          this.setState({ disableBtn: false, serverTooManyErr: true });
        } else {
          console.log("server error");
          this.setState({ disableBtn: false, serverErr: true });
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <BackgroundContainer>
        <form className="sendCode-container">
          <div>
            <h3 className="form-title">{translate("Registration")}</h3>
            <label htmlFor="phone">{translate("Phone_number")}:</label>
            <NumberFormat
              value={this.state.phone}
              displayType={"input"}
              placeholder={"+998"}
              format="+998 (##) ###-##-##"
              mask="_"
              onValueChange={(values) => {
                this.setState({
                  phone: values.value,
                  serverErr: false,
                  serverPhoneTakenErr: false,
                });
              }}
              onBlur={() => this.validator.showMessageFor("phone")}
              renderText={(value, props) => <div {...props}>{value}</div>}
            />
            {this.validator.message("phone", this.state.phone, "required")}
          </div>
          <div>
            <div className="server-errors">
              {this.state.serverErr && <p>{translate("Server_error")}</p>}
              {this.state.serverPhoneTakenErr && (
                <p>{translate("Phone_already_taken")}</p>
              )}
              {this.state.serverTooManyErr && (
                <p>{translate("To_many_request")}</p>
              )}
            </div>
            <button
              className="sendCode-btn"
              onClick={(e) => this.sendCodeBtn(e)}
              disabled={this.state.disableBtn}
              style={{
                cursor: this.state.disableBtn ? "not-allowed" : "",
              }}
            >
              {this.state.disableBtn ? (
                <ClipLoader color={"#fff"} loading={true} size={20} />
              ) : (
                translate("Send")
              )}
            </button>
            <button onClick={() => this.navigateBtn("login")}>
              {translate("Back")}
            </button>
          </div>
        </form>
      </BackgroundContainer>
    );
  }
}

export default withTranslate(SendCode);
