import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-responsive-modal";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";

import { postChangeEmail, postConfirmCode } from "./Users";
import { getUser } from "../../actions";

export class ChangeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      code: "",
      openModal: false,
      serverErrEmailTaken: false,
      serverErrToManyReq: false,
      serverErr: false,
      disableSaveBtn: false,
      modalServerErrEmailTaken: false,
      modalServerErrToManyReq: false,
      modalServerErrWrongCode: false,
      modalServerErr: false,
      disableSendBtn: false,
    };
    this.handleInputs = this.handleInputs.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });
  }

  componentDidMount() {
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    this.setState({ token: userObj.token });
  }

  handleInputs(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  openModal = (e) => {
    e.preventDefault(e);
    if (this.validator.fieldValid("email")) {
      this.setState({ disableSaveBtn: true });
      const data = {
        email: this.state.email,
      };
      postChangeEmail(this.state.token, data).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState({ openModal: true, disableSaveBtn: false });
        } else if (res.status === 409) {
          console.log("Email is already taken");
          this.setState({ serverErrEmailTaken: true });
          setTimeout(() => this.setState({ disableSaveBtn: false }), 2000);
        } else if (res.status === 429) {
          console.log("To many request");
          this.setState({ serverErrToManyReq: true });
          setTimeout(() => this.setState({ disableSaveBtn: false }), 2000);
        } else {
          console.log("server error");
          this.setState({ serverErr: true });
          setTimeout(() => this.setState({ disableSaveBtn: false }), 2000);
        }

        setTimeout(
          () =>
            this.setState({
              serverErrEmailTaken: false,
              serverErrToManyReq: false,
              serverErr: false,
            }),
          3000
        );
      });
    } else {
      this.validator.showMessageFor("email");
      this.forceUpdate();
    }
  };
  onCloseModal = (e) => {
    e.preventDefault();
    this.setState({ openModal: false, code: "" });
  };

  confirmCodeBtn = (e) => {
    e.preventDefault();
    if (
      this.validator.fieldValid("code") &&
      this.validator.fieldValid("email")
    ) {
      this.setState({ disableSendBtn: true });
      const data = {
        email: this.state.email,
        code: Number(this.state.code),
      };
      postConfirmCode(this.state.token, data).then(async (res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState({
            openModal: false,
            code: "",
            email: "",
            disableSendBtn: false,
          });
          await this.props.getUser(this.state.token);
          this.props.changePage(1);
        } else if (res.status === 429) {
          console.log("To many request");
          this.setState({ modalServerErrToManyReq: true });
          setTimeout(() => this.setState({ disableSendBtn: false }), 2000);
        } else if (res.status === 400) {
          console.log("wrong code");
          this.setState({ modalServerErrWrongCode: true });
          setTimeout(() => this.setState({ disableSendBtn: false }), 2000);
        } else if (res.status === 409) {
          console.log("email is taken");
          this.setState({ modalServerErrEmailTaken: true });
          setTimeout(() => this.setState({ disableSendBtn: false }), 2000);
        } else {
          console.log("server error");
          this.setState({ modalServerErr: true });
          setTimeout(() => this.setState({ disableSendBtn: false }), 2000);
        }
        setTimeout(
          () =>
            this.setState({
              modalServerErrEmailTaken: false,
              modalServerErrToManyReq: false,
              modalServerErrWrongCode: false,
              modalServerErr: false,
            }),
          3000
        );
      });
    } else {
      this.validator.showMessageFor("code");
      this.forceUpdate();
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="edit-profile-container">
        <h3 className="profile-sidebar-title">{translate("Update_email")}</h3>
        <form className="changePassword-container">
          <label htmlFor="">{translate("Enter_email")}</label>
          <input
            type="email"
            name="email"
            placeholder={translate("Email")}
            value={this.state.email}
            onChange={this.handleInputs}
            onBlur={() => this.validator.showMessageFor("email")}
          />
          {this.validator.message("email", this.state.email, "required|email")}
          {this.state.serverErrEmailTaken && (
            <p style={{ color: "red", marginTop: "5%", marginBottom: "0" }}>
              {translate("Email_already_taken")}
            </p>
          )}
          {this.state.serverErrToManyReq && (
            <p style={{ color: "red", marginTop: "5%", marginBottom: "0" }}>
              {translate("To_many_request")}
            </p>
          )}
          {this.state.serverErr && (
            <p style={{ color: "red", marginTop: "5%", marginBottom: "0" }}>
              {translate("Server_error")}
            </p>
          )}

          <button
            onClick={(e) => this.openModal(e)}
            disabled={this.state.disableSaveBtn}
            style={{
              cursor: this.state.disableSaveBtn ? "not-allowed" : "",
            }}
          >
            {this.state.disableSaveBtn ? (
              <ClipLoader color={"#fff"} loading={true} size={20} />
            ) : (
              translate("Save")
            )}
          </button>
        </form>
        <Modal open={this.state.openModal} onClose={this.onCloseModal} center>
          <h2
            style={{
              textTransform: "unset",
              fontSize: "22px",
              lineHeight: "22px",
              textAlign: "center",
            }}
          >
            {translate("Title_comfirm_code")}
          </h2>
          <form
            style={{
              display: "grid",
              gridTemplateColumns: "minmax(200px , 400px)",
              gridTemplateRows: "auto auto auto auto",
            }}
          >
            <label htmlFor="">{translate("Code_confirmation")}:</label>
            <input
              type="text"
              name="code"
              placeholder={translate("Confirm_code")}
              value={this.state.code}
              onChange={this.handleInputs}
              onBlur={() => this.validator.showMessageFor("code")}
            />
            {this.validator.message("code", this.state.code, "required")}

            {this.state.modalServerErrEmailTaken && (
              <p style={{ color: "red", marginTop: "5%", marginBottom: "0" }}>
                {translate("Email_already_taken")}
              </p>
            )}
            {this.state.modalServerErrToManyReq && (
              <p style={{ color: "red", marginTop: "5%", marginBottom: "0" }}>
                {translate("To_many_request")}
              </p>
            )}
            {this.state.modalServerErrWrongCode && (
              <p style={{ color: "red", marginTop: "5%", marginBottom: "0" }}>
                {translate("wrong_code")}
              </p>
            )}
            {this.state.modalServerErr && (
              <p style={{ color: "red", marginTop: "5%", marginBottom: "0" }}>
                {translate("Server_error")}
              </p>
            )}

            <button
              className="btn btn-primary"
              style={{
                borderRadius: "5px",
                background: "#00a075",
                marginTop: "20px",
                cursor: this.state.disableSendBtn ? "not-allowed" : "",
              }}
              disabled={this.state.disableSendBtn}
              onClick={(e) => this.confirmCodeBtn(e)}
            >
              {this.state.disableSendBtn ? (
                <ClipLoader color={"#fff"} loading={true} size={20} />
              ) : (
                translate("Send")
              )}
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  { getUser }
)(withRouter(withTranslate(ChangeEmail)));
