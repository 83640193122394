import * as types from "../constants/ActionTypes";

//bu filter qilish uchun
const mainCategoryReducerDefaultState = {
  mainCategories: [],
};

const mainCategoryReducer = (
  state = mainCategoryReducerDefaultState,
  action
) => {
  switch (action.type) {
    case types.MAIN_CATEGORIES:
      return {
        ...state,
        mainCategories: action.main_categories,
      };
    case types.NEXT_LENGTH:
      state.mainCategories.map((el) => {
        if (el.id === action.id) {
          el.nextLength = action.nextLength;
        }
      });
      return {
        ...state,
        mainCategories: [...state.mainCategories]
      };
    default:
      return state;
  }
};

export default mainCategoryReducer;
