import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import Modal from "react-responsive-modal";
import StarRatingComponent from "react-star-rating-component";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import "./shop.scss";
import FooterFive from "../common/footers/footer-five";
import Header from "../common/headers/header-six";
import ClipLoader from "react-spinners/ClipLoader";
import ProductListItem from "../collection/common/product-list-item";
import InfiniteScroll from "react-infinite-scroll-component";

import { addToWishlist } from "../../actions";

import {
  getStore,
  postStoreComment,
  getStoreComments,
  fetchFilteredProduct,
  fetchReportTypes,
  postReportTypes,
  reportStore,
} from "../../fetch/filterApi";
export class shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      reportTypes: [],
      optionSelected: null,
      disabled: false,
      errorMessage: false,
      reportComment: "",
      page: 3,
      pages: null,
      store: "",
      rate: null,
      comment: "",
      token: "",
      role: null,
      openReview: false,
      storeComments: [],
      disableBtn: false,
      registerModal: false,
      products: [],
    };
    this.handleInput = this.handleInput.bind(this);
    this.addStoreComment = this.addStoreComment.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    const { storeId } = this.props.match.params;
    // console.log(storeId);
    this.setState({
      role: userObj ? userObj.roles[0].id : "",
      token: userObj ? userObj.token : "",
      storeId,
    });

    fetchReportTypes().then((res) => {
      this.setState({ reportTypes: res });
    });
    getStore(storeId).then((res) => {
      this.setState({ store: res });
    });
    getStoreComments(storeId, 1).then(async (res) => {
      const totalLength = res.headers.get("totalLength");
      const pages = Math.ceil(totalLength / 3);
      this.setState({ pages });
      const commentList = await res
        .clone()
        .json()
        .then((clist) => {
          this.setState({ storeComments: clist });
        });
    });
    fetchFilteredProduct({ storeId }).then((res) => {
      this.setState({ products: res });
    });
  }

  componentDidMount() {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  handleInput(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  openReviewForm = (e) => {
    e.preventDefault();
    this.setState({
      openReview: true,
    });
  };
  onOpenModalReport = () => {
    this.setState({ open: true });
  };
  onOpenModal = () => this.setState({ registerModal: true });
  onCloseModal = () =>
    this.setState({
      registerModal: false,
      open: false,
      optionSelected: null,
      reportComment: "",
      errorMessage: false,
      disabled: false,
    });

  handleOption = async (id) => {
    this.setState({ optionSelected: id });
    console.log(id);
  };

  handleComment = (event) => {
    this.setState({ reportComment: event.target.value, errorMessage: true });
    if (event.target.value.length >= 10) {
      this.setState({ disabled: true, errorMessage: false });
    } else {
      this.setState({ disabled: false, errorMessage: true });
    }
    // console.log(event.target.value);
  };

  sendReport = () => {
    const { product } = this.props;
    const data = {
      storeId: this.props.storeId,
      reportTypeId: this.state.optionSelected,
      description: this.state.reportComment,
    };
    reportStore(data).then((res) => {
      console.log(res);
      toast.success(`${this.props.translate("reportNotif")}`);
      // this.props.locale == "uz" ? toast.success(`${translate("reportNotif")}`) : toast.success(`${translate("reportNotif")}`);
      this.onCloseModal();
    });
  };

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rate: nextValue });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    console.log(selected);
    getStoreComments(this.state.storeId, selected + 1).then(async (res) => {
      const totalLength = res.headers.get("totalLength");
      const pages = Math.ceil(totalLength / 3);
      this.setState({ pages });
      const commentList = await res
        .clone()
        .json()
        .then((clist) => {
          this.setState({ storeComments: clist });
        });
    });
  };

  addStoreComment = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      // const { storeId } = this.props;
      const { rate, comment, role, token, storeId, totalLength } = this.state;

      if (token && role == 3) {
        this.setState({ disableBtn: true });
        postStoreComment({ rate, comment, storeId }, token).then(
          async (res) => {
            if (res.status === 200) {
              console.log("comment added about store");
              const newComment = await res
                .clone()
                .json()
                .then((result) => {
                  setTimeout(() => {
                    this.setState({
                      storeComments: [result, ...this.state.storeComments],
                      openReview: false,
                      disableBtn: false,
                    });
                    toast.success(this.props.translate("Comment_added"));
                  }, 2000);
                });
            } else {
              setTimeout(() => {
                this.setState({
                  disableBtn: false,
                });
              }, 2000);

              console.log("smth error with add comment about store");
            }
          }
        );
      } else {
        this.setState({ registerModal: true });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  switchPage = (page) => {
    const { store, storeComments } = this.state;
    console.log(this.props.storeId);
    switch (page) {
      case 0:
        return (
          <div>
            <p className="about-title">
              {this.props.translate("About_Company")}
            </p>
            <div className="company-description">
              <p>
                {store.aboutUs_ru && store.aboutUs_uz
                  ? this.props.locale === "en"
                    ? store.aboutUs_uz
                    : store.aboutUs_ru
                  : this.props.translate("No_description_about_company")}
              </p>
            </div>
            <div className="py-3 d-flex justify-content-end">
              <p
                className="contact-p"
                onClick={() => this.onOpenModalReport()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <svg
                  style={{
                    fill: "#009F73",
                    width: "20px",
                    marginRight: "5px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
                </svg>
                <b>{this.props.translate("complain")}</b>
              </p>
            </div>
          </div>
        );
        break;
      case 1:
        return (
          <div>
            <p className="about-title mb-4">
              {this.props.translate("Comments_about_company")}
            </p>

            <form className="store-review-container">
              {this.state.openReview && (
                <div>
                  <div>
                    <label className="mb-1">
                      {this.props.translate("Total_seller_rating")}:
                    </label>
                    <StarRatingComponent
                      className="store-star-rating"
                      name="store_rate"
                      starCount={5}
                      value={this.state.rate}
                      onStarClick={this.onStarClick.bind(this)}
                      onBlur={() => this.validator.showMessageFor("rate")}
                    />
                    {this.validator.message(
                      "rate",
                      this.state.rate,
                      "required"
                    )}
                  </div>
                  <div className="mt-2 mb-2">
                    <label htmlFor="review_text">
                      {this.props.translate("Comment_about_seller")}:
                    </label>
                    <textarea
                      className="px-2 py-2"
                      id="review-text"
                      name="comment"
                      rows="5"
                      cols="50"
                      onChange={this.handleInput}
                      onBlur={() => this.validator.showMessageFor("comment")}
                    />
                    {this.validator.message(
                      "comment",
                      this.state.comment,
                      "required"
                    )}
                  </div>
                </div>
              )}

              {this.state.role !== 2 && this.state.role !== 1 && (
                <button
                  className="add-comment-btn mb-5"
                  disabled={this.state.disableBtn}
                  style={{
                    cursor: this.state.disableBtn ? "not-allowed" : "",
                    backgroundColor: this.state.disableBtn ? "#cccccc" : "",
                    border: this.state.disableBtn ? "#cccccc" : "",
                  }}
                  onClick={
                    this.state.openReview
                      ? this.addStoreComment
                      : this.openReviewForm
                  }
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ClipLoader
                      color={"#fff"}
                      loading={this.state.disableBtn}
                      size={20}
                    />{" "}
                    <span style={{ marginLeft: "8px" }}>
                      {this.props.translate("Put_comment")}
                    </span>
                  </span>
                </button>
              )}
            </form>

            {storeComments.length > 0 ? (
              <div>
                <div className="mb-4">
                  {storeComments.map((el, index) => {
                    return (
                      <div key={index} className="store-comment-fluid">
                        <div className="comment-author">
                          <div className="author-info">
                            <p>
                              <i className="fa fa-user" />{" "}
                              {el.user ? el.user.name : "hi"}{" "}
                              {el.user ? el.user.surname : "hello"}
                            </p>
                            <p>
                              <i className="fa fa-calendar" />{" "}
                              {moment(el.createdAt).format("DD/MM/YYYY")}
                            </p>
                          </div>
                          <div>
                            <StarRatingComponent
                              className="store-star-rating"
                              name="store_rate"
                              starCount={5}
                              value={el.rate}
                            />
                          </div>
                        </div>
                        <div>
                          <p>{el.comment}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <ReactPaginate
                  previousLabel={<i className="fa fa-angle-double-left" />}
                  previousClassName={"btn-pagination"}
                  nextLabel={<i className="fa fa-angle-double-right" />}
                  nextClassName={"btn-pagination"}
                  pageClassName={"page-pagination"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active-pagination"}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      case 3:
        return (
          <div>
            <p className="about-title">{this.props.translate("Products")}</p>
            <div className="company-description">
              <div className="product-wrapper-grid">
                <div className="container-fluid">
                  {this.state.products.length > 0 && (
                    <div className="row">
                      {this.state.products.map((product, index) => (
                        <div
                          className={`${"col-xl-3 col-md-6 col-sm-6 col-6"}`}
                          key={index}
                        >
                          <ProductListItem
                            product={product}
                            key={index}
                            onAddToWishlistClicked={() =>
                              this.props.addToWishlist(
                                product,
                                this.props.locale
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        return <h1>smth wrong</h1>;
        break;
    }
  };

  render() {
    const { translate, locale } = this.props;
    const { store } = this.state;
    const { workingTimes } = store;
    const { open } = this.state;
    console.log(store);
    return (
      <>
        <Header
          shopNavbar={true}
          shopImage={store.image ? `${store.image.imageUrl}` : null}
        />
        <div style={{ display: "none" }}>
          <h4
            id="about_shop_btn"
            onClick={() => {
              this.setState({ page: 0 });
            }}
          >
            О нас
          </h4>
          <h4
            id="shop_comment_btn"
            onClick={() => {
              this.setState({ page: 1 });
            }}
          >
            Отзывы
          </h4>
          <h4
            id="shop_products_btn"
            onClick={() => {
              this.setState({ page: 3 });
            }}
          >
            Product
          </h4>
        </div>
        <div className="container mt-5">
          <div className="shop-container">
            <div className="contact-container">
              <p className="contact-title">{translate("Contacts")}</p>
              <p className="contact-p">{translate("Phones")}</p>
              <ul>
                <li>
                  <p>
                    <i className="fa fa-phone" /> {store.phone}
                  </p>
                </li>
                {store.phone1 && (
                  <li>
                    <p>
                      <i className="fa fa-phone" /> {store.phone1}
                    </p>
                  </li>
                )}

                {store.phone2 && (
                  <li>
                    <p>
                      <i className="fa fa-phone" /> {store.phone2}
                    </p>
                  </li>
                )}
              </ul>
              <p className="contact-p">{translate("Address")}</p>
              <ul>
                <li>
                  <p>
                    <i className="fa fa-map-marker" /> {store.address}
                  </p>
                </li>
                {store.telegram && (
                  <li>
                    <a
                      href={
                        store.telegram.charAt(0) === "@"
                          ? `https://telegram.me/${store.telegram.replace(
                              "@",
                              ""
                            )}`
                          : `https://telegram.me/${store.telegram}`
                      }
                      target="_blank"
                    >
                      <i className="fa fa-telegram" /> {store.telegram}
                    </a>
                  </li>
                )}
              </ul>
              {workingTimes ? (
                <div>
                  {workingTimes.length !== 0 ? (
                    <p className="contact-p">{translate("Work_schedule")}</p>
                  ) : (
                    ""
                  )}
                  <ul>
                    {workingTimes.map((day, index) => (
                      <li key={index}>
                        <p>
                          {locale === "en"
                            ? day.weekday.name_uz
                            : day.weekday.name_ru}
                          : {day.startTime}-{day.finishTime}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="company-info">
              {this.switchPage(this.state.page)}
            </div>
          </div>
        </div>
        <Modal
          open={this.state.registerModal}
          onClose={this.onCloseModal}
          center
        >
          <section className="login-page section-b-space">
            <div className="container">
              <div className="row">
                <div className="col right-login">
                  <h3>{translate("Please")}</h3>
                  <div className="theme-card authentication-right">
                    <p>{translate("Please_login")}</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/login`}
                      className="btn btn-solid"
                    >
                      {translate("Login")}
                    </Link>
                    <p className="mt-2">
                      {translate("Not_yet_account")}{" "}
                      <Link to={`${process.env.PUBLIC_URL}/login`}>
                        {translate("Please_sign_up")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal>
        <Modal open={open} onClose={this.onCloseModal} center>
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col py-3">
                    <div style={{ textAlign: "center" }}>
                      <svg
                        style={{
                          fill: "#009F73",
                          width: "80px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
                      </svg>
                    </div>
                    <h4 style={{ margin: "0 30px" }}>
                      {translate("warrningSpam")}
                    </h4>
                    {this.state.reportTypes.length
                      ? this.state.reportTypes.map((item, index) => (
                          <div
                            style={{ cursor: "pointer" }}
                            className="custom-control custom-checkbox collection-filter-checkbox"
                          >
                            <input
                              type="checkbox"
                              checked={this.state.optionSelected == item.id}
                              onChange={() => this.handleOption(item.id)}
                              value={item.id}
                              id={item.id}
                              className="custom-control-input reportCheck"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              className="custom-control-label"
                              htmlFor={item.id}
                            >
                              {this.props.locale === "en"
                                ? item.name_uz
                                : item.name_ru}
                            </label>
                          </div>
                        ))
                      : ""}

                    {this.state.optionSelected !== null ? (
                      <div className="form-group">
                        <label htmlFor="description">
                          {translate("description")}
                        </label>
                        <textarea
                          cols="30"
                          rows="5"
                          value={this.state.reportComment}
                          onChange={this.handleComment}
                          className="form-control"
                          id="description"
                        />
                        {/* <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required=""
                      /> */}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.errorMessage ? (
                      <p> {translate("requiredMess")}</p>
                    ) : (
                      ""
                    )}
                    <button
                      className="btnWarning"
                      disabled={this.state.disabled ? false : true}
                      onClick={this.sendReport}
                    >
                      {translate("sendReport")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <ToastContainer />
        <FooterFive logoName={"logo/mebzone.svg"} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  storeId: state.comment.storeId,
  locale: state.Intl.locale,
});

export default connect(
  mapStateToProps,
  { addToWishlist }
)(withTranslate(shop));
