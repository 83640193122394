import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: "0px" },
    };
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: "-300px" } });
    }
  }

  openNav() {
    console.log("open");
    this.setState({ navClose: { right: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { right: "-410px" } });
  }

  onMouseEnterHandler() {
    if (window.innerWidth > 1199) {
      document.querySelector("#main-menu").classList.add("hover-unset");
    }
  }

  handleSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensubmenu"))
      event.target.nextElementSibling.classList.remove("opensubmenu");
    else {
      document.querySelectorAll(".nav-submenu").forEach(function(value) {
        value.classList.remove("opensubmenu");
      });
      document
        .querySelector(".mega-menu-container")
        .classList.remove("opensubmenu");
      event.target.nextElementSibling.classList.add("opensubmenu");
    }
  };

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (
      event.target.parentNode.nextElementSibling.classList.contains(
        "opensubmegamenu"
      )
    )
      event.target.parentNode.nextElementSibling.classList.remove(
        "opensubmegamenu"
      );
    else {
      document.querySelectorAll(".menu-content").forEach(function(value) {
        value.classList.remove("opensubmegamenu");
      });
      event.target.parentNode.nextElementSibling.classList.add(
        "opensubmegamenu"
      );
    }
  };

  render() {
    const { translate, shopNavbar } = this.props;
    return (
      <div>
        <div className="main-navbar">
          <div id="mainnav">
            <div className="toggle-nav" onClick={this.openNav.bind(this)}>
              <i className="fa fa-bars sidebar-bar" />
            </div>
            <ul className="nav-menu" style={this.state.navClose}>
              <li className="back-btn" onClick={this.closeNav.bind(this)}>
                <div className="mobile-back text-right">
                  <span className="scrollItem">{translate("Back")}</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                </div>
              </li>
              <li>
                {shopNavbar ? (
                  <a
                    className="nav-link scrollItem"
                    onClick={() => {
                      document.getElementById("about_shop_btn").click();
                    }}
                  >
                    <b>{translate("About_us")}</b>
                  </a>
                ) : (
                  <a
                    className="nav-link scrollItem"
                    onClick={() => {
                      document.getElementById("category").scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest",
                      });
                    }}
                  >
                    <b>{translate("Categories")}</b>
                  </a>
                )}
              </li>
              <li>
                {shopNavbar ? (
                  <a
                    className="nav-link scrollItem"
                    onClick={() => {
                      document.getElementById("shop_comment_btn").click();
                    }}
                  >
                    <b>{translate("Comments")}</b>
                  </a>
                ) : (
                  <a
                    className="nav-link scrollItem"
                    onClick={() => {
                      document
                        .getElementById("sponsor")
                        .scrollIntoView({ behavior: "smooth", block: "start" });
                    }}
                  >
                    <b>{translate("Be_partner")}</b>
                  </a>
                )}
              </li>
              <li>
                {shopNavbar ? (
                  <a
                    className="nav-link scrollItem"
                    onClick={() => {
                      document.getElementById("shop_products_btn").click();
                    }}
                  >
                    <b>{translate("Products")}</b>
                  </a>
                ) : (
                  <a
                    className="nav-link scrollItem"
                    onClick={() => {
                      document
                        .getElementById("contact")
                        .scrollIntoView({ behavior: "smooth", block: "start" });
                    }}
                  >
                    <b>{translate("Contacts")}</b>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslate(NavBar);
