import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import { Slider3 } from "../../../services/script";

class mBlogSection extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Slider {...Slider3} className="slide-3 no-arrow ">
              <div>
                <div className="col-md-12">
                  <div className="sponsor-class">
                    <img
                      width="50%"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/blog/sponsor1.jpg`}
                      alt="sponsor1"
                    />
                    <span />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-12">
                  <div className="sponsor-class">
                    <img
                      width="57%"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/blog/sponsor2.jpg`}
                      alt="sponsor2"
                    />
                    <span />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-12">
                  <div className="sponsor-class">
                    <img
                      width="50%"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/blog/sponsor3.jpg`}
                      className="img-fluid"
                      alt=""
                    />
                    <span />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-12">
                  <div className="sponsor-class">
                    <img
                      width="50%"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/blog/sponsor4.jpg`}
                      className="img-fluid"
                      alt=""
                    />
                    <span />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-12">
                  <div className="sponsor-class">
                    <img
                      width="50%"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/blog/sponsor5.jpg`}
                      className="img-fluid"
                      alt=""
                    />
                    <span />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-12">
                  <div className="sponsor-class">
                    <img
                      width="50%"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/blog/sponsor6.jpg`}
                      className="img-fluid"
                      alt=""
                    />
                    <span />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-12">
                  <div className="sponsor-class">
                    <img
                      width="50%"
                      // height="200px"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/blog/sponsor7.jpg`}
                      className="img-fluid"
                      alt=""
                    />
                    <span />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default mBlogSection;
