import * as types from "../constants/ActionTypes";

//bu filter qilish uchun
const loadmoreReducerDefaultState = {
  loadmore: [],
};

const loadmoreReducer = (state = loadmoreReducerDefaultState, action) => {
  // console.log(action);
  switch (action.type) {
    case types.LOADMORE:
      return {
        loadmore: action.list,
      };

    case types.LOADMORE_CERTAIN_CATEGORY:
      state.loadmore.map((el) => {
        if (el.id === action.id) {
          if (action.moreItem) {
            const { categories } = el;
            action.moreItem.map((el) => categories.push(el));
            el.page = action.page;
            el.visibleBtn = action.visibleBtn;
          }
        }
      });
      return {
        ...state,
        loadmore: [...state.loadmore],
      };
    default:
      return state;
  }
};

export default loadmoreReducer;
