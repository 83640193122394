import { combineReducers } from "redux";
//import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import { IntlReducer as Intl } from "react-redux-multilingual";

// Import custom components
import productReducer from "./products";
// import cartReducer from "./cart";
import filtersReducer from "./filters";
import wishlistReducer from "./wishlist";
// import compareReducer from "./compare";
import mainCategoryReducer from "./mainCategory";
import helpReducer from "./help";
import commentReducer from "./comment";
import userReducer from "./user";
import productDetailReducer from "./product_detail";
import loadmoreReducer from "./loadmore";

const rootReducer = combineReducers({
  mainCategories: mainCategoryReducer,
  data: productReducer,
  // cartList: cartReducer,
  filters: filtersReducer,
  wishlist: wishlistReducer,
  // compare: compareReducer,
  help: helpReducer,
  comment: commentReducer,
  user: userReducer,
  product_detail: productDetailReducer,
  loadmore: loadmoreReducer,
  Intl,
});

export default rootReducer;
