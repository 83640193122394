import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import {
  chosenProductId,
  filterStore,
  getVisibleproducts,
} from "../../../actions/index";
import { withTranslate } from "react-redux-multilingual";
import StarRatingComponent from "react-star-rating-component";
import moment from "moment";

class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock: "InStock",
      quantity: 1,
      image: "",
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: "InStock" });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({ stock: "Out of Stock !" });
    }
  };
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) });
  };

  changeProductId = async (productId) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/right-sidebar/product/${productId}`
    );
  };

  filterStoreBtn = async (storeId) => {
    await this.props.filterStore({ storeId: storeId });
    this.props.getVisibleproducts(this.props.filters);
  };

  render() {
    const {
      product,
      symbol,
      onAddToWishlistClicked,
      translate,
      locale,
    } = this.props;
    const { open } = this.state;
    const { USD_UZS } = this.props.rate;

    let RatingStars = [];
    for (var i = 0; i < product.rate; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }

    return (
      <div className="product-box">
        <div className="img-wrapper">
          <div className="front">
            <img
              onClick={() => {
                this.changeProductId(product.id);
              }}
              src={
                product.images
                  ? `${product.images[0].imageUrl}?width=315&height=200`
                  : `${
                      process.env.PUBLIC_URL
                    }/assets/images/furniture/product/f-1.jpg`
              }
              className="img-fluid"
              alt=""
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="cart-info cart-wrap">
            <a
              href="javascript:void(0)"
              title={translate("Add_to_wishlist")}
              onClick={() => onAddToWishlistClicked()}
            >
              <i className="fa fa-heart" aria-hidden="true" />
            </a>
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#quick-view"
              title={translate("Quick_view")}
              onClick={this.onOpenModal}
            >
              <i className="fa fa-search" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="product-detail">
          <div>
            <h4 className="mt-2 mb-0 pb-0">
              <StarRatingComponent
                name="product_rate"
                starCount={5}
                value={Number(product.rate)}
              />
            </h4>

            <h4 className="mb-2">
              <NumberFormat
                value={symbol === "$" ? product.price / USD_UZS : product.price}
                thousandSeparator=" "
                decimalScale={2}
                prefix={symbol === "$" ? "$" : null}
                suffix={symbol === "$" ? null : " UZS"}
                displayType="text"
              />
            </h4>
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => this.changeProductId(product.id)}
            >
              {locale === "en" ? product.name_uz : product.name_ru}
            </h6>
            {product.store && (
              <Link to={`${process.env.PUBLIC_URL}/store/${product.storeId}`}>
                <h6
                  style={{ color: "#a8cf45", cursor: "pointer" }}
                  // onClick={(e) => this.filterStoreBtn(product.storeId)}
                >
                  {product.store.brandname}
                </h6>
              </Link>
            )}
            {product.createdAt && (
              <h6 className="pb-2">
                {moment(product.createdAt).format("D")}{" "}
                {translate(moment(product.createdAt).format("MMMM"))}
              </h6>
            )}
          </div>
        </div>
        <Modal open={open} onClose={this.onCloseModal} center>
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6  col-xs-12">
                    <div className="quick-view-img">
                      <img
                        src={
                          product.images
                            ? product.images[0].imageUrl
                            : `${
                                process.env.PUBLIC_URL
                              }/assets/images/furniture/product/f-1.jpg`
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 rtl-text">
                    <div className="product-right">
                      <h2>
                        {locale === "en" ? product.name_uz : product.name_ru}
                      </h2>
                      <h3>
                        <NumberFormat
                          value={
                            symbol === "$"
                              ? product.price / USD_UZS
                              : product.price
                          }
                          thousandSeparator=" "
                          decimalScale={2}
                          prefix={symbol === "$" ? "$" : null}
                          suffix={symbol === "$" ? null : " UZS"}
                          displayType="text"
                        />
                      </h3>
                      <div className="border-product">
                        <h6 className="product-title">
                          {translate("Feature_and_descriptions")} :
                        </h6>
                        <p>
                          {locale === "en"
                            ? product.description_uz
                            : product.description_ru}
                        </p>
                      </div>
                      <div className="product-buttons">
                        <button
                          className="btn btn-solid"
                          onClick={() => onAddToWishlistClicked()}
                        >
                          <i className="fa fa-heart" aria-hidden="true" />
                        </button>
                        <button
                          onClick={() => this.changeProductId(product.id)}
                          className="btn btn-solid ml-2"
                        >
                          {translate("In_detail")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
  rate: state.data.rate,
  symbol: state.data.symbol,
  locale: state.Intl.locale,
});

export default connect(
  mapStateToProps,
  { chosenProductId, filterStore, getVisibleproducts }
)(withRouter(withTranslate(ProductListItem)));
