import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";

class TopBarDarkMeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      surname: "",
      show: false,
      finalValue: null,
    };
  }

  componentDidMount() {
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    if (userObj) {
      if (userObj.name && userObj.surname) {
        this.setState({ username: userObj.name, surname: userObj.surname });
        this.setState({ finalValue: true });
      }
    } else {
      this.setState({ finalValue: false });
    }
  }

  logoutBtn = () => {
    localStorage.removeItem("mebzone-user");
    this.props.history.push(`${process.env.PUBLIC_URL}/`);
    window.location.reload();
  };

  showingBtn = () => {
    this.setState({ show: !this.state.show });
    setTimeout(() => {
      this.setState({ show: false });
    }, 3000);
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="top-header top-header-dark3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="header-contact">
                <ul>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/`}>
                      {translate("Welcome_to_mebzone")}
                    </Link>
                  </li>
                  <li>
                    <a href="tel:+998 97 700 06 63">
                      {/* <i
                        className="fa fa-phone topbar-a-i"
                        aria-hidden="true"
                      />{" "} */}
                      +998 97 700 06 63
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <ul className="header-dropdown">
                {this.state.finalValue ? (
                  <li
                    className="mobile-account btn-relative"
                    onClick={() => this.showingBtn()}
                  >
                    <img
                      style={{ margin: "0 auto" }}
                      className="login-ico"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/icon/user.svg`}
                      alt=""
                    />
                    {this.state.username} {this.state.surname}
                    {this.state.show && (
                      <div className="landing-topbar-logout">
                        <ul>
                          <li>
                            <Link to={`${process.env.PUBLIC_URL}/user-profile`}>
                              <span> {translate("My_profile")} </span>
                            </Link>
                          </li>
                          <li onClick={this.logoutBtn}>
                            {translate("Log_out")}
                          </li>
                        </ul>
                      </div>
                    )}
                    <p
                      className="hidden-p"
                      style={{
                        margin: "0",
                        padding: "0",
                        color: "#fff",
                        marginTop: "5px",
                      }}
                    >
                      {translate("Profile")}
                    </p>
                  </li>
                ) : (
                  <li className="mobile-account user-login">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Link to={`${process.env.PUBLIC_URL}/login`}>
                        <img
                          className="login-ico"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/icon/user.svg`}
                          alt=""
                        />
                        {translate("Login")}
                      </Link>
                    </div>
                    <p
                      className="hidden-p"
                      style={{
                        margin: "0",
                        padding: "0",
                        color: "#fff",
                        marginTop: "5px",
                      }}
                    >
                      {translate("Profile")}
                    </p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslate(TopBarDarkMeb));
