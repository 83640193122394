import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import SimpleReactValidator from "simple-react-validator";
import { Eye, EyeOff } from "react-feather";

// custom files
import BackgroundContainer from "./components/BackgroundContainer";
import "./style/auth.scss";
import { registerUser } from "./api/auth";

export class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      surname: "",
      phone: "+998" + localStorage.getItem("new-user-phone"),
      password: "",
      roles: [],
      disableBtn: false,
      serverErr: false,
      showPassword: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });

    if (!localStorage.getItem("new-user-phone")) {
      this.navigateBtn("login");
    }
  }

  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  navigateBtn = (path) => {
    this.props.history.push(`${process.env.PUBLIC_URL}/${path}`);
  };

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handlePassword(e) {
    e.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  registerUserBtn = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const { name, surname, phone, password, roles } = this.state;
      this.setState({ disableBtn: true });
      registerUser({ name, surname, phone, password, roles }).then((res) => {
        if (res.status === 200) {
          console.log(res);
          this.setState({ disableBtn: false });
          this.navigateBtn("complete");
        } else {
          console.log("not verified account");
          this.setState({ disableBtn: false, serverErr: true });
          setTimeout(() => {
            this.setState({ serverErr: false });
          }, 2000);
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <BackgroundContainer>
        <form className="register-container" autoComplete="off">
          <div>
            <h3 className="form-title">{translate("Registration")}</h3>
            <label htmlFor="phone">{translate("Name")}:</label>
            <input
              type="text"
              placeholder={translate("Enter_name")}
              name="name"
              value={this.state.name}
              onChange={this.handleInputChange}
              onBlur={() => this.validator.showMessageFor("name")}
            />
            {this.validator.message("name", this.state.name, "required")}
            <label htmlFor="phone">{translate("Surname")}:</label>
            <input
              type="text"
              placeholder={translate("Enter_surname")}
              name="surname"
              value={this.state.surname}
              onChange={this.handleInputChange}
              onBlur={() => this.validator.showMessageFor("surname")}
            />
            {this.validator.message("surname", this.state.surname, "required")}
            <label htmlFor="password">{translate("Password")}:</label>
            <div className="register-show-password-span-container">
              <input
                type={this.state.showPassword ? "text" : "password"}
                placeholder={translate("Enter_password")}
                name="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                onBlur={() => this.validator.showMessageFor("password")}
              />
              <span
                className="register-show-password-icon"
                onClick={(e) => this.handlePassword(e)}
              >
                {this.state.showPassword ? (
                  <EyeOff size={18} />
                ) : (
                  <Eye size={18} />
                )}
              </span>
            </div>
            {this.validator.message(
              "password",
              this.state.password,
              "required|min:8"
            )}

            <label>{translate("Choose_type")}:</label>
            <div className="user-type-container">
              <label htmlFor="customer">
                {translate("Customer")}
                <input
                  id="customer"
                  type="radio"
                  placeholder={"enter password"}
                  name="user"
                  value={3}
                  onChange={(e) => {
                    this.setState({ roles: [3] });
                  }}
                  onBlur={() => this.validator.showMessageFor("roles")}
                />
              </label>
              <label htmlFor="seller">
                {translate("Company")}
                <input
                  id="seller"
                  type="radio"
                  placeholder={"enter password"}
                  name="user"
                  value={2}
                  onChange={(e) => {
                    this.setState({ roles: [2] });
                  }}
                  onBlur={() => this.validator.showMessageFor("roles")}
                />
              </label>
              {this.validator.message("roles", this.state.roles, "required")}
            </div>
          </div>
          <div>
            <div className="server-errors">
              {this.state.serverErr && <p>{translate("Server_error")}</p>}
            </div>
            <button
              className="register-btn"
              onClick={(e) => this.registerUserBtn(e)}
              disabled={this.state.disableBtn}
              style={{
                cursor: this.state.disableBtn ? "not-allowed" : "",
              }}
            >
              {this.state.disableBtn ? (
                <ClipLoader color={"#fff"} loading={true} size={20} />
              ) : (
                translate("Continue")
              )}
            </button>
          </div>
        </form>
      </BackgroundContainer>
    );
  }
}

export default connect()(withTranslate(Register));
