import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import "./index.scss";
import store from "./store";
import translations from "./constants/translations";
import { getMainCategories, regionList, getCurrency } from "./actions";
import Mebzone from "./components/layouts/mebzone/main";
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import RightSideBar from "./components/products/right-sidebar";
import Layout from "./components/app";
import wishList from "./components/wishlist";
import PageNotFound from "./components/pages/404";
// import Login from "./components/pages/login";
// import Register from "./components/pages/register";
import Search from "./components/pages/search";
import ForgetPassword from "./components/pages/forget-password";
import Contact from "./components/pages/contact";

import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import SendCode from "./components/auth/SendCode";
import ConfirmCode from "./components/auth/ConfirmCode";
import ForgotPassword from "./components/auth/ForgotPassword";

import Partners from "./components/partners/partner";
import UserProfile from "./components/userProfile/userProfile";
import Shop from "./components/shops/shop";
import UpdateStore from "./components/userProfile/updateStore";
import { checkToken } from "./fetch/filterApi";
import Complete from "./components/auth/Complete";

class Root extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    if (userObj) {
      checkToken(userObj.token).then((res) => {
        if (res.isExpired) {
          localStorage.removeItem("mebzone-user");
          return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
        }
      });
    } else {
      localStorage.removeItem("mebzone-user");
      return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
    }
  }

  render() {
    store.dispatch(getMainCategories());
    store.dispatch(regionList());
    store.dispatch(getCurrency());

    return (
      <Provider store={store}>
        <IntlProvider translations={translations} locale="en">
          <BrowserRouter basename={"/"}>
            <ScrollContext>
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={Mebzone}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/login`}
                  component={Login}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/register`}
                  component={Register}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/sendCode`}
                  component={SendCode}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/confirmCode`}
                  component={ConfirmCode}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/forget-password`}
                  component={ForgotPassword}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/complete`}
                  component={Complete}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-profile`}
                  component={UserProfile}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/update-store/:storeId`}
                  component={UpdateStore}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/store/:storeId`}
                  component={Shop}
                />

                <Layout>
                  <Route
                    path={`${process.env.PUBLIC_URL}/left-sidebar/collection`}
                    component={CollectionLeftSidebar}
                  />
                  <Route
                    path={`${
                      process.env.PUBLIC_URL
                    }/right-sidebar/product/:productId`}
                    component={RightSideBar}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/wishlist`}
                    component={wishList}
                  />

                  {/* <Route exact path="*" component={PageNotFound} /> */}

                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/404`}
                    component={PageNotFound}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/login`}
                    component={Login}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/register`}
                    component={Register}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/search`}
                    component={Search}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/forget-password`}
                    component={ForgetPassword}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/contact`}
                    component={Contact}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/partner`}
                    component={Partners}
                  />
                </Layout>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
