import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";
import { withTranslate } from "react-redux-multilingual";
// Import custom components
import store from "../../../store";
import NavBar from "./common/navbar-mebzone";
import SideBar from "./common/meb-sidebar";
import TopBar from "./common/topbar-mebzone";
import LogoImage from "./common/logo";
import { changeCurrency } from "../../../actions";
import { connect } from "react-redux";
import { filterSearch, getVisibleproducts } from "../../../actions";

class HeaderSix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      search: "",
      langAndCurrency: false,
    };
  }
  /*=====================
         Pre loader
         ==========================*/
  componentDidMount() {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);

    this.setState({ open: true });
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    // console.log("num", number);
    if (number >= 300) {
      if (window.innerWidth < 577) {
        document.getElementById("sticky").classList.remove("fixed");
      } else document.getElementById("sticky").classList.add("fixed");
    } else {
      document.getElementById("sticky").classList.remove("fixed");
    }
  };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }
  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  handleSearch = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitSearch = async (e) => {
    e.preventDefault();
    if (this.state.search == "") {
      return;
    } else {
      await this.props.filterSearch(this.state.search);
      this.props.getVisibleproducts(this.props.filters);
      localStorage.setItem("hidefilters", true);
      this.props.history.push(
        `${process.env.PUBLIC_URL}/left-sidebar/collection`
      );
    }
  };

  keyPressed = (e) => {
    if (e.charCode == 13) {
      this.submitSearch(e);
    }
  };

  openlangAndCurrency = () => {
    this.setState({ langAndCurrency: !this.state.langAndCurrency });
    setTimeout(() => {
      this.setState({ langAndCurrency: false });
    }, 4000);
  };

  render() {
    const { translate } = this.props;
    return (
      <div>
        <header id="sticky" className="sticky">
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option" />
          {/*Top Header Component*/}
          <TopBar />

          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left">
                    <div className="navbar">
                      <a href="javascript:void(0)" onClick={this.openNav}>
                        <div className="bar-style">
                          {" "}
                          <i
                            className="fa fa-bars sidebar-bar"
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                      {/*SideBar Navigation Component*/}
                      <SideBar />
                    </div>
                    <div className="brand-logo">
                      <LogoImage shopImage={this.props.shopImage} />
                    </div>
                  </div>
                  <div className="menu-right pull-right">
                    {/*Top Navigation Bar Component*/}
                    <NavBar shopNavbar={this.props.shopNavbar} />

                    <div>
                      <div className="icon-nav">
                        <ul>
                          <li
                            onClick={this.openSearch}
                            className="onhover-div mobile-search"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className="navbar-ico"
                              >
                                <path d="M8.90592 0.75C11.0664 0.75 13.1384 1.60602 14.6661 3.12976C16.1938 4.65349 17.0521 6.72012 17.0521 8.875C17.0521 10.8875 16.3127 12.7375 15.097 14.1625L15.4354 14.5H16.4255L22.6917 20.75L20.8118 22.625L14.5456 16.375V15.3875L14.2072 15.05C12.7785 16.2625 10.9237 17 8.90592 17C6.74543 17 4.67342 16.144 3.14572 14.6202C1.61802 13.0965 0.759766 11.0299 0.759766 8.875C0.759766 6.72012 1.61802 4.65349 3.14572 3.12976C4.67342 1.60602 6.74543 0.75 8.90592 0.75ZM8.90592 3.25C5.77279 3.25 3.26628 5.75 3.26628 8.875C3.26628 12 5.77279 14.5 8.90592 14.5C12.0391 14.5 14.5456 12 14.5456 8.875C14.5456 5.75 12.0391 3.25 8.90592 3.25Z" />
                              </svg>
                            </div>
                            <p
                              className="hidden-p"
                              style={{
                                margin: "0",
                                padding: "0",
                                color: "#fff",
                                marginTop: "5px",
                              }}
                            >
                              {translate("Search")}
                            </p>
                          </li>
                          <li
                            className="onhover-div mobile-setting"
                            onClick={() => this.openlangAndCurrency()}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* <img src={`${process.env.PUBLIC_URL}/assets/images/icon/mebzone-lang.svg`} className="img-fluid" alt="" /> */}
                              <svg
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                xmlns="http://www.w3.org/2000/svg"
                                className="navbar-ico"
                              >
                                <path d="M12.9875 0.5C6.0875 0.5 0.5 6.1 0.5 13C0.5 19.9 6.0875 25.5 12.9875 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 12.9875 0.5ZM21.65 8H17.9625C17.5625 6.4375 16.9875 4.9375 16.2375 3.55C18.5375 4.3375 20.45 5.9375 21.65 8ZM13 3.05C14.0375 4.55 14.85 6.2125 15.3875 8H10.6125C11.15 6.2125 11.9625 4.55 13 3.05ZM3.325 15.5C3.125 14.7 3 13.8625 3 13C3 12.1375 3.125 11.3 3.325 10.5H7.55C7.45 11.325 7.375 12.15 7.375 13C7.375 13.85 7.45 14.675 7.55 15.5H3.325ZM4.35 18H8.0375C8.4375 19.5625 9.0125 21.0625 9.7625 22.45C7.4625 21.6625 5.55 20.075 4.35 18ZM8.0375 8H4.35C5.55 5.925 7.4625 4.3375 9.7625 3.55C9.0125 4.9375 8.4375 6.4375 8.0375 8ZM13 22.95C11.9625 21.45 11.15 19.7875 10.6125 18H15.3875C14.85 19.7875 14.0375 21.45 13 22.95ZM15.925 15.5H10.075C9.9625 14.675 9.875 13.85 9.875 13C9.875 12.15 9.9625 11.3125 10.075 10.5H15.925C16.0375 11.3125 16.125 12.15 16.125 13C16.125 13.85 16.0375 14.675 15.925 15.5ZM16.2375 22.45C16.9875 21.0625 17.5625 19.5625 17.9625 18H21.65C20.45 20.0625 18.5375 21.6625 16.2375 22.45ZM18.45 15.5C18.55 14.675 18.625 13.85 18.625 13C18.625 12.15 18.55 11.325 18.45 10.5H22.675C22.875 11.3 23 12.1375 23 13C23 13.8625 22.875 14.7 22.675 15.5H18.45Z" />
                              </svg>
                            </div>
                            {this.state.langAndCurrency && (
                              <div className="show-div setting">
                                <h6>{translate("Language")}</h6>
                                <ul>
                                  <li>
                                    <a
                                      href={null}
                                      onClick={() => this.changeLanguage("en")}
                                    >
                                      {translate("Uzbek")}
                                    </a>{" "}
                                  </li>
                                  <li>
                                    <a
                                      href={null}
                                      onClick={() => this.changeLanguage("fn")}
                                    >
                                      {translate("Russian")}
                                    </a>{" "}
                                  </li>
                                </ul>
                                <h6 style={{ textTransform: "unset" }}>
                                  {translate("Currency")}
                                </h6>
                                <ul className="list-inline">
                                  <li>
                                    <a
                                      href={null}
                                      onClick={() =>
                                        this.props.changeCurrency("UZS")
                                      }
                                    >
                                      UZS
                                    </a>{" "}
                                  </li>
                                  <li>
                                    <a
                                      href={null}
                                      onClick={() =>
                                        this.props.changeCurrency("$")
                                      }
                                    >
                                      Dollar
                                    </a>{" "}
                                  </li>
                                </ul>
                              </div>
                            )}
                            <p
                              className="hidden-p"
                              style={{
                                margin: "0",
                                padding: "0",
                                color: "#fff",
                                marginTop: "5px",
                              }}
                            >
                              {translate("Settings")}
                            </p>
                          </li>
                          <li className="onhover-div mobile-setting mobile-wishing">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
                                <svg
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="navbar-ico"
                                >
                                  <path d="M13.1645 20.1875L13.0391 20.3125L12.9013 20.1875C6.94832 14.8 3.0131 11.2375 3.0131 7.625C3.0131 5.125 4.89299 3.25 7.3995 3.25C9.32951 3.25 11.2094 4.5 11.8736 6.2H14.2047C14.8689 4.5 16.7488 3.25 18.6788 3.25C21.1853 3.25 23.0652 5.125 23.0652 7.625C23.0652 11.2375 19.13 14.8 13.1645 20.1875ZM18.6788 0.75C16.4981 0.75 14.4052 1.7625 13.0391 3.35C11.6731 1.7625 9.58016 0.75 7.3995 0.75C3.53947 0.75 0.506592 3.7625 0.506592 7.625C0.506592 12.3375 4.76766 16.2 11.2219 22.0375L13.0391 23.6875L14.8564 22.0375C21.3106 16.2 25.5717 12.3375 25.5717 7.625C25.5717 3.7625 22.5388 0.75 18.6788 0.75Z" />
                                </svg>
                              </Link>
                            </div>
                            <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
                              <p
                                className="hidden-p"
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  color: "#fff",
                                  marginTop: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                {translate("Wishlist_ico")}
                              </p>
                            </Link>
                          </li>
                          {/*Header Cart Component */}
                          {/* <CartContainer /> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="search-overlay" className="search-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          name="search"
                          value={this.state.search}
                          className="form-control"
                          placeholder={translate("Find_product")}
                          onChange={(e) => this.handleSearch(e)}
                          onKeyPress={(e) => this.keyPressed(e)}
                        />
                      </div>
                      <button
                        style={{ top: "-5px" }}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => this.submitSearch(e)}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  filters: state.filters,
});

export default connect(
  mapStateToProps,
  { filterSearch, getVisibleproducts, changeCurrency }
)(withRouter(withTranslate(HeaderSix)));
