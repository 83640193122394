import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import SimpleReactValidator from "simple-react-validator";
import NumberFormat from "react-number-format";
import { Eye, EyeOff } from "react-feather";

//custom files
import BackgroundContainer from "./components/BackgroundContainer";
import "./style/auth.scss";
import { login } from "./api/auth";
import { setUser, getGender } from "../../actions";
import { getStore } from "../../fetch/filterApi";
import { getTempToken } from "../userProfile/Users";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      password: "",
      disableBtn: false,
      serverLoginErr: false,
      serverTimeErr: false,
      serverErr: false,
      showPassword: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      messages: {
        required: this.props.translate("Fill_in_the_field"),
      },
    });
  }

  componentDidMount() {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  navigateBtn = (path) => {
    this.props.history.push(`${process.env.PUBLIC_URL}/${path}`);
  };

  handleInputChange(event) {
    this.setState({
      serverLoginErr: false,
      serverTimeErr: false,
      serverErr: false,
    });
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handlePassword(e) {
    e.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  goPhoneRegister = () => {
    this.navigateBtn("sendCode");
  };

  loginBtn = (e) => {
    e.preventDefault();
    this.setState({
      serverLoginErr: false,
      serverTimeErr: false,
      serverErr: false,
    });
    if (this.validator.allValid()) {
      const { phone, password } = this.state;
      this.setState({ disableBtn: true });
      login({ phone: "+998" + phone, password }).then(async (res) => {
        if (res.status === 200) {
          const resClone = await res
            .clone()
            .json()
            .then(async (json) => {
              const user = {
                token: json.token,
                roles: json.roles,
                name: json.name,
                surname: json.surname,
              };
              localStorage.setItem("mebzone-user", JSON.stringify(user));
              await this.props.setUser(json);
              await this.props.getGender();
              if (user.roles.id === 2) {
                const { defaultStoreId } = json;
                if (defaultStoreId) {
                  getStore(defaultStoreId).then((res) => {
                    if (res.statusId === 2) {
                      getTempToken(this.state.token).then((res) => {
                        window.location.href = `${
                          process.env.REACT_APP_MEBZONE_PORTAL
                        }/auth/login?token=${
                          res.token
                        }&storeId=${defaultStoreId}`;
                      });
                    } else {
                      this.props.history.push(
                        `${
                          process.env.PUBLIC_URL
                        }/user-profile?defStore=${true}`
                      );
                    }
                  });
                } else {
                  setTimeout(() => {
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/user-profile`
                    );
                  }, 2000);
                }
              } else {
                setTimeout(() => {
                  this.props.history.push(
                    `${process.env.PUBLIC_URL}/user-profile`
                  );
                }, 2000);
              }
            });
        } else if (res.status === 401) {
          console.log("Wrong login or password");
          this.setState({ disableBtn: false, serverLoginErr: true });
        } else if (res.status === 429) {
          console.log("Too many requests, please try again later");
          this.setState({ disableBtn: false, serverTimeErr: true });
        } else {
          console.log("Server error");
          this.setState({ disableBtn: false, serverErr: true });
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    const { translate } = this.props;
    return (
      <BackgroundContainer>
        <form className="login-container">
          <div>
            <h3 className="form-title">{translate("Login_to_your_profile")}</h3>
            <label htmlFor="phone">{translate("Phone_number")}:</label>
            <NumberFormat
              value={this.state.phone}
              displayType={"input"}
              placeholder={"+998"}
              format="+998 (##) ###-##-##"
              mask="_"
              onValueChange={(values) => {
                this.setState({
                  phone: values.value,
                  serverLoginErr: false,
                  serverTimeErr: false,
                  serverErr: false,
                });
              }}
              onBlur={() => this.validator.showMessageFor("phone")}
              renderText={(value, props) => <div {...props}>{value}</div>}
            />
            {this.validator.message("phone", this.state.phone, "required")}
            <label htmlFor="password">{translate("Enter_password")}</label>
            <div className="login-show-password-span-container">
              <input
                type={this.state.showPassword ? "text" : "password"}
                placeholder="***"
                name="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                onBlur={() => this.validator.showMessageFor("password")}
              />
              <span
                className="login-show-password-icon"
                onClick={(e) => this.handlePassword(e)}
              >
                {this.state.showPassword ? (
                  <EyeOff size={18} />
                ) : (
                  <Eye size={18} />
                )}
              </span>
            </div>
            {this.validator.message(
              "password",
              this.state.password,
              "required"
            )}
          </div>
          <div className="login-btn-container">
            <div className="server-errors">
              {this.state.serverLoginErr && (
                <p>{translate("Wrong_login_or_password")}</p>
              )}
              {this.state.serverTimeErr && (
                <p>{translate("To_many_request")}</p>
              )}
              {this.state.serverErr && <p>{translate("Server_error")}</p>}
            </div>
            <button
              className="login-btn"
              onClick={(e) => this.loginBtn(e)}
              disabled={this.state.disableBtn}
              style={{
                cursor: this.state.disableBtn ? "not-allowed" : "",
              }}
            >
              {this.state.disableBtn ? (
                <ClipLoader color={"#fff"} loading={true} size={20} />
              ) : (
                translate("Login")
              )}
            </button>
            <button
              className="register-route-btn"
              onClick={() => this.goPhoneRegister()}
            >
              {translate("Please_sign_up")}
            </button>
            <button
              className="forget-password-route-btn"
              onClick={() => this.navigateBtn("forget-password")}
            >
              {translate("Forgot_password")}
            </button>
          </div>
        </form>
      </BackgroundContainer>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  { setUser, getGender }
)(withRouter(withTranslate(Login)));
