import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import StarRatingComponent from "react-star-rating-component";
import { withTranslate } from "react-redux-multilingual";
import NumberFormat from "react-number-format";
import {
  chosenProductId,
  gettingProductComments,
  gettingRelatedProduct,
} from "../../actions/index";

class NewProduct extends Component {
  constructor(props) {
    super(props);
  }

  changeProductId = async (productId) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/right-sidebar/product/${productId}`
    );
    // await this.props.chosenProductId(productId);
    // await this.props.gettingProductComments(productId, 1);
    // await this.props.gettingRelatedProduct(productId, 1, 4);
  };

  render() {
    const { related_products, translate, locale, symbol } = this.props;
    console.log(related_products);

    const { USD_UZS } = this.props.rate;
    let arrays = [];
    // if (related_products) {
    //   while (related_products.length > 0) {
    //     arrays.push(related_products.splice(0, 3));
    //   }
    // }

    return (
      <div className="theme-card">
        {related_products.length !== 0 && (
          <h5 className="title-border">{translate("Same_products")} :</h5>
        )}
        <Slider className="offer-slider slide-1">
          <div>
            {related_products &&
              related_products.map((product) => (
                <div className="media">
                  <img
                    className="img-fluid"
                    src={`${product.images[0].imageUrl}?width=188&height=160`}
                    alt=""
                    onClick={() => this.changeProductId(product.id)}
                  />
                  <div className="media-body align-self-center">
                    <h4>
                      <StarRatingComponent
                        name="product_rate"
                        starCount={5}
                        value={Number(product.rate)}
                      />
                    </h4>
                    <h6>
                      {locale === "en" ? product.name_uz : product.name_ru}
                    </h6>
                    <h4>
                      {/* {product.price} UZS */}
                      <NumberFormat
                        value={
                          symbol === "$"
                            ? product.price / USD_UZS
                            : product.price
                        }
                        thousandSeparator=" "
                        decimalScale={2}
                        prefix={symbol === "$" ? "$" : null}
                        suffix={symbol === "$" ? null : " UZS"}
                        displayType="text"
                      />
                    </h4>
                  </div>
                </div>
              ))}
          </div>
          {/* {arrays.map((products, index) => (
            <div key={index}>
              {products.map((product, i) => (
                <div className="media" key={i}>
                  <img
                    className="img-fluid"
                    src={`${product.images[0].imageUrl}`}
                    alt=""
                    onClick={() => this.changeProductId(product.id)}
                  />

                  <div className="media-body align-self-center">
                    <h4>
                      <StarRatingComponent
                        name="product_rate"
                        starCount={5}
                        value={Number(product.rate)}
                      />
                    </h4>
                    <h6>
                      {locale === "en" ? product.name_uz : product.name_ru}
                    </h6>
                    <h4>
                      {product.price} UZS
                      <NumberFormat
                        value={
                          symbol === "$"
                            ? product.price / USD_UZS
                            : product.price
                        }
                        thousandSeparator=" "
                        decimalScale={2}
                        prefix={symbol === "$" ? "$" : null}
                        suffix={symbol === "$" ? null : " UZS"}
                        displayType="text"
                      />
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          ))} */}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  productId: state.product_detail.productId,
  related_products: state.product_detail.related_products,
  locale: state.Intl.locale,
  symbol: state.data.symbol,
  rate: state.data.rate,
});

export default connect(
  mapStateToProps,
  { chosenProductId, gettingProductComments, gettingRelatedProduct }
)(withRouter(withTranslate(NewProduct)));
