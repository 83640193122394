import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { SlideUpDown } from "../../../services/script";
import LogoImage from "../headers/common/logo";

class FooterFive extends Component {
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth < 750) {
      SlideUpDown("footer-title");
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function(elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }

  render() {
    const { translate } = this.props;
    return (
      <footer className="footer-light">
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row footer-theme partition-f">
              <div className="col-lg-4 col-md-6">
                <div className="footer-contant">
                  <div className="footer-logo">
                    <LogoImage logo={this.props.logoName} />
                  </div>
                  <h6>{translate("Mebzone_footer_about")}</h6>
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href="https://facebook.com/mebzone.mebzone">
                          <i
                            className="fa fa-facebook foot-ico"
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/mebzone.uz">
                          <i
                            className="fa fa-instagram foot-ico"
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col offset-xl-1">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>{translate("To_customers")}</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <Link to={"#"}>{translate("How_to_use_mebzone")}</Link>
                      </li>
                      <li>
                        <Link to={"#"}>
                          {translate("How_to_leave_comment")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>{translate("To_seller")}</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <a href="#">{translate("How_to_create_website")}</a>
                      </li>
                      <li>
                        <a href="#">{translate("Security")}</a>
                      </li>
                      <li>
                        <a href="#">{translate("Rules")}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="sub-title">
                  <div className="footer-title">
                    <h4>{translate("About_us")}</h4>
                  </div>
                  <div className="footer-contant">
                    <ul>
                      <li>
                        <a href="#">{translate("About_mebzone")}</a>
                      </li>
                      <li>
                        <a href="#">{translate("FAQ")}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="sub-footer ">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="footer-end">
                  <p>
                    <a href="http://wemake.uz">We Make</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslate(FooterFive);
