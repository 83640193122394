import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import Modal from "react-responsive-modal";
export class mainProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      surname: "",
      email: "",
      phone: "",
      gender: "",
      birthday: "",
      address: "",
      imageUrl: "",
      defaultStore: false,
    };
  }

  componentWillMount() {
    const defStore = new URLSearchParams(this.props.location.search).get(
      "defStore"
    );

    if (defStore) {
      this.setState({ defaultStore: true });
    }

    const { userData, locale } = this.props;
    this.setState({
      name: userData.name || this.props.translate("Not_given"),
      surname: userData.surname || this.props.translate("Not_given"),
      email: userData.email || this.props.translate("Not_given"),
      phone: userData.phone || this.props.translate("Not_given"),
      gender: userData.gender
        ? locale === "en"
          ? userData.gender.name_uz
          : userData.gender.name_ru || this.props.translate("Not_given")
        : this.props.translate("Not_given"),
      birthday: userData.birthday
        ? userData.birthday.slice(0, 10)
        : this.props.translate("Not_given"),
      address: userData.address || this.props.translate("Not_given"),
      imageUrl: userData.image
        ? userData.image.imageUrl ||
          `${process.env.PUBLIC_URL}/assets/images/awa.png`
        : `${process.env.PUBLIC_URL}/assets/images/awa.png`,
    });
  }

  onCloseModal = () => {
    this.setState({ defaultStore: false });
    this.props.history.push(`${process.env.PUBLIC_URL}/user-profile`);
  };

  render() {
    const { translate } = this.props;
    return (
      <div className="main-profile-container">
        <h3 className="profile-sidebar-title">
          {translate("My_personal_info")}
        </h3>
        <div className="fixed-user-info">
          <div className="user-avatar">
            <img
              src={this.state.imageUrl}
              alt="avatar"
              style={{ objectFit: "cover" }}
            />

            <div>
              <p className="user-name">{this.state.name}</p>
              <p className="user-name">{this.state.surname}</p>
              <p>{this.state.email}</p>
              {/* <p>{this.state.address ? this.state.address : ""}</p> */}
            </div>
          </div>
          <div className="user-data">
            <table>
              <tbody>
                <tr>
                  <td>{translate("Name")}</td>
                  <td>{this.state.name}</td>
                </tr>
                <tr>
                  <td>{translate("Surname")}</td>
                  <td>{this.state.surname}</td>
                </tr>

                <tr>
                  <td>{translate("Email")}</td>
                  <td>{this.state.email}</td>
                </tr>
                <tr>
                  <td>{translate("Phone_number")}</td>
                  <td>{this.state.phone}</td>
                </tr>
                <tr>
                  <td>{translate("Gender")}</td>
                  <td>{this.state.gender}</td>
                </tr>
                <tr>
                  <td>{translate("Birthday")}</td>
                  <td>{this.state.birthday}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <Modal
          open={this.state.defaultStore}
          onClose={this.onCloseModal}
          center
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                margin: "20px",
                padding: "25px",
                textTransform: "unset",
                lineHeight: "42px",
                textAlign: "center",
                border: "2px solid #00a075",
              }}
            >
              {translate("Your_default_store_is_blocked")}
            </h2>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(withTranslate(mainProfile));
