import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
//custom files
import BackgroundContainer from "./components/BackgroundContainer";
import "./style/auth.scss";

export class Complete extends Component {
  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  navigateBtn = (path) => {
    localStorage.removeItem("new-user-phone");
    this.props.history.push(`${process.env.PUBLIC_URL}/${path}`);
  };

  render() {
    const { translate } = this.props;
    return (
      <BackgroundContainer>
        <form className="auth-complete-container">
          <p>{translate("you_are_registered_as_customer")}</p>
          <button onClick={() => this.navigateBtn("login")}>
            {translate("To_complete")}
          </button>
        </form>
      </BackgroundContainer>
    );
  }
}

export default withTranslate(Complete);
