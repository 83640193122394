import * as types from "../constants/ActionTypes";

const helpReducerDefaultState = {
  mainCategory: null,
};

const helpReducer = (state = helpReducerDefaultState, action) => {
  // console.log("Action Result");
  // console.log(action);
  switch (action.type) {
    case types.HELP_MAINCATEGORY:
      return {
        mainCategory: action.data,
      };
    case types.HELP_ONLY_MAINCATEGORY:
      return {
        mainCategory: action.data,
      };

    default:
      return state;
  }
};

export default helpReducer;
