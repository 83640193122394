import {
  FETCH_SINGLE_PRODUCT,
  CHANGE_CURRENCY,
  RECEIVE_PRODUCTS,
  SELECTED_PRICE_RANGES,
  RATE,
} from "../constants/ActionTypes";

const initialState = {
  products: [],
  symbol: "$",
  product_details: [],
  selected_price_ranges: {},
  rate: "",
};

const productReducer = (state = initialState, action) => {
  //console.log("ss", action);
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return { ...state, products: action.products };
    case FETCH_SINGLE_PRODUCT:
      if (
        state.products.findIndex(
          (product) => product.id === action.productId
        ) !== -1
      ) {
        const singleItem = state.products.reduce((itemAcc, product) => {
          return product;
        }, []);
        return { ...state, product_details: singleItem };
      }
    case RATE:
      return {
        ...state,
        rate: action.rate,
      };

    case CHANGE_CURRENCY:
      if (state.symbol !== action.symbol) {
        const { min, max } = state.selected_price_ranges;
        const { USD_UZS } = state.rate;
        console.log(USD_UZS);
        console.log(min, max);
        if (action.symbol === "$") {
          return {
            ...state,
            symbol: action.symbol,
            selected_price_ranges: {
              min: (min / USD_UZS).toFixed(2),
              max: (max / USD_UZS).toFixed(2),
            },
          };
        } else {
          return {
            ...state,
            symbol: action.symbol,
            selected_price_ranges: {
              min: (min * USD_UZS).toFixed(2),
              max: (max * USD_UZS).toFixed(2),
            },
          };
        }
      } else {
        return {
          ...state,
          symbol: action.symbol,
          selected_price_ranges: state.selected_price_ranges,
        };
      }

    case SELECTED_PRICE_RANGES:
      return { ...state, selected_price_ranges: action.data };
    default:
      return state;
  }
};
export default productReducer;
