import * as types from "../constants/ActionTypes";

const productDetailReducerDefaultState = {
  product: "",
  productId: "",
  product_comments: "",
  pages: "",
  related_products: ""
};

const productDetailReducer = (state = productDetailReducerDefaultState, action) => {
  // console.log("Action Result");
  // console.log(action);
  switch (action.type) {
    case types.PRODUCT_DETAIL:
      return {
        product: action.product,
      };

    case types.PRODUCT_COMMENTS:
      return {
        ...state,
        productId: action.productId,
        product_comments: action.product_comments,
        pages: action.pages,
      }

    case types.NEW_PRODUCT_COMMENT_ADDED:
      return {
        ...state,
        product_comments: [action.new_comment, ...state.product_comments]
      }
    case types.RELATED_PRODUCTS:
      return {
        ...state,
        related_products: action.related_products
      }
    default:
      return state;
  }
};

export default productDetailReducer;
