import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import Breadcrumb from "../common/breadcrumb";
// import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import { withTranslate } from "react-redux-multilingual";

class CollectionLeftSidebar extends Component {
  state = {
    layoutColumns: 3,
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  componentWillUnmount() {
    localStorage.removeItem("hidefilters");
  }

  render() {
    const { translate } = this.props;
    const hideFilter = localStorage.getItem("hidefilters");
    const location = window.location.href.split("/");
    const id = Number(location[location.length - 1]);

    //console.log("hiding", hideFilter);

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Mebzone | Продукты</title>
          <meta name="description" content="Mebzone" />
        </Helmet>
        {/*SEO Support End */}

        <div className="container">
          <div className="row justify-content-end mt-4">
            <img
              src={
                this.props.locale === "en"
                  ? `${
                      process.env.PUBLIC_URL
                    }/assets/images/mebzone-ads/ad-uz.jpg`
                  : `${
                      process.env.PUBLIC_URL
                    }/assets/images/mebzone-ads/ad-ru.jpg`
              }
              className="col"
              alt="reklama"
            />
          </div>
        </div>
        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                {hideFilter ? (
                  ""
                ) : (
                  <div className="col-sm-3 collection-filter">
                    <StickyBox offsetTop={20} offsetBottom={20}>
                      <div>
                        <Filter />
                      </div>
                    </StickyBox>
                    {/*side-bar banner end here*/}
                  </div>
                )}
                <div className="collection-content col">
                  <div className="page-main-content ">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                {hideFilter ? (
                                  ""
                                ) : (
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <div className="filter-main-btn">
                                        <span
                                          onClick={this.openFilter}
                                          className="filter-btn btn btn-theme"
                                        >
                                          <i
                                            className="fa fa-filter"
                                            aria-hidden="true"
                                          />{" "}
                                          {translate("Filters")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-12">
                                    <FilterBar
                                      onLayoutViewClicked={(colmuns) =>
                                        this.LayoutViewClicked(colmuns)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*Products Listing Component*/}
                            <ProductListing
                              colSize={this.state.layoutColumns}
                              mainCategoryId={id}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.Intl.locale,
});

export default connect(mapStateToProps)(withTranslate(CollectionLeftSidebar));
