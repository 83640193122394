import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import {
  filterCategory,
  getVisibleproducts,
  helpMainCategory,
  setMoreCategory,
  setNextLength,
} from "../../../actions";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleBtn: true,
    };
    this.handleCategoryId = this.handleCategoryId.bind(this);
  }

  handleCategoryId = async (e, categoryId, mainCategoryId) => {
    e.preventDefault();
    await this.props.filterCategory(categoryId);
    await this.props.helpMainCategory({ mainCategoryId, categoryId });
    await this.props.getVisibleproducts(this.props.filters);
    this.props.history.push(
      `${process.env.PUBLIC_URL}/left-sidebar/collection`
    );
  };

  loadMoreBtn = async (e, id, categoryLength, nextLength) => {
    console.log(categoryLength, nextLength);
    e.preventDefault();
    if (nextLength < categoryLength) {
      await this.props.setNextLength(nextLength + 3, id);
    } else {
      this.setState({ visibleBtn: false });
    }
  };
  render() {
    const { mainCategories, translate, locale } = this.props;
    return (
      <div id="category">
        <div className="title1 section-t-space">
          <h2 className="title-inner1">{translate("Categories")}</h2>
        </div>
        <section className="section-b-space p-t-0">
          <div className="container">
            {this.props.mainCategories.map((mainCategory, index) => {
              return (
                <div key={index}>
                  <div className="landing-category-list">
                    {mainCategory.categories
                      .slice(0, mainCategory.nextLength)
                      .map((category, index) => {
                        return (
                          <div
                            className="category"
                            key={index}
                            onClick={(e) => {
                              this.handleCategoryId(
                                e,
                                category.id,
                                mainCategory.id
                              );
                            }}
                          >
                            <div className="img-deco" />
                            <p className="category-title">
                              {locale == "en"
                                ? category.name_uz
                                : category.name_ru}
                            </p>
                            <img
                              src={
                                category.image
                                  ? `${
                                      category.image.imageUrl
                                    }?width=350&height=233`
                                  : `${
                                      process.env.PUBLIC_URL
                                    }/assets/images/furniture/product/f-1.jpg`
                              }
                              className="img-fluid"
                              alt="category-pics"
                            />
                          </div>
                        );
                      })}
                  </div>

                  <span>
                    {mainCategory.categories.length ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {this.state.visibleBtn ? (
                          <button
                            className="btn btn-solid mt-3 mb-3"
                            onClick={(e) =>
                              this.loadMoreBtn(
                                e,
                                mainCategory.id,
                                mainCategory.categoryLength,
                                mainCategory.nextLength
                              )
                            }
                          >
                            {translate("Loadmore")}
                          </button>
                        ) : (
                          <p>{translate("No_item_left")}</p>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mainCategories: state.mainCategories.mainCategories,
  filters: state.filters,
  locale: state.Intl.locale,
});

export default connect(
  mapStateToProps,
  {
    filterCategory,
    getVisibleproducts,
    helpMainCategory,
    setMoreCategory,
    setNextLength,
  }
)(withRouter(withTranslate(Categories)));
