import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";

import { svgFreeShipping, svgservice } from "../../services/script";

class Service extends Component {
  render() {
    const { product, translate } = this.props;

    return (
      <div className="collection-filter-block ">
        <div className="product-service">
          <div className="media">
            <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
            <div className="media-body">
              <h4>{translate("Delivery_through_uzb")}</h4>
              <p>
                {product.isDeliverable ? translate("Yes") : translate("No")}
              </p>
            </div>
          </div>
          <div className="media">
            <div dangerouslySetInnerHTML={{ __html: svgservice }} />
            <div className="media-body">
              <h4>{translate("In_a_sale")}</h4>
              <p>{product.isAvailable ? translate("Yes") : translate("No")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslate(Service);
