import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  ShoppingCart,
  Edit,
  Key,
  MessageSquare,
  Tool,
  Phone,
  Mail,
  ShoppingBag,
} from "react-feather";
import SwitchProfile from "./switchProfile";
import "./userProfile.scss";
import { getTempToken } from "./Users";
import { getUser } from "../../actions/index";
export class userProfile extends Component {
  state = {
    role: null,
    page: 1,
    comment: 1,
    token: "",
    userName: "",
    userSurname: "",
    show: false,
  };

  componentDidMount() {
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);

    if (userObj) {
      if (userObj.token === "TokenExpiredError") {
        this.props.history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        this.setState({
          role: userObj.roles[0].id,
          token: userObj.token,
          userName: userObj.name,
          userSurname: userObj.surname,
        });
      }
    } else {
      this.props.history.push(`${process.env.PUBLIC_URL}/login`);
    }
  }

  changePage = (p) => {
    this.setState({
      page: p,
    });
  };

  changeComment = (c, event) => {
    var smth = document.getElementsByClassName("help-class-2");
    [].forEach.call(smth, function(elem) {
      elem.classList.remove("comment-active");
    });
    event.target.classList.add("comment-active");

    this.setState({
      comment: c,
    });
  };

  handleToken = (e) => {
    e.preventDefault();
    getTempToken(this.state.token).then((res) => {
      window.location.href = `${
        process.env.REACT_APP_MEBZONE_PORTAL
      }/auth/login?token=${res.token}`;
    });
  };

  showTopbarMenu = () => {
    this.setState({ show: !this.state.show });
  };

  logoutBtn() {
    localStorage.removeItem("mebzone-user");
    this.props.history.push(`${process.env.PUBLIC_URL}/`);
    window.location.reload();
  }

  render() {
    const { translate } = this.props;
    const { page, comment } = this.state;

    return (
      <>
        <div className="profile-topbar">
          <div className="container">
            <div className="profile-topbar-links">
              <div>
                <Link to={`${process.env.PUBLIC_URL}/`}>
                  {translate("Welcome_to_mebzone")}
                </Link>
              </div>
              <div className="profile-user-name">
                <span onClick={() => this.showTopbarMenu()}>
                  {this.state.userName} {this.state.userSurname}
                </span>
                {this.state.show && (
                  <div className="profile-topbar-logout">
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/user-profile`}>
                          <span>{translate("My_profile")}</span>
                        </Link>
                      </li>
                      <li onClick={() => this.logoutBtn()}>
                        {translate("Log_out")}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="user-profile-contanier">
            <div className="u-profile-sidebar">
              <button
                onClick={() => this.changePage(1)}
                className={this.state.page === 1 ? "profile-active" : ""}
              >
                <Grid size={13} className="feather-icons" />{" "}
                <span>{translate("My_personal_info")}</span>
              </button>
              <button
                onClick={() => this.changePage(2)}
                className={this.state.page === 2 ? "profile-active" : ""}
              >
                <Edit size={13} className="feather-icons" />{" "}
                <span>{translate("Edit_personal_info")}</span>
              </button>

              {/* {this.state.role === 3 ? (
                <button
                  onClick={(event) => this.changePage(3)}
                  className={this.state.page === 3 ? "profile-active" : ""}
                >
                  <MessageSquare size={13} className="feather-icons" />
                  {translate("My_comments")}
                </button>
              ) : (
                ""
              )} */}

              {this.state.role === 2 ? (
                <button
                  onClick={() => this.changePage(4)}
                  className={this.state.page === 4 ? "profile-active" : ""}
                >
                  <ShoppingCart size={13} className="feather-icons" />{" "}
                  <span>{translate("My_stores")}</span>
                </button>
              ) : (
                ""
              )}

              <button
                onClick={() => this.changePage(5)}
                className={this.state.page === 5 ? "profile-active" : ""}
              >
                <Key size={13} className="feather-icons" />{" "}
                <span>{translate("Change_password")}</span>
              </button>
              <button
                onClick={() => this.changePage(7)}
                className={this.state.page === 7 ? "profile-active" : ""}
              >
                <Phone size={13} className="feather-icons" />{" "}
                <span>{translate("Update_phone")}</span>
              </button>
              <button
                onClick={() => this.changePage(8)}
                className={this.state.page === 8 ? "profile-active" : ""}
              >
                <Mail size={13} className="feather-icons" />{" "}
                <span>{translate("Update_email")}</span>
              </button>

              {this.state.role === 2 ? (
                <button
                  onClick={() => this.changePage(6)}
                  className={this.state.page === 6 ? "profile-active" : ""}
                >
                  <ShoppingBag size={13} className="feather-icons" />{" "}
                  <span>{translate("Create_internet_store")}</span>
                </button>
              ) : (
                ""
              )}

              {this.state.role === 1 ? (
                <button
                  onClick={(event) => this.handleToken(event)}
                  // className="admin-btn"
                >
                  <Tool size={13} className="feather-icons" />{" "}
                  <span>{translate("Control_stores")}</span>
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="u-profile-page">
              <SwitchProfile
                page={page}
                comment={comment}
                changeComment={this.changeComment}
                changePage={this.changePage}
                mainCategories={this.props.mainCategories}
                regions={this.props.regions}
                userData={this.props.userData}
                gender={this.props.gender}
                getUser={this.props.getUser}
                locale={this.props.locale}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  mainCategories: state.mainCategories.mainCategories,
  regions: state.user.regions,
  userData: state.user.userData,
  gender: state.user.gender,
  locale: state.Intl.locale,
});

export default connect(
  mapStateToProps,
  { getUser }
)(withRouter(withTranslate(userProfile)));
