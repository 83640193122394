import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import { Edit2 } from "react-feather";
import { Link, withRouter } from "react-router-dom";
import { getSellerOwnShops, getTempToken } from "./Users";
import { getStore } from "../../fetch/filterApi";
export class MyShops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shops: [],
      token: "",
    };
  }

  componentWillMount() {
    const token = localStorage.getItem("token");
    this.setState({ token: token });
  }

  componentDidMount() {
    const userStr = localStorage.getItem("mebzone-user");
    const userObj = JSON.parse(userStr);
    getSellerOwnShops(userObj.token).then((resShops) => {
      console.log(resShops);
      this.setState({
        shops: [...resShops],
        token: userObj.token,
      });
    });
  }

  handleToken = (e, shopId) => {
    e.preventDefault();
    getStore(shopId, this.state.token).then((res) => {
      const { statusId } = res;
      console.log(statusId);
      if (statusId === 2) {
        getTempToken(this.state.token).then((res) => {
          window.location.href = `${
            process.env.REACT_APP_MEBZONE_PORTAL
          }/auth/login?token=${res.token}&storeId=${shopId}`;
        });
      } else {
        this.props.history.push(`${process.env.PUBLIC_URL}/pages/404`);
      }
    });
  };

  render() {
    const { translate, locale } = this.props;
    return (
      <div>
        <h3 className="profile-sidebar-title">{translate("My_stores")}</h3>
        <div className="ownShops">
          {this.state.shops
            .slice(0)
            .reverse()
            .map((shop, index) => {
              return (
                <div className="ownShops-container" key={index}>
                  <Link
                    to={`${process.env.PUBLIC_URL}/update-store/${shop.id}`}
                    className="btn btn-secondary edit-store-btn"
                  >
                    <Edit2 size={18} />
                  </Link>
                  <div className="ownshop-data">
                    <img
                      src={shop.image.imageUrl}
                      alt=""
                      className="shop-logo"
                    />
                    <p>
                      {translate("Company_name")}:{" "}
                      <span> {shop.brandname}</span>{" "}
                    </p>
                    <p>
                      {translate("Address")}: <span> {shop.address} </span>
                    </p>
                    <p>
                      {translate("Region")}:{" "}
                      <span>
                        {locale === "en"
                          ? shop.region.name_uz
                          : shop.region.name_ru}{" "}
                      </span>
                    </p>
                    <p>
                      {translate("Phone_number")}: <span> {shop.phone} </span>{" "}
                    </p>
                  </div>
                  <button
                    disabled={shop.statusId === 1}
                    className={
                      shop.statusId === 1
                        ? "btn btn-secondary"
                        : "btn btn-primary"
                    }
                    onClick={(e) => {
                      this.handleToken(e, shop.id);
                    }}
                  >
                    {shop.statusId === 1
                      ? translate("you_are_not_activated")
                      : translate("Login")}
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslate(MyShops));
